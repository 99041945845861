import http from '../../utils/http'

const audiencesEndpoint = '/audiences'

export const fetchAudiences = ({
  filters,
  pagination: { current, pageSize }
}) => {
  let endpoint = `${audiencesEndpoint}?page[number]=${current}&page[size]=${pageSize}`
  if (filters.name) {
    endpoint = `${audiencesEndpoint}?q[name_cont]=${
      filters.name
    }&page[number]=${current}&page[size]=${pageSize}`
  }
  return http
    .get(endpoint)
    .then(res => res)
    .catch(err => err.response)
}

export const createAudiences = params => {
  return http
    .post(audiencesEndpoint, params)
    .then(res => res)
    .catch(err => err.response)
}

export const updateAudience = ({ params, id }) => {
  return http
    .put(`${audiencesEndpoint}/${id}`, params)
    .then(res => res)
    .catch(err => err.response)
}

export const deleteAudiences = id => {
  return http({
    url: `${audiencesEndpoint}/${id}`,
    headers: {
      'Content-Type': 'application/vnd.api+json'
    },
    method: 'DELETE',
    data: {}
  })
}
