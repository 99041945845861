import React from 'react'

const ArrowDownIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='13'
      height='12'
      viewBox='0 0 13 12'
      fill='none'
    >
      <path
        d='M0.75 5.98242L5.9658 11.1982C6.11429 11.3467 6.3494 11.3467 6.49789 11.1982L11.7137 5.98242'
        stroke='#484848'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default ArrowDownIcon
