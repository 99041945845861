import React from 'react'

const LogoutIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 14 15'
      fill='none'
    >
      <path
        d='M8.92611 10.66L5.76055 7.49441C5.67227 7.40613 5.67227 7.26199 5.76055 7.17191L8.92611 4.00635'
        stroke='#776AAE'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M5.77002 7.33398H13.0002'
        stroke='#776AAE'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M11.8827 10.0282V11.3668C11.8827 12.1866 11.2341 12.8496 10.4324 12.8496L2.35905 13.7108C1.5591 13.7108 0.908691 13.0478 0.908691 12.228V2.44128C0.910493 1.62152 1.5591 0.958496 2.36085 0.958496L10.4342 1.62512C11.2341 1.62512 11.8846 2.28814 11.8846 3.10791V4.45196'
        stroke='#776AAE'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default LogoutIcon
