/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  clearFields,
  untouch,
  isValid,
  getFormValues,
  change,
  submit
} from 'redux-form'
import UserForm from '../../components/form/UsersForm'

import {
  createUserRequest,
  fetchUsersRequest,
  updateUsersPagination
} from '../../store/users/'
import { fetchInvestmentsRequest } from '../../store/investments'

const mapStateToProps = state => ({
  isFormValid: isValid('usersForm')(state),
  formValues: getFormValues('usersForm')(state),
  investments: state.investments.items,
  userProfiles: state.auth.user.profiles,
  users: state.users.items,
  isFetching: state.users.isFetching
})

const mapDispatchToProps = {
  clearFields,
  untouch,
  change,
  submit,
  createUserRequest,
  fetchInvestmentsRequest,
  fetchUsersRequest,
  updateUsersPagination
}

const UserFormContainer = props => {
  const {
    createUserRequest,
    fetchInvestmentsRequest,
    fetchUsersRequest,
    userProfiles,
    updateUsersPagination
  } = props
  const history = useHistory()
  React.useEffect(() => {
    if (
      userProfiles?.includes('superuser') ||
      userProfiles?.includes('admin')
    ) {
      updateUsersPagination({ current: 1, pageSize: 20 })
      fetchInvestmentsRequest()
      fetchUsersRequest({
        filters: {
          'q[profiles_role_eq]': 'investor'
        },
        fields: 'name'
      })
    } else {
      history.push('/')
    }
  }, [])
  const onSubmit = data => {
    const { phone_code, assessors, ...attrs } = data
    const assessorAttrs =
      assessors && assessors.length > 0
        ? {
          assessors: assessors.map(item => {
            const { phone_code, ...restAttrs } = item
            const phoneAttr = `${phone_code} ${restAttrs['phone']}`
            if (restAttrs.hasOwnProperty('investment_entity_id')) {
              return {
                ...restAttrs,
                investment_entity_id: restAttrs['investment_entity_id'] * 1,
                phone: phoneAttr
              }
            }
            return {
              ...restAttrs,
              phone: phoneAttr
            }
          })
        }
        : {}
    const investmentEntityIdsAttr = attrs['investment_entity_ids']
      ? {
        investment_entity_ids: attrs['investment_entity_ids'].map(
          item => item * 1
        )
      }
      : {}
    const assessorInvestorAttrs =
      attrs['profile'] === 'assessor'
        ? {
          investment_entity_id: attrs['investment_entity_id'] * 1,
          investor_account_id: attrs['investor_account_id'] * 1
        }
        : {}

    const formattedData = {
      ...attrs,
      ...investmentEntityIdsAttr,
      phone: `${phone_code} ${attrs['phone']}`,
      ...assessorAttrs,
      ...assessorInvestorAttrs
    }
    createUserRequest({ attributes: formattedData, history })
  }
  return <UserForm {...props} onSubmit={onSubmit} />
}

export default connect(mapStateToProps, mapDispatchToProps)(UserFormContainer)
