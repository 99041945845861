export const userProfileOptions = [
  {
    label: 'Assessor',
    value: 'assessor'
  },
  {
    label: 'Investor',
    value: 'investor'
  },
  {
    label: 'Potential Investor',
    value: 'potential_investor'
  },
  {
    label: 'Admin',
    value: 'admin'
  },
  {
    label: 'Super User',
    value: 'superuser'
  }
]

export const reportKindOptions = [
  {
    label: 'Report',
    value: 'report',
    path: '/reports'
  },
  {
    label: 'Legal',
    value: 'legal',
    path: '/legals'
  },
  {
    label: 'Planning',
    value: 'planning',
    path: '/plannings'
  },
  {
    label: 'Result',
    value: 'result',
    path: '/results'
  },
  {
    label: 'Transaction',
    value: 'transactions',
    path: '/transactions'
  },
  {
    label: 'Statement',
    value: 'statement',
    path: '/statements'
  }
]
