import React from 'react'

const RemoveIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        d='M12.917 1.11377H3.23276C2.08243 1.11377 1.1499 2.04629 1.1499 3.19662V12.8809C1.1499 14.0312 2.08243 14.9638 3.23276 14.9638H12.917C14.0674 14.9638 14.9999 14.0312 14.9999 12.8809V3.19662C14.9999 2.04629 14.0674 1.11377 12.917 1.11377Z'
        stroke='#484848'
        strokeWidth='0.75'
        strokeMiterlimit='10'
      />
      <path
        d='M4.49072 8.03906H11.66'
        stroke='#484848'
        strokeWidth='0.75'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default RemoveIcon
