import React from 'react'

const AudiencesIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 13'
      fill='none'
    >
      <path
        d='M15.5239 12.238H7.98543C7.37749 12.238 6.82938 12.0353 6.48199 11.6821C6.13267 11.327 6.05161 10.8716 6.26198 10.4643C7.10151 8.83352 9.30938 7.7373 11.7546 7.7373C14.1999 7.7373 16.4637 8.86054 17.2801 10.53C17.4712 10.9198 17.3843 11.3405 17.0427 11.6821C16.6934 12.0315 16.126 12.2399 15.5258 12.2399L15.5239 12.238Z'
        stroke='#B9B3D6'
        strokeMiterlimit='10'
      />
      <path
        d='M11.762 7.38045C13.5239 7.38045 14.9522 5.95213 14.9522 4.19022C14.9522 2.42831 13.5239 1 11.762 1C10.0001 1 8.57178 2.42831 8.57178 4.19022C8.57178 5.95213 10.0001 7.38045 11.762 7.38045Z'
        stroke='#B9B3D6'
        strokeMiterlimit='10'
      />
      <path
        d='M6.27325 10.3799H2.4114C1.94435 10.3799 1.52362 10.2236 1.25729 9.9534C0.989021 9.68127 0.927263 9.33195 1.08745 9.01737C1.73206 7.76483 3.42656 6.92529 5.30248 6.92529C6.7596 6.92529 8.09706 7.42708 8.92694 8.23381'
        stroke='#B9B3D6'
        strokeMiterlimit='10'
      />
      <path
        d='M5.30996 6.65214C6.66257 6.65214 7.75908 5.55564 7.75908 4.20302C7.75908 2.85041 6.66257 1.75391 5.30996 1.75391C3.95735 1.75391 2.86084 2.85041 2.86084 4.20302C2.86084 5.55564 3.95735 6.65214 5.30996 6.65214Z'
        stroke='#B9B3D6'
        strokeMiterlimit='10'
      />
    </svg>
  )
}

export default AudiencesIcon
