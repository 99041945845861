import React from 'react'

const HomeIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
    >
      <path
        d='M15.6578 6.15531L11.4014 2.26756C10.2805 1.24415 8.46559 1.24415 7.34465 2.26756L3.09021 6.15531C2.55248 6.64623 2.25 7.31333 2.25 8.00814V13.1291C2.25 14.5762 3.53503 15.75 5.11858 15.75H5.61085C6.30279 15.75 7.56607 15.2373 7.56607 14.6058V12.3888C7.56607 11.8207 8.0702 11.3614 8.69097 11.3614H10.0472C10.6679 11.3614 11.1721 11.8207 11.1721 12.3888V14.6058C11.1721 15.2373 11.7315 15.75 12.4235 15.75H13.6314C15.215 15.75 16.5 14.5762 16.5 13.1291V8.00814C16.5 7.31333 16.1975 6.64623 15.6598 6.15531H15.6578Z'
        stroke='#B9B3D6'
        strokeMiterlimit='10'
      />
    </svg>
  )
}

export default HomeIcon
