import React from 'react'
import { CloseCircleFilled, DownloadOutlined } from '@ant-design/icons'
import PDFIcon from '../../static/pdf-icon.svg'
import DOCIcon from '../../static/doc-icon.svg'
import PNGIcon from '../../static/png-icon.svg'
import JPGIcon from '../../static/jpg-icon.svg'
import FileIcon from '../../static/file.svg'

import { parseText } from '../../utils/functions'
import { AlternativeSpinner } from '../loading/Loading'
import { Document, Page, pdfjs } from 'react-pdf'
import { Modal } from 'antd'
import styled from '@emotion/styled'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`

const Container = styled.div`
  cursor: pointer;
`
const FileContainer = styled.div`
  cursor: pointer;
  &:hover {
    background: #fff;
  }
`

const fileTypes = [
  {
    type: 'png',
    icon: PNGIcon
  },
  {
    type: 'jpeg',
    icon: JPGIcon
  },
  {
    type: 'jpg',
    icon: JPGIcon
  },
  {
    type: 'pdf',
    icon: PDFIcon
  },
  {
    type: 'doc',
    icon: DOCIcon
  },
  {
    type: 'other',
    icon: FileIcon
  }
]

function formatBytes (bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

function blobToURL (blob) {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = function () {
      const base64data = reader.result
      resolve(base64data)
    }
  })
}

const ModalComponent = ({ pdf_url }) => {
  const [pdfData, setPdfData] = React.useState('')
  const [loadingPdf, setLoadingPdf] = React.useState(true)
  const [numPages, setNumPages] = React.useState()

  function onDocumentLoadSuccess ({ numPages }) {
    setNumPages(numPages)
  }

  const getPdfData = async () => {
    const data = await fetch(pdf_url)
    const blob = await data.blob()
    const url = await blobToURL(blob)
    setPdfData(url)
    setLoadingPdf(false)
  }

  React.useEffect(() => {
    getPdfData()
  }, [])
  if (loadingPdf) {
    return <AlternativeSpinner />
  }
  return (
    <Document
      renderMode='canvas'
      className='pdf-container'
      file={pdfData}
      onLoadSuccess={args => onDocumentLoadSuccess(args)}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
      ))}
    </Document>
  )
}

const ViewComponent = ({ item }) => {
  const [openModal, setOpenModal] = React.useState(false)
  const name = item.fileName || item.name || item.filename

  let fileType = fileTypes.find(fileItem => fileItem.type === 'other')
  fileTypes.forEach(fileItem => {
    if (name.endsWith(fileItem.type)) {
      fileType = fileItem
    }
  })

  return (
    <>
      <Container onClick={() => setOpenModal(true)}>
        <div
          style={{
            width: '5em',
            height: '5em',
            margin: 'auto',
            padding: '1em'
          }}
        >
          <img
            style={{ height: '100%', width: '100%' }}
            src={fileType.icon}
            alt='attachment'
          />
        </div>
        <div
          style={{
            alignItems: 'center',
            color: '#666',
            display: 'flex',
            flexGrow: '1',
            fontSize: '0.6em',
            justifyContent: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <span
              style={{
                fontSize: '12px'
              }}
            >
              {' '}
              {parseText(name, 10)}{' '}
            </span>
          </div>
        </div>
      </Container>
      <Modal
        style={{ top: 20 }}
        visible={openModal}
        destroyOnClose
        onCancel={() => setOpenModal(false)}
        footer={false}
      >
        <ModalComponent pdf_url={item.url} />
      </Modal>
    </>
  )
}

const AttachmentComponent = ({ attachments, download, deleteHandle }) => {
  return attachments.map((item, index) => {
    if (item._destroy) {
      return null
    }

    const name = item.fileName || item.name || item.filename

    let fileType = fileTypes.find(fileItem => fileItem.type === 'other')
    fileTypes.forEach(fileItem => {
      if (name.endsWith(fileItem.type)) {
        fileType = fileItem
      }
    })
    const id = item.id || item.uid

    return (
      <FileContainer
        key={id}
        style={{
          border: '1px solid',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          marginTop: '16px',
          padding: '15px'
        }}
      >
        {download ? (
          <>
            <ViewComponent item={item} />
            <a
              target='_blank'
              rel='noreferrer'
              href={item.url}
              download={item.filename}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <DownloadOutlined style={{ fontSize: '2em' }} />
            </a>
          </>
        ) : (
          <div
            style={{
              position: 'relative'
            }}
          >
            <div
              style={{
                width: '5em',
                height: '5em',
                margin: 'auto',
                padding: '1em'
              }}
            >
              <img
                style={{ height: '100%', width: '100%' }}
                src={fileType.icon}
                alt='attachment'
              />
            </div>
            <div
              style={{
                alignItems: 'center',
                color: 'white',
                cursor: 'default',
                display: 'flex',
                flexGrow: '1',
                fontSize: '0.6em'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <span style={{ color: '#666' }}> {parseText(name, 15)} </span>
                <span style={{ color: '#666' }}>
                  {' '}
                  {formatBytes(item.size)}{' '}
                </span>
              </div>
            </div>
            <CloseCircleFilled
              alt='Delete file'
              style={{
                color: 'red',
                fontSize: '1.5em',
                cursor: 'pointer',
                position: 'absolute',
                right: '-25px',
                top: '-25px',
                background: '#fff',
                borderRadius: '50%'
              }}
              onClick={() => deleteHandle(item, index)}
            />
          </div>
        )}
      </FileContainer>
    )
  })
}

export default AttachmentComponent
