import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import {
  Container,
  ContainerTitle,
  InnerContainer
} from './styles/Views.styles'
import { AlternativeSpinner as Loading } from '../loading/Loading'
import { SubmitButton } from '../form/styles/LoginForm.style'
import http from '../../utils/http'

const confirmUserRequest = token => {
  const confirmationUrl = '/auth/confirmation'
  return http
    .get(`${confirmationUrl}?confirmation_token=${token}`)
    .then(res => res)
    .catch(err => err.response)
}

const ConfirmUserView = () => {
  const [status, setStatus] = React.useState('waiting')
  const [errors, setErrors] = React.useState([])
  const location = useLocation()
  const history = useHistory()
  const confirmUser = async token => {
    const response = await confirmUserRequest(token)
    if (response.status === 200) {
      setStatus('confirmed')
    } else if (response.status === 422) {
      setStatus('already')
    } else {
      const errorMsg = 'Error on confirming account.'
      !errors.includes(errorMsg) && setErrors(errors.concat(errorMsg))
      setStatus('waiting')
    }
  }

  React.useState(async () => {
    const queryString = 'confirmation_token'
    const queryparams = new URLSearchParams(location.search)
    if (queryparams.has(queryString)) {
      confirmUser(queryparams.get(queryString))
    } else {
      const errorMsg = 'Error on confirming account.'
      !errors.includes(errorMsg) && setErrors(errors.concat(errorMsg))
    }
  }, [])

  return (
    <Container>
      <InnerContainer>
        {errors.map(item => (
          <span key={item} style={{ color: 'red' }}>
            {' '}
            {item}{' '}
          </span>
        ))}
        {status === 'waiting' ? (
          <>
            <ContainerTitle> Confirming user </ContainerTitle>
            <Loading />
          </>
        ) : status === 'confirmed' ? (
          <>
            <ContainerTitle> Account confirmed with success! </ContainerTitle>
            <SubmitButton onClick={() => history.push('/')}>
              Go to Login Page
            </SubmitButton>
          </>
        ) : status === 'already' ? (
          <>
            <ContainerTitle> Account confirmed with success! </ContainerTitle>
            <SubmitButton onClick={() => history.push('/')}>
              Go to Login Page
            </SubmitButton>
          </>
        ) : null}
        <span />
      </InnerContainer>
    </Container>
  )
}

export default ConfirmUserView
