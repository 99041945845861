import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'antd'

const fieldHasError = (touched, error) => touched && !!error

const FormItemWithValidation = ({
  touched,
  error,
  label,
  children,
  ...rest
}) => {
  return (
    <Form.Item
      label={label}
      hasFeedback={fieldHasError(touched, error)}
      validateStatus={fieldHasError(touched, error) ? 'error' : ''}
      help={fieldHasError(touched, error) ? error : ''}
      style={{
        display: 'contents'
      }}
      {...rest}
    >
      {children}
    </Form.Item>
  )
}
FormItemWithValidation.defaultProps = {
  touched: false
}

FormItemWithValidation.propTypes = {
  touched: PropTypes.bool.isRequired,
  error: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node.isRequired
}

export default FormItemWithValidation
