import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import InvestmentsView from '../../components/view/InvestmentsView'
import {
  fetchInvestmentsRequest,
  deleteInvestmentRequest,
  updateInvestmentsPagination
} from '../../store/investments'

const mapDispatchToProps = {
  fetchInvestmentsRequest,
  deleteInvestmentRequest,
  updateInvestmentsPagination
}

const mapStateToProps = ({ investments, auth }) => ({
  investmentItems: investments.items,
  loadingInvestments: investments.isFetching,
  pagination: investments.pagination,
  userProfiles: auth.user.profiles
})

const InvestmentsContainer = props => {
  const {
    fetchInvestmentsRequest,
    investmentItems,
    userProfiles,
    ...rest
  } = props
  const history = useHistory()
  React.useEffect(() => {
    if (
      userProfiles?.includes('superuser') ||
      userProfiles?.includes('admin')
    ) {
      fetchInvestmentsRequest()
    } else {
      history.push('/')
    }
  }, [])
  return (
    <InvestmentsView
      {...rest}
      fetchInvestmentsRequest={fetchInvestmentsRequest}
      data={investmentItems}
      history={history}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(
  InvestmentsContainer
)
