import styled from '@emotion/styled'

export const TitleContainer = styled('div')`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`
