import React from 'react'
import { Table } from 'antd'

import './style/table.css'

const TableComponent = props => {
  return (
    <Table
      {...props}
      rowClassName={(record, index) =>
        index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
      }
    />
  )
}

export default TableComponent
