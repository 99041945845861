import { isEmpty } from 'lodash'

export const required = value =>
  !isEmpty(value) ? undefined : 'This field is required!'
export const minPassLength = value => {
  return value.length >= 6
    ? undefined
    : 'Password must have at least 6 characters'
}
export const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/

export const validEmail = value =>
  emailRegex.test(value) ? undefined : 'Type an valid email'

export const minBRPhoneNumberLength = value =>
  value.replace(/[&/\\\s()_-]/g, '').length === 11
    ? undefined
    : 'Invalid phone number'

export const minUSPhoneNumberLength = value =>
  value.replace(/[&/\\\s()_-]/g, '').length === 10
    ? undefined
    : 'Invalid phone number'
export const validUserStatus = value =>
  value !== 'pending_approval' ? undefined : 'Update user status'

export const validDestination = value => {
  return isEmpty(value) || isEmpty(value.filter(item => !item._destroy))
    ? 'Add at least one destination'
    : undefined
}
