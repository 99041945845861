import React from 'react'

import './style/loading.css'
import Logo from '../../static/logo.png'

const LoadingSpinner = () => {
  return (
    <div className='loader'>
      <img style={{ width: '5em' }} src={Logo} alt='Sharpen Logo' />
    </div>
  )
}

export const AlternativeSpinner = () => {
  return (
    <div className='lds-ripple'>
      <div />
      <div />
    </div>
  )
}

export default LoadingSpinner
