import React from 'react'
import { Input } from 'antd'

import { InputWrapper } from './styles/Input.styles'
import MaskedInput from './MaskedInput'

const InputComponent = ({ input, mask, ...props }) => {
  return (
    <InputWrapper>
      {mask ? (
        <MaskedInput
          mask={mask}
          style={{
            border: 'none'
          }}
          {...input}
          {...props}
        />
      ) : (
        <Input
          style={{
            border: 'none'
          }}
          {...input}
          {...props}
        />
      )}
    </InputWrapper>
  )
}

export default InputComponent
