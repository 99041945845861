import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { LockFilled, CheckCircleFilled } from '@ant-design/icons'

import {
  Container,
  ContainerTitle,
  InnerContainer
} from './styles/Views.styles'
import InputComponent from '../input/StyledInput'
import { SubmitButton } from '../form/styles/LoginForm.style'
import { AlternativeSpinner as Loading } from '../loading/Loading'
import http from '../../utils/http'

const resetPasswordMethod = payload => {
  const resetPasswordUrl = '/auth/password'
  const params = {
    data: {
      id: '',
      type: 'passwords',
      attributes: {
        password: payload.password,
        password_confirmation: payload.password_confirmation,
        reset_password_token: payload.token
      }
    }
  }
  return http
    .put(resetPasswordUrl, params)
    .then(res => res)
    .catch(err => err.response)
}

const ChangePassView = () => {
  const [status, setStatus] = React.useState('waiting')
  const [token, setToken] = React.useState(null)
  const [password, setPassword] = React.useState('')
  const [confirmPass, setConfirmPass] = React.useState('')
  const [errors, setErrors] = React.useState([])
  const location = useLocation()
  React.useState(() => {
    const queryString = 'reset_password_token'
    const queryparams = new URLSearchParams(location.search)
    if (queryparams.has(queryString)) {
      setToken(queryparams.get(queryString))
    }
  }, [])
  const resetPassword = async () => {
    setStatus('loading')
    const response = await resetPasswordMethod({
      password,
      password_confirmation: confirmPass,
      token
    })
    if (response.status === 200) {
      setStatus('confirmed')
    } else if (response.status === 422) {
      const errorMsg = 'Invalid password change request.'
      !errors.includes(errorMsg) && setErrors(errors.concat(errorMsg))
      setStatus('waiting')
    }
  }
  const history = useHistory()
  return (
    <Container>
      <InnerContainer>
        <ContainerTitle>Create a new password</ContainerTitle>
        {status === 'loading' ? (
          <>
            <ContainerTitle>Changing password</ContainerTitle>
            <Loading />
          </>
        ) : status === 'waiting' ? (
          <>
            <span> Enter your new password</span>
            <InputComponent
              prefix={<LockFilled />}
              placeholder='New password'
              value={password}
              onChange={e => setPassword(e.target.value)}
              type='password'
            />
            <InputComponent
              prefix={<LockFilled />}
              placeholder='Confirm new password'
              value={confirmPass}
              onChange={e => setConfirmPass(e.target.value)}
              type='password'
            />
            {errors.map(item => (
              <span key={item} style={{ color: 'red' }}>
                {' '}
                {item}{' '}
              </span>
            ))}
            <SubmitButton onClick={resetPassword}>Change password</SubmitButton>
          </>
        ) : status === 'confirmed' ? (
          <>
            <CheckCircleFilled style={{ fontSize: '2em' }} />
            <span>Password changed successfully!</span>
            <SubmitButton onClick={() => history.push('/')}>
              Go to Login Page
            </SubmitButton>
          </>
        ) : null}
      </InnerContainer>
    </Container>
  )
}

export default ChangePassView
