import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import FormItemWithValidation from '../form/Validation/FormItemWithValidation'

import StyledInput from './StyledInput'
import MaskedInput from './MaskedInput'

const FormInput = ({
  input,
  meta: { touched, error },
  label,
  formItem,
  addon: AddonComponent,
  addonProps,
  placeholder,
  styledInput,
  ...props
}) => {
  const { mask } = props
  return (
    <FormItemWithValidation
      touched={touched}
      error={error}
      label={label}
      {...formItem}
      {...props}
    >
      {styledInput ? (
        <StyledInput placeholder={placeholder} {...input} {...props} />
      ) : mask ? (
        <MaskedInput placeholder={placeholder} {...input} {...props} />
      ) : AddonComponent !== null ? (
        <Input label={label} {...input} addonAfter={AddonComponent} />
      ) : (
        <Input placeholder={placeholder} {...input} {...props} />
      )}
    </FormItemWithValidation>
  )
}

FormInput.defaultProps = {
  addon: null,
  addonProps: {}
}

FormInput.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string
  }),
  addonProps: PropTypes.object,
  formItem: PropTypes.object,
  addon: PropTypes.object,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  styledInput: PropTypes.bool,
  mask: PropTypes.string
}

export default FormInput
