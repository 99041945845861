import React from 'react'

const UserIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 16 15'
      fill='none'
    >
      <path
        d='M12.3181 14.2934H3.21988C2.48615 14.2934 1.82464 14.0715 1.40536 13.6848C0.983764 13.2959 0.883605 12.7972 1.13983 12.3492C2.15306 10.5636 4.81776 9.36328 7.76897 9.36328C10.7202 9.36328 13.4524 10.5932 14.4377 12.4211C14.6683 12.8479 14.5635 13.3086 14.1512 13.6827C13.7296 14.0652 13.0448 14.2934 12.3204 14.2934H12.3181Z'
        stroke='#B9B3D6'
        strokeMiterlimit='10'
      />
      <path
        d='M7.82355 8.39515C9.86566 8.39515 11.5211 6.73969 11.5211 4.69757C11.5211 2.65546 9.86566 1 7.82355 1C5.78144 1 4.12598 2.65546 4.12598 4.69757C4.12598 6.73969 5.78144 8.39515 7.82355 8.39515Z'
        stroke='#B9B3D6'
        strokeMiterlimit='10'
      />
    </svg>
  )
}

export default UserIcon
