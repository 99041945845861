/* eslint-disable react/display-name */
import React from 'react'
import { Input, Dropdown, Menu } from 'antd'
import {
  SyncOutlined,
  SearchOutlined,
  PlusCircleOutlined,
  MoreOutlined
} from '@ant-design/icons'
import { useMediaQuery } from 'react-responsive'

import TableComponent from '../table/Table'
import { AlternativeSpinner } from '../loading/Loading'
import { userProfileOptions } from '../../utils/constants'
import EditIcon from '../../static/edit-icon'
import AddIcon from '../../static/add-icon'
import { AddButton } from '../button/AddButton'
import { TableContainer } from '../container/TableContainer'
import { H2 } from '../h2/CustomH2'
import { TableButtonsContainer } from '../container/TableButtonsContainer'
import { ResetButton } from '../button/ResetButton'
import { TitleContainer } from '../container/TitleContainer'

const statusColor = {
  approved: '#8EF151',
  rejected: '#F15B51',
  pending_approval: '#F1B151'
}

const DropdownMenu = ({ record, history }) => (
  <Menu style={{ borderRadius: '8px' }}>
    <Menu.Item>
      <button
        style={{ border: 'none', background: 'transparent' }}
        onClick={() => history.push(`/users/edit/${record.id}`)}
      >
        <EditIcon />
      </button>
    </Menu.Item>
  </Menu>
)

const UsersView = props => {
  const {
    data,
    history,
    fetchUsersRequest,
    loadingUsers,
    updateUsersPagination,
    pagination
  } = props
  const [nameFilter, setNameFilter] = React.useState('')
  const isPhone = useMediaQuery({ query: '(max-width: 767px)' })
  const handleSizeChange = (current, size) => {
    updateUsersPagination({ current, pageSize: size })
    fetchUsersRequest()
  }
  const columns = [
    {
      title: () => {
        return <span style={{ paddingLeft: '15px' }}> ID </span>
      },
      dataIndex: 'id',
      render: (_, record) => {
        return <span style={{ paddingLeft: '25px' }}>{record.id}</span>
      }
    },
    {
      title: () => {
        return <span> Profile </span>
      },
      dataIndex: 'type',
      render: (_, record) => {
        return (
          <>
            <span>
              {userProfileOptions
                .reduce((acc, opt) => {
                  if (record?.profiles?.includes(opt.value)) {
                    return acc.concat(opt.label)
                  }
                  return acc
                }, [])
                .join(',')}
            </span>
          </>
        )
      }
    },
    {
      title: () => {
        return <span> Name </span>
      },
      key: 'name',
      dataIndex: 'name',
      render: (text, record) => {
        return (
          <span style={{ color: '#4A55A5', fontWeight: 'bold' }}>
            {' '}
            {record.name && record.name}{' '}
          </span>
        )
      }
    },
    {
      title: () => {
        return <span> Email </span>
      },
      key: 'email',
      dataIndex: 'email'
    },
    {
      title: () => {
        return <span> Phone </span>
      },
      key: 'phone',
      dataIndex: 'phone'
    },
    {
      title: () => {
        return <span> Document </span>
      },
      key: 'document',
      render: (_, record) => {
        return (
          record.document_type && (
            <span>
              {' '}
              {record.document_type} : {record.document_number}{' '}
            </span>
          )
        )
      }
    },
    {
      title: () => {
        return <span> Status </span>
      },
      dataIndex: 'status',
      render: (_, record) => {
        return (
          record.status && (
            <div
              style={{
                height: '1em',
                width: '1em',
                background: statusColor[record.status],
                borderRadius: '50%'
              }}
            />
          )
        )
      }
    },
    {
      title: () => {
        return <span> Actions </span>
      },
      key: 'actions',
      render: (_, record) => {
        return (
          record.id && (
            <button
              style={{
                background: 'transparent',
                border: 'none',
                paddingLeft: '30px',
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/users/edit/${record.id}`)}
            >
              <EditIcon />
            </button>
          )
        )
      }
    }
  ]
  const mobileColumn = [
    {
      title: () => {
        return <span style={{ paddingLeft: '20px' }}> Users </span>
      },
      render: (_, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingRight: '20px'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <span style={{ fontWeight: 'bolder', paddingLeft: '20px' }}>
                {' '}
                {record.name}{' '}
              </span>
              <span style={{ paddingLeft: '20px' }}>
                {record.type && record.type}
              </span>
              <span style={{ paddingLeft: '20px' }}>
                {' '}
                {record.email && record.email}{' '}
              </span>
              <span style={{ paddingLeft: '20px' }}>
                {' '}
                {record.phone && record.phone}{' '}
              </span>
            </div>
            <Dropdown
              trigger={['click']}
              overlay={<DropdownMenu record={record} history={history} />}
            >
              <MoreOutlined style={{ fontSize: '2em' }} />
            </Dropdown>
          </div>
        )
      }
    }
  ]

  const getColumns = isPhone ? mobileColumn : columns
  return (
    <TableContainer style={{ width: '100%' }}>
      <TitleContainer style={{ display: 'flex' }}>
        <H2 style={{ marginTop: '16px' }}>Users</H2>
        <TableButtonsContainer>
          <div
            style={{
              display: 'flex',
              gap: '8px'
            }}
          >
            <ResetButton style={{ background: '#E0E1E1' }}>
              <SyncOutlined onClick={() => fetchUsersRequest()} />
            </ResetButton>
            <Input
              placeholder='Search by name'
              value={nameFilter}
              onChange={e => setNameFilter(e.target.value)}
              style={{ width: '70%' }}
              suffix={
                <SearchOutlined
                  onClick={() => {
                    fetchUsersRequest({
                      filters: { 'q[name_cont]': nameFilter }
                    })
                  }}
                />
              }
            />
          </div>
          <div>
            <AddButton
              style={{ background: '#E0E1E1' }}
              icon={<PlusCircleOutlined />}
              onClick={() => history.push('/users/new')}
            >
              <AddIcon />
              Add user
            </AddButton>
          </div>
        </TableButtonsContainer>
      </TitleContainer>
      <TableComponent
        rowKey={record => record.id}
        pagination={{
          ...pagination,
          onShowSizeChange: handleSizeChange,
          onChange: handleSizeChange
        }}
        loading={{
          spinning: loadingUsers,
          indicator: <AlternativeSpinner />
        }}
        dataSource={data}
        columns={getColumns}
        style={{
          padding: '0.75em 0'
        }}
        size='small'
      />
    </TableContainer>
  )
}

export default UsersView
