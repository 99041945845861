import React from 'react'
import { Modal } from 'antd'

const ModalComponent = ({
  visible,
  modalData,
  handleOk,
  handleCancel,
  ...rest
}) => {
  return (
    <Modal visible={visible} onOk={handleOk} onCancel={handleCancel} {...rest}>
      <span style={{ marginTop: '20px' }}>
        {' '}
        Are you sure that you want to delete{' '}
        <strong style={{ color: '#776AAE' }}>
          {modalData.name || modalData.title}
        </strong>{' '}
        ?{' '}
      </span>
    </Modal>
  )
}

export default ModalComponent
