import React from 'react'

const InvestmentsIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 16 15'
      fill='none'
    >
      <path
        d='M14.9981 14.4873H0.820312'
        stroke='#B9B3D6'
        strokeWidth='0.75'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M9.5376 1H14.7356C14.8812 1 15.0001 1.10044 15.0001 1.22342V5.60978'
        stroke='#B9B3D6'
        strokeWidth='0.75'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M0.820312 12.9098L6.47134 6.56804C6.54923 6.48195 6.69271 6.46351 6.79724 6.52295L8.99042 7.79581C9.09905 7.85935 9.24663 7.8368 9.32247 7.74457L14.8075 1.14453'
        stroke='#B9B3D6'
        strokeWidth='0.75'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default InvestmentsIcon
