import React from 'react'
import { useHistory } from 'react-router-dom'
import { MailOutlined, CheckCircleFilled } from '@ant-design/icons'

import InputComponent from '../input/StyledInput'
import {
  Container,
  ContainerTitle,
  InnerContainer
} from './styles/Views.styles'
import { SubmitButton } from '../form/styles/LoginForm.style'
import { AlternativeSpinner as Loading } from '../loading/Loading'
import http from '../../utils/http'

const resetPasswordMethod = email => {
  const resetPasswordUrl = '/auth/password'
  const params = {
    data: {
      type: 'passwords',
      attributes: {
        email: email
      }
    }
  }
  return http
    .post(resetPasswordUrl, params)
    .then(res => res)
    .catch(err => err.response)
}

const RecoverPassView = () => {
  const [status, setStatus] = React.useState('waiting')
  const [inputValue, setInputValue] = React.useState('')
  const [errors, setErrors] = React.useState([])
  const history = useHistory()
  const requestResetPassword = async email => {
    setErrors([])
    setStatus('loading')
    const response = await resetPasswordMethod(email)
    if (response.status === 200) {
      setStatus('accepted')
    } else if (response.status === 422) {
      setStatus('waiting')
      setErrors(errors.concat('Email não encontrado'))
    }
  }
  return (
    <Container>
      <InnerContainer>
        <ContainerTitle>Password recovery</ContainerTitle>
        {errors.map(item => (
          <span style={{ color: 'red' }} key={item}>
            {' '}
            {item}{' '}
          </span>
        ))}
        {status === 'loading' ? (
          <>
            <ContainerTitle> Verifying email </ContainerTitle>
            <Loading />
          </>
        ) : status === 'waiting' ? (
          <>
            <span>
              {' '}
              Enter your registered email to receive instructions to change your
              password
            </span>
            <InputComponent
              prefix={<MailOutlined />}
              placeholder='Email Address'
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
            />
            <SubmitButton onClick={() => requestResetPassword(inputValue)}>
              Send
            </SubmitButton>
          </>
        ) : status === 'accepted' ? (
          <>
            <CheckCircleFilled style={{ fontSize: '2em' }} />
            <span>
              If there is any acount with the informed email address, a message
              with instructions was sent to it.
            </span>
            <SubmitButton onClick={() => history.push('/')}>
              Go to Login Page
            </SubmitButton>
          </>
        ) : null}
      </InnerContainer>
    </Container>
  )
}

export default RecoverPassView
