import React from 'react'
import FormEditor from '../../input/FormEditorInput'

const FormEditorValidator = ({
  input,
  label,
  meta: { touched, error, warning },
  isRequired,
  showError,
  ...rest
}) => {
  return (
    <div>
      <FormEditor
        {...input}
        {...rest}
        onBlur={() => input.onBlur(input.value)}
      />
      {showError &&
        touched &&
        ((error && <span style={{ color: '#ff4d4f' }}>{error}</span>) ||
          (warning && <span style={{ color: '#ff4d4f' }}>{warning}</span>))}
    </div>
  )
}

FormEditorValidator.defaultProps = {
  showError: true
}

export default FormEditorValidator
