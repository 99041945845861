import React from 'react'
import { Button, Collapse } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { compose } from 'redux'
import { reduxForm, Field, FieldArray } from 'redux-form'
import { useMediaQuery } from 'react-responsive'
import {
  FormButtonsArea,
  FormViewWrapper,
  FormWrapper,
  FieldsWrapper,
  FormTitle
} from './styles/Form.style'
import FormInput from '../input/FormInput'
import SelectFormInput from '../input/SelectFormInput'
import AlternateInput from '../input/AlternateInput'
import PhoneInput from '../input/PhoneInput'
import { required, validEmail, validUserStatus } from './Validation/validation'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import ReturnIcon from '../../static/return-icon'
import { LineDivider } from '../view/MessageView'
import { FormContainer } from '../container/FormContainer'

const Container = styled('div')`
  .ant-select-selector {
    background-color: transparent;
    border: none !important;
    span {
      color: #484848;
      font-family: 'Montserrat';
    }
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #776aae9e !important;
  }
  .ant-select-focused:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
    box-shadow: none;
  }
  .ant-select {
    .ant-select-arrow {
      > span > svg > path {
        fill: #484848;
      }
    }
  }
  .ant-collapse-content-box div > div {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: none;
  }
  .ant-pagination {
    margin-top: 0px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background: transparent;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    background: transparent;
    border: none;
  }
  .ant-checkbox {
    &:hover {
      border: 1px solid #484848;
    }
  }
  a:hover {
    font-weight: 600;
    svg > circle {
      stroke: #eaeced;
      fill: #776aae;
    }
    svg > path {
      stroke: #fff;
    }
  }
`

const SelectStatus = props => {
  const { input } = props
  const options = [
    {
      value: 'pending_approval',
      label: 'Pending Approval'
    },
    {
      value: 'approved',
      label: 'Approved'
    },
    {
      value: 'rejected',
      label: 'Rejected'
    }
  ]
  return (
    <SelectFormInput
      style={{ width: '15em' }}
      {...props}
      options={options}
      onBlur={() => input.onBlur(input.value)}
    />
  )
}
const InvestEntitySelect = props => {
  const { input, options } = props
  return (
    <SelectFormInput
      {...props}
      options={options}
      onBlur={() => input.onBlur(input.value)}
    />
  )
}

const InvestorSelect = props => {
  const { input } = props
  return (
    <SelectFormInput
      {...props}
      showSearch
      onBlur={() => input.onBlur(input.value)}
    />
  )
}

const renderPersonalInfoForm = ({ namePreffix = '', formValues }) => {
  const documentTypeValue = formValues && formValues['document_type']
  return (
    <>
      <Field
        name={`${namePreffix}name`}
        component={FormInput}
        placeholder={'Name'}
        required
        validate={[required]}
      />
      <Field
        name={`${namePreffix}email`}
        component={FormInput}
        placeholder={'Email'}
        required
        validate={[required, validEmail]}
      />
      <PhoneInput
        name={`${namePreffix}phone`}
        namePreffix={`${namePreffix}`}
        placeholder={'Phone'}
      />
      <AlternateInput
        customSelect
        documentSelectStyle={{ width: '8em' }}
        Field={Field}
        documentTypeValue={documentTypeValue}
      />
    </>
  )
}

const renderAddAssessorForm = ({ fields, investmentOptions, isPhone }) => {
  const fieldArrayValues = fields.getAll()
  return (
    <div>
      <Button onClick={() => fields.push()}>Add Assessor</Button>
      {fields.map((newItem, fieldIndex) => {
        const documentTypeValue =
          fieldArrayValues &&
          fieldArrayValues[fieldIndex] &&
          fieldArrayValues[fieldIndex]['document_type']
        return (
          <Collapse
            key={`${newItem}-${fieldIndex}`}
            style={{ margin: '1em 0' }}
          >
            <Collapse.Panel
              header={
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    textTransform: 'capitalize'
                  }}
                >
                  <span> Assessor {fieldIndex + 1} </span>
                  <CloseOutlined
                    onClick={() => {
                      fields.remove(fieldIndex)
                    }}
                  />
                </div>
              }
              key={fieldIndex}
            >
              <FieldsWrapper isPhone={isPhone}>
                <Field
                  name={`${newItem}.name`}
                  component={FormInput}
                  placeholder={`Assessor name`}
                />
                <Field
                  name={`${newItem}.email`}
                  component={FormInput}
                  placeholder={`Assessor email`}
                  validate={[validEmail]}
                />
                <PhoneInput
                  name={`${newItem}.phone`}
                  namePreffix={`${newItem}.`}
                  placeholder={`Assessor phone`}
                />
                <AlternateInput
                  customSelect
                  documentSelectStyle={{ width: '8em' }}
                  name={`${newItem}`}
                  Field={Field}
                  documentTypeValue={documentTypeValue}
                />
                <Field
                  name={`${newItem}.investment_entity_id`}
                  component={InvestEntitySelect}
                  placeholder='Choose an investment entity'
                  options={investmentOptions}
                />
              </FieldsWrapper>
            </Collapse.Panel>
          </Collapse>
        )
      })}
    </div>
  )
}

const renderInvestorForm = ({
  investmentOptions,
  formValues,
  edit,
  clearFields,
  untouch,
  statusValue,
  profileValue,
  isPhone
}) => {
  return (
    <>
      <FieldsWrapper isPhone={isPhone}>
        {renderPersonalInfoForm({ formValues })}
        {(!edit || (statusValue && statusValue === 'pending_approval')) && (
          <>
            <Field
              name='investment_entity_ids'
              component={InvestEntitySelect}
              placeholder={'Investment entity'}
              mode='multiple'
              options={investmentOptions}
              required
              validate={[profileValue === 'investor' && required]}
            />
            <div
              style={{
                gridColumn: '-1 / 1'
              }}
            >
              <FieldArray
                name='assessors'
                component={renderAddAssessorForm}
                investmentOptions={investmentOptions}
                formValues={formValues}
                clearFields={clearFields}
                untouch={untouch}
                isPhone={isPhone}
              />
            </div>
          </>
        )}
      </FieldsWrapper>
    </>
  )
}

const renderAssessorForm = ({
  investmentOptions,
  formValues,
  users,
  edit,
  statusValue,
  profileValue,
  isPhone
}) => {
  const investorOptions = users.map(item => ({
    label: item.name,
    value: item.id
  }))
  return (
    <>
      <FieldsWrapper isPhone={isPhone}>
        {renderPersonalInfoForm({ formValues })}
        {(!edit || (statusValue && statusValue === 'pending_approval')) && (
          <>
            <Field
              name={'investment_entity_id'}
              component={InvestEntitySelect}
              placeholder='Choose an investment entity'
              options={investmentOptions}
              validate={[profileValue === 'assessor' && required]}
            />
            <Field
              name={'investor_account_id'}
              component={InvestorSelect}
              placeholder='Select an Investor'
              validate={[profileValue === 'assessor' && required]}
              options={investorOptions}
            />
          </>
        )}{' '}
      </FieldsWrapper>
    </>
  )
}

const renderDefaultForm = ({ formValues, isPhone }) => {
  return (
    <FieldsWrapper isPhone={isPhone}>
      {renderPersonalInfoForm({ formValues })}
    </FieldsWrapper>
  )
}

const SelectProfile = props => {
  const { input, fieldValue, clearFields, untouch, isSuperUser = false } = props
  const adminOption = isSuperUser ? [{ value: 'admin', label: 'Admin' }] : []

  const userOptions = [
    ...adminOption,
    {
      value: 'investor',
      label: 'Investor'
    },
    {
      value: 'assessor',
      label: 'Assessor'
    },
    {
      value: 'potential_investor',
      label: 'Potential Investor'
    }
  ]
  const { onChange } = input
  return (
    <SelectFormInput
      style={{ width: '15em' }}
      {...props}
      options={userOptions}
      onBlur={() => input.onBlur(input.value)}
      onChange={value => {
        if (fieldValue !== 'investor') {
          clearFields(false, false, 'investors')
          untouch('investors')
        } else if (fieldValue !== 'assessor') {
          clearFields(false, false, 'assessors')
          untouch('assessors')
        }
        onChange(value)
      }}
    />
  )
}

const UsersForm = props => {
  const {
    clearFields,
    untouch,
    isFormValid,
    formValues,
    onSubmit,
    handleSubmit,
    submit,
    investments,
    edit,
    initialValues,
    users,
    userProfiles,
    isFetching
  } = props
  const isPhone = useMediaQuery({ query: '(max-width: 767px)' })
  const profileValue = formValues && formValues['profile']
  const statusValue = initialValues && initialValues['status']
  const investmentOptions = investments.map(item => {
    return {
      label: item.name,
      value: item.id
    }
  })
  const isAssessorInfoValid = () => {
    if (
      profileValue !== 'investor' ||
      (profileValue === 'investor' &&
        (formValues &&
          (formValues['assessors'] === undefined ||
            (formValues['assessors'].length >= 0 &&
              !formValues['assessors'].some(item => item === undefined)))))
    ) {
      return true
    } else return false
  }
  return (
    <FormViewWrapper>
      <Container style={{ marginBottom: '16px' }}>
        <Link
          style={{
            display: 'flex',
            color: '#484848',
            alignItems: 'center',
            gap: '4px',
            width: 'fit-content'
          }}
          to='/users'
        >
          {' '}
          <ReturnIcon />
          Go back to users list{' '}
        </Link>
      </Container>
      <FormTitle style={{ color: '#484848' }}>
        {edit ? 'Edit user' : 'Create a new user'}
      </FormTitle>
      <LineDivider style={{ marginBottom: '7px' }} />
      <FormContainer>
        <FormWrapper onFinish={handleSubmit(onSubmit)}>
          <div
            style={{
              display: 'flex',
              width: 'fit-content'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: 'fit-content'
              }}
            >
              <span style={{ color: '#484848' }}> Set user profile</span>
              <Field
                name='profile'
                component={SelectProfile}
                clearFields={clearFields}
                untouch={untouch}
                fieldValue={profileValue}
                isSuperUser={userProfiles.includes('superuser')}
                required
                validate={[required]}
              />
            </div>
            {edit &&
              statusValue === 'pending_approval' && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 'fit-content',
                  marginLeft: '2em'
                }}
              >
                <span> Set user status</span>
                <Field
                  name='status'
                  component={SelectStatus}
                  required={statusValue === 'pending_approval'}
                  validate={
                    statusValue === 'pending_approval' && [
                      required,
                      validUserStatus
                    ]
                  }
                />
              </div>
            )}
          </div>
          {profileValue === 'investor'
            ? renderInvestorForm({
              investmentOptions,
              formValues,
              edit,
              clearFields,
              untouch,
              statusValue,
              profileValue,
              isPhone
            })
            : profileValue === 'assessor'
              ? renderAssessorForm({
                investmentOptions,
                formValues,
                users,
                edit,
                statusValue,
                profileValue,
                isPhone
              })
              : renderDefaultForm({ formValues, isPhone })}
        </FormWrapper>
      </FormContainer>
      <LineDivider />
      <FormButtonsArea>
        <Button
          loading={isFetching}
          disabled={
            profileValue === undefined ||
            profileValue === 'Select profile' ||
            profileValue === '' ||
            !isFormValid ||
            !isAssessorInfoValid() ||
            isFetching
          }
          onClick={() => submit('usersForm')}
          type='submit'
        >
          Save user
        </Button>
      </FormButtonsArea>
    </FormViewWrapper>
  )
}

const enhance = compose(
  reduxForm({ form: 'usersForm', initialValues: { phone_code: '+55' } })
)

export default enhance(UsersForm)
