import { all, takeLatest, call, put } from 'redux-saga/effects'

import * as actions from './actions'
import * as services from './services'

export function * signIn ({ payload }) {
  try {
    const params = {
      data: {
        type: 'sessions',
        attributes: {
          ...payload
        }
      }
    }
    const response = yield call(services.signIn, params)
    if (response.status === 200) {
      const { headers, data: { data: { attributes, id } } } = response
      yield put(actions.SIGN_IN_SUCCESSFUL({ user: { ...attributes, id } }))
      yield put(actions.updateCredentialsRequest(headers))
    }
  } catch (err) {
    const getErrorMessage = err.response.data.errors.map(item => item.detail)
    yield put(actions.SIGN_IN_FAILURE(getErrorMessage))
  }
}

export function * updateCredentials ({ payload }) {
  try {
    const headers = payload
    if (
      headers.hasOwnProperty('access-token') &&
      typeof headers['access-token'] === 'string' &&
      headers['access-token'].length
    ) {
      yield put(actions.updateCredentialsSuccess({ headers: headers }))
    }
  } catch (e) {
    yield put(actions.updateCredentialsFailure(e))
  }
}

// Watchers
export function * watchSignIn () {
  yield takeLatest(actions.SIGN_IN, signIn)
}

export function * watchUpdateCredentials () {
  yield takeLatest(actions.updateCredentialsRequest, updateCredentials)
}

export default function * authSagas () {
  yield all([watchSignIn(), watchUpdateCredentials()])
}
