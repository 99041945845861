/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { useParams, useHistory } from 'react-router-dom'
import http from '../../utils/http'
import { clearFields, untouch, isValid, getFormValues } from 'redux-form'

import UserForm from '../../components/form/UsersForm'
import { updateCredentialsRequest } from '../../store/auth/actions'
import { fetchInvestmentsRequest } from '../../store/investments'
import {
  updateUserRequest,
  fetchUsersRequest,
  updateUsersPagination
} from '../../store/users'

const mapStateToProps = state => ({
  isFormValid: isValid('usersForm')(state),
  formValues: getFormValues('usersForm')(state),
  investments: state.investments.items,
  userProfiles: state.auth.user.profiles,
  users: state.users.items,
  isFetching: state.users.isFetching
})

const mapDispatchToProps = {
  clearFields,
  untouch,
  updateCredentialsRequest,
  fetchInvestmentsRequest,
  updateUserRequest,
  fetchUsersRequest,
  updateUsersPagination
}

const fetchUserData = id => {
  const usersEndpoint = '/users'
  return http
    .get(`${usersEndpoint}/${id}`)
    .then(res => res)
    .catch(err => err.response)
}

const UserFormContainer = props => {
  const {
    updateCredentialsRequest,
    fetchInvestmentsRequest,
    updateUserRequest,
    userProfiles,
    fetchUsersRequest,
    updateUsersPagination
  } = props
  const { id } = useParams()
  const history = useHistory()
  const [initialValues, setInitialValues] = React.useState({})
  const [renderReady, setRenderReady] = React.useState(false)
  React.useEffect(async () => {
    if (
      userProfiles?.includes('superuser') ||
      userProfiles?.includes('admin')
    ) {
      updateUsersPagination({ current: 1, pageSize: 20 })
      fetchInvestmentsRequest()
      fetchUsersRequest({
        filters: {
          'q[profiles_role_eq]': 'investor'
        },
        fields: 'name'
      })
      const response = await fetchUserData(id)
      if (response.status === 200) {
        const { data: { data: { attributes, id } } } = response
        const { phone, profiles, investment_entities, ...restAttr } = attributes
        const profileAttr = profiles.length
          ? { profile: profiles[0] }
          : { profile: '' }
        let phoneAttrs = { phone: phone, phone_code: '+55' }
        if (phone?.includes('+55')) {
          phoneAttrs.phone = phone?.replace('+55', '').trim()
          phoneAttrs.phone_code = phone?.substr(0, 3)
        } else if (phone?.includes('+1')) {
          phoneAttrs.phone = phone.replace('+1', '').trim()
          phoneAttrs.phone_code = phone.substr(0, 2)
        }
        const investmentAttr = investment_entities
          ? {
            investment_entity_ids: investment_entities.map(
              item => item.id + ''
            )
          }
          : {}
        setInitialValues({
          ...restAttr,
          ...profileAttr,
          ...phoneAttrs,
          ...investmentAttr,
          id
        })
        updateCredentialsRequest(response.headers)
      } else {
        history.push('/')
      }
    } else {
      history.push('/')
    }
  }, [])
  React.useEffect(
    () => {
      if (!isEmpty(initialValues)) {
        setRenderReady(true)
      }
    },
    [initialValues]
  )

  const onSubmit = data => {
    const { profiles, phone_code, ...attrs } = data
    const assessorAttrs = attrs['assessors']
      ? {
        assessors: attrs['assessors'].map(item => {
          const { phone_code, ...restAttrs } = item
          const phoneAttr = `${phone_code} ${restAttrs['phone']}`
          if (restAttrs.hasOwnProperty('investment_entity_id')) {
            return {
              ...restAttrs,
              investment_entity_id: restAttrs['investment_entity_id'] * 1,
              phone: phoneAttr
            }
          }
          return {
            ...restAttrs,
            phone: phoneAttr
          }
        })
      }
      : {}
    const investmentEntityIdsAttr = attrs['investment_entity_ids']
      ? {
        investment_entity_ids: attrs['investment_entity_ids'].map(
          item => item * 1
        )
      }
      : {}
    const assessorInvestorAttrs =
      attrs['profile'] === 'assessor' &&
      attrs['investment_entity_id'] &&
      attrs['investor_account_id']
        ? {
          investment_entity_id: attrs['investment_entity_id'] * 1,
          investor_account_id: attrs['investor_account_id'] * 1
        }
        : {}

    const formattedData = {
      ...attrs,
      ...investmentEntityIdsAttr,
      phone: `${phone_code} ${attrs['phone']}`,
      ...assessorAttrs,
      ...assessorInvestorAttrs
    }
    updateUserRequest({ attributes: formattedData, history })
  }
  if (renderReady) {
    return (
      <UserForm
        edit
        {...props}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    )
  } else return null
}

export default connect(mapStateToProps, mapDispatchToProps)(UserFormContainer)
