import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { all } from 'redux-saga/effects'

// Reducers
import authReducer from './auth/reducers'
import { reportsReducer } from './reports/'
import { AudiencesReducer } from './audiences'
import { UsersReducer } from './users'
import { InvestmentsReducer } from './investments'

// Sagas
import authSagas from './auth/sagas'
import { reportsSagas } from './reports/sagas'
import { AudiencesSagas } from './audiences/sagas'
import { UsersSagas } from './users/sagas'
import { InvestmentsSagas } from './investments/sagas'

export function * rootSaga () {
  yield all([
    authSagas(),
    reportsSagas(),
    AudiencesSagas(),
    UsersSagas(),
    InvestmentsSagas()
  ])
}

export default combineReducers({
  auth: authReducer,
  form: formReducer,
  reports: reportsReducer,
  audiences: AudiencesReducer,
  users: UsersReducer,
  investments: InvestmentsReducer
})
