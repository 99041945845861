import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { ViewTitle } from './styles/Views.styles'
import { Container, LineDivider } from './MessageView'
import ReturnIcon from '../../static/return-icon'
import UserIcon from '../../static/user-icon'
import MailIcon from '../../static/mail-icon'
import PhoneIcon from '../../static/phone-icon'
import DocumentIcon from '../../static/document-icon'

const UserAttr = styled('div')`
  align-items: left;
  display: flex;
  justify-content: left;
  width: 100%;
`

const UserCard = styled('div')`
  align-items: center;
  border: 1px solid #484848;
  border-radius: 16px;
  cursor: default;
  color: #484848;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: ${props => (props.isPhone ? '12em' : '15em')};
  min-width: 250px;
  background-color: #fff;
  > svg:nth-of-type(1) {
    width: 42px;
    height: 42px;
    path {
      stroke: #484848;
    }
  }
  svg {
    width: 24px;
    height: 24px;
  }
`

export const CardsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  @media (max-width: 767px) {
    justify-content: center;
  }
`

const SingleAudienceView = props => {
  const isPhone = useMediaQuery({ query: '(max-width: 767px)' })
  const { initialValues } = props
  return (
    <div style={{ textAlign: 'initial' }}>
      <Container style={{ marginBottom: '16px', marginTop: '16px' }}>
        <Link
          style={{
            display: 'flex',
            color: '#484848',
            alignItems: 'center',
            gap: '4px',
            width: 'fit-content'
          }}
          to='/audiences'
        >
          <ReturnIcon />
          Go back to audiences
        </Link>
      </Container>
      <ViewTitle> {initialValues.name} </ViewTitle>
      <LineDivider style={{ marginBottom: '16px', marginTop: '16px' }} />
      <CardsContainer>
        {initialValues.userData.map(user => {
          return (
            <UserCard isPhone={isPhone} key={user.id}>
              <UserIcon />
              <span style={{ fontSize: isPhone ? '1em' : '1.5em' }}>
                {' '}
                {user.name}{' '}
              </span>
              <LineDivider style={{ width: '100%', marginBottom: '12px' }} />
              <div>
                <UserAttr>
                  <MailIcon />
                  <span style={{ marginLeft: '0.5em' }}> {user.email} </span>
                </UserAttr>
                <UserAttr>
                  <PhoneIcon />
                  <span style={{ marginLeft: '0.5em' }}> {user.phone} </span>
                </UserAttr>
                {(user.document_type || user.document_number) && (
                  <UserAttr>
                    <DocumentIcon />
                    <span style={{ marginLeft: '0.5em' }}>
                      {' '}
                      <span style={{ textTransform: 'upperCase' }}>
                        {user?.document_type}:
                      </span>
                      <span> {user?.document_number}</span>
                    </span>
                  </UserAttr>
                )}
              </div>
            </UserCard>
          )
        })}
      </CardsContainer>
    </div>
  )
}

export default SingleAudienceView
