import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  fetchReports,
  deleteReport,
  updateReportPagination,
  toggleFavoriteReport
} from '../../store/reports'

import ReportsView from '../../components/view/ReportsView'

const mapStateToProps = ({ reports, auth }) => ({
  reportItems: reports.items,
  loadingReports: reports.isFetching,
  userProfiles: auth.user.profiles,
  pagination: reports.pagination,
  user_id: auth.user.id
})

const mapDispatchToProps = {
  fetchReportsRequest: fetchReports.request,
  deleteReportRequest: deleteReport.request,
  updateReportPagination,
  toggleFavoriteReport: toggleFavoriteReport.request
}

const ReportsContainer = props => {
  const { fetchReportsRequest, reportItems, ...rest } = props
  const history = useHistory()
  React.useEffect(() => {
    fetchReportsRequest({
      'q[kind_eq]': props.kind || 'reports',
      'page[number]': 1,
      'page[size]': props.pagination.pageSize,
      'q[s]': 'created_at desc'
    })
  }, [])
  return (
    <ReportsView
      {...rest}
      fetchReportRequest={fetchReportsRequest}
      history={history}
      data={reportItems}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsContainer)
