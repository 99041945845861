/* eslint-disable import/no-named-as-default */
import DashboardTemplate from '../components/template/DashboardTemplate'
import LoginTemplate from '../components/template/LoginTemplate'

import LoginPage from '../pages/LoginPage'
import SignupPage from '../pages/SignupPage'
import ReportsPage from '../pages/ReportsPage'
import NewReportPage from '../pages/NewReportPage'
import AudiencesPage from '../pages/AudiencesPage'
import AudienceFormPage from '../pages/AudienceFormPage'
import AudienceEditPage from '../pages/AudienceEditPage'
import UsersPage from '../pages/UsersPage'
import UserFormPage from '../pages/UserFormPage'
import DeviceAuthPage from '../pages/DeviceAuthPage'
import RecoverPassPage from '../pages/RecoverPassPage'
import ChangePassPage from '../pages/ChangePassPage'
import ConfirmUserPage from '../pages/ConfirmUserPage'
import UserEditPage from '../pages/UserEditPage'
import InvestmentsPage from '../pages/InvestmentsPage'
import NewInvestmentPage from '../pages/NewInvestmentPage'
import EditInvestmentPage from '../pages/EditInvestmentPage'
import SingleAudiencePage from '../pages/SingleAudiencePage'
import ReportMessagePage from '../pages/ReportMessagePage'
import SettingsPage from '../pages/SettingsPage'
import LegalsPage from '../pages/LegalsPage'
import TransactionsPage from '../pages/TransactionsPage'
import PlanningPage from '../pages/PlanningPage'
import ResultsPage from '../pages/ResultsPage'
import StatementsPage from '../pages/StatementsPage'

/**
 * Only logged users can access these routes
 *
 * - Major precedence when user IS LOGGED:
 *    Overwrites the same paths of the public routes
 * - No match when user IS NOT LOGGED:
 *    Render LoginPage component by default
 */
export const privateRoutes = [
  {
    path: '/',
    component: ReportsPage,
    template: DashboardTemplate,
    default: true
  },
  {
    path: '/reports',
    component: ReportsPage,
    template: DashboardTemplate
  },
  {
    path: '/legals',
    component: LegalsPage,
    template: DashboardTemplate
  },
  {
    path: '/transactions',
    component: TransactionsPage,
    template: DashboardTemplate
  },
  {
    path: '/plannings',
    component: PlanningPage,
    template: DashboardTemplate
  },
  {
    path: '/results',
    component: ResultsPage,
    template: DashboardTemplate
  },
  {
    path: '/statements',
    component: StatementsPage,
    template: DashboardTemplate
  },
  {
    path: '/reports/form',
    component: NewReportPage,
    template: DashboardTemplate
  },
  {
    path: '/reports/:id',
    component: ReportMessagePage,
    template: DashboardTemplate
  },
  {
    path: '/audiences',
    component: AudiencesPage,
    template: DashboardTemplate
  },
  {
    path: '/audiences/new',
    component: AudienceFormPage,
    template: DashboardTemplate
  },
  {
    path: '/audiences/:id',
    component: SingleAudiencePage,
    template: DashboardTemplate
  },
  {
    path: '/audiences/edit/:id',
    component: AudienceEditPage,
    template: DashboardTemplate
  },
  {
    path: '/users',
    component: UsersPage,
    template: DashboardTemplate
  },
  {
    path: '/users/new',
    component: UserFormPage,
    template: DashboardTemplate
  },
  {
    path: '/users/edit/:id',
    component: UserEditPage,
    template: DashboardTemplate
  },
  {
    path: '/investments',
    component: InvestmentsPage,
    template: DashboardTemplate
  },
  {
    path: '/investments/new',
    component: NewInvestmentPage,
    template: DashboardTemplate
  },
  {
    path: '/investments/edit/:id',
    component: EditInvestmentPage,
    template: DashboardTemplate
  },
  {
    path: '/deviceauth',
    component: DeviceAuthPage,
    template: LoginTemplate
  },
  {
    path: '/settings',
    component: SettingsPage,
    template: DashboardTemplate
  }
]

/**
 * Only not logged users can access these routes
 *
 * - Precedence over same paths of private routes when user IS NOT LOGGED:
 *    Overwrites the login rendering
 * - No match when user IS LOGGED
 *    Redirect to default private route
 */
export const notLoggedRoutes = [
  {
    path: '/login',
    component: LoginPage,
    template: LoginTemplate
  },
  {
    path: '/signup',
    component: SignupPage,
    template: LoginTemplate
  },
  {
    path: '/recoverpass',
    component: RecoverPassPage,
    template: LoginTemplate
  },
  {
    path: '/changepass',
    component: ChangePassPage,
    template: LoginTemplate
  },
  {
    path: '/confirm',
    component: ConfirmUserPage,
    template: LoginTemplate
  }
]

/**
 * Both logged and not logged users can access these routes
 *
 * - Minor precedence when user IS LOGGED
 *    Is overwritten by the private route with same paths
 * - Major precedence when user IS NOT LOGGED
 *    Overwrites the same paths of the private and not logged routes
 */
export const publicRoutes = [
  {
    path: '/',
    component: LoginPage,
    template: LoginTemplate
  },
  {
    path: '/signup',
    component: SignupPage,
    template: LoginTemplate
  },
  {
    path: '/recoverpass',
    component: RecoverPassPage,
    template: LoginTemplate
  },
  {
    path: '/changepass',
    component: ChangePassPage,
    template: LoginTemplate
  }
]
