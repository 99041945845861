import { createAction } from '@reduxjs/toolkit'

export const SIGN_IN = createAction('auth:SIGN_IN')
export const SIGN_IN_SUCCESSFUL = createAction('auth:SIGN_IN_SUCCESSFUL')
export const SIGN_IN_FAILURE = createAction('auth:SIGN_IN_FAILURE')

export const RESET_SIGN_IN_ERROR = createAction('auth:RESET_SIGN_IN_ERROR')

export const REGISTER_DEVICE_REQUEST = createAction(
  'auth:REGISTER_DEVICE_REQUEST'
)
export const REGISTER_DEVICE_SUCCESS = createAction(
  'auth:REGISTER_DEVICE_SUCCESS'
)
export const REGISTER_DEVICE_FAILURE = createAction(
  'auth:REGISTER_DEVICE_FAILURE'
)

export const VERIFY_BROWSER_REQUEST = createAction(
  'auth: VERIFY_BROWSER_REQUEST'
)
export const VERIFY_BROWSER_SUCCESS = createAction(
  'auth: VERIFY_BROWSER_SUCCESS'
)
export const VERIFY_BROWSER_FAILURE = createAction(
  'auth: VERIFY_BROWSER_FAILURE'
)

export const LOGOUT = createAction('auth:LOGOUT')
export const updateCredentialsRequest = createAction(
  'auth: UPDATE_CREDENTIALS_REQUEST'
)
export const updateCredentialsSuccess = createAction(
  'auth: UPDATE_CREDENTIALS_SUCCESS'
)
export const updateCredentialsFailure = createAction(
  'auth: UPDATE_CREDENTIALS_FAILURE'
)

export const updateUserInfo = createAction('auth: UPDATE_USER_INFO')

export const updateDeviceId = createAction('auth: UPDATE_DEVICE_ID')
