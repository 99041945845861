import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import HomeIcon from '../../static/home-icon.js'
import AudiencesIcon from '../../static/audiences-icon.js'
import ReportIcon from '../../static/report-icon.js'
import ArrowLeftIcon from '../../static/arrow-left-icon.js'
import UserIcon from '../../static/user-icon.js'
import InvestmentsIcon from '../../static/investments-icon.js'
import { Sidebar, SidebarMenu, MenuItemWrapper } from './styles/Template.style'
import Profile from '../../static/user.jpg'
import Logo from '../../static/fractal-logo.png'
import LogoutIcon from '../../static/logout-icon'
import { ActionButtonWithIcon } from './NavbarTemplate'

const CloseMenuButton = styled('button')`
  background: #eaeced;
  border: 1px solid #484848;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  position: relative;
  left: 140px;
  transition: 0.5s ease;

  & :hover {
    background-color: #776aae;
    span > svg > path {
      fill: #fff;
    }
  }
`
const SideBarItem = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
  &:hover {
    span {
      font-weight: 600;
      color: #776aae;
    }
  }
`
const MenuItems = ({ isPhone, setMenu, user, history }) =>
  [
    {
      icon: <HomeIcon />,
      name: 'Home',
      path: '/',
      authorize: true,
      padding: '0px 0px 31px 0px'
    },
    {
      icon: <ReportIcon />,
      name: 'Reports',
      path: '/reports',
      authorize: true,
      padding: '0px 0px 27px 0px'
    },
    {
      icon: <ReportIcon />,
      name: 'Planning',
      path: '/plannings',
      authorize: true,
      padding: '0px 0px 27px 0px'
    },
    {
      icon: <ReportIcon />,
      name: 'Results',
      path: '/results',
      authorize: true,
      padding: '0px 0px 27px 0px'
    },
    {
      icon: <ReportIcon />,
      name: 'Legal',
      path: '/legals',
      authorize: true,
      padding: '0px 0px 27px 0px'
    },
    {
      icon: <ReportIcon />,
      name: 'Statements',
      path: '/statements',
      authorize: true,
      padding: '0px 0px 27px 0px'
    },
    {
      icon: <ReportIcon />,
      name: 'Transactions',
      path: '/transactions',
      authorize: true,
      padding: '0px 0px 27px 0px'
    },
    {
      icon: <AudiencesIcon />,
      name: 'Audiences',
      path: '/audiences',
      authorize:
        user.profiles?.includes('superuser') ||
        user.profiles?.includes('admin'),
      padding: '0px 0px 27px 0px'
    },
    {
      icon: <UserIcon />,
      name: 'Users',
      path: '/users',
      authorize:
        user.profiles?.includes('superuser') ||
        user.profiles?.includes('admin'),
      padding: '0px 0px 27px 0px'
    },
    {
      icon: <InvestmentsIcon />,
      name: 'Investments',
      path: '/investments',
      authorize:
        user.profiles?.includes('superuser') ||
        user.profiles?.includes('admin'),
      padding: '0px 0px 33px 0px'
    }
  ].map(item => {
    function extractUrl (url) {
      const firstSlash = url.indexOf('/')

      const secondSlash = url.indexOf('/', firstSlash + 1)

      let extractedUrl
      if (secondSlash !== -1) {
        extractedUrl = url.substring(0, secondSlash)
      } else {
        extractedUrl = url
      }

      return extractedUrl
    }

    const fullUrl = location.pathname
    const path = extractUrl(fullUrl)
    const selected = path === item.path

    return (
      item.authorize && (
        <MenuItemWrapper
          onClick={() => {
            item.path && history.push(item.path)
            isPhone && setMenu(false)
          }}
          key={item.name}
          selected={location.pathname === item.path}
          style={{ padding: `${item.padding}` }}
        >
          <SideBarItem>
            <div
              style={{
                background: `${selected ? '#776AAE' : 'transparent'}`,
                display: 'flex',
                borderRadius: '50%',
                padding: '5px 5px'
              }}
            >
              <span style={{ display: 'flex' }}>{item.icon}</span>
            </div>
            <span>{item.name}</span>
          </SideBarItem>
        </MenuItemWrapper>
      )
    )
  })

const SidebarComponent = ({ isPhone, menu, setMenu, ...rest }) => {
  const { user } = rest
  const [toggleHideSideBar, setToggleHideSidebar] = useState(false)
  const handleToggleHideSidebar = () => {
    if (toggleHideSideBar) {
      setToggleHideSidebar(false)
    } else {
      setToggleHideSidebar(true)
    }
  }
  return (
    <Sidebar
      style={{
        display: `${toggleHideSideBar ? 'none' : 'flex'}`
      }}
      isPhone={isPhone}
    >
      <SidebarMenu>
        <div>
          {isPhone && (
            <CloseMenuButton onClick={handleToggleHideSidebar}>
              <span
                style={{
                  position: 'relative',
                  top: '1px',
                  right: '1px',
                  padding: '3px 3px',
                  margin: 0
                }}
              >
                <ArrowLeftIcon />
              </span>
            </CloseMenuButton>
          )}
          <div>
            <img style={{ width: '107px' }} src={Logo} alt='Logo Fractal' />
          </div>
          <div
            style={{
              margin: '3em auto'
            }}
          >
            <img
              src={Profile}
              style={{
                display: 'block',
                margin: 'auto',
                width: '6em',
                borderRadius: '50%'
              }}
              alt='profile'
            />
            <span style={{ fontWeight: 'bolder', color: 'rgb(94, 95, 96)' }}>
              {' '}
              {user.name}{' '}
            </span>
          </div>
          <MenuItems {...rest} />
        </div>
        <ActionButtonWithIcon
          style={{
            marginTop: '45px',
            paddingBottom: '20px',
            paddingLeft: '5px',
            gap: '8px',
            color: '#776AAE',
            fontSize: '16px',
            lineHeight: '120%'
          }}
        >
          <LogoutIcon />
          Logout
        </ActionButtonWithIcon>
      </SidebarMenu>
    </Sidebar>
  )
}

const SidebarWrapper = ({ isPhone, menu, setMenu, user, ...rest }) => {
  const history = useHistory()
  const location = useLocation()
  if (isPhone) {
    return (
      menu && (
        <SidebarComponent
          menu={menu}
          setMenu={setMenu}
          isPhone={isPhone}
          history={history}
          location={location}
          user={user}
        />
      )
    )
  }
  return (
    <SidebarComponent
      menu={menu}
      setMenu={setMenu}
      isPhone={isPhone}
      history={history}
      location={location}
      user={user}
    />
  )
}

export default SidebarWrapper
