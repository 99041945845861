import React from 'react'

const arrowLeftIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='8'
      height='12'
      viewBox='0 0 7 12'
      fill='none'
    >
      <path
        d='M6.85337 11.8537C6.65846 12.0493 6.34188 12.0499 6.14627 11.855L0.661784 6.39C0.44607 6.17505 0.446071 5.82574 0.661784 5.6108L6.14627 0.145818C6.34188 -0.0490958 6.65846 -0.0485312 6.85337 0.147078C7.04829 0.342688 7.04772 0.65927 6.85212 0.854184L1.68753 6.0004L6.85211 11.1466C7.04772 11.3415 7.04829 11.6581 6.85337 11.8537Z'
        fill='#484848'
      />
    </svg>
  )
}

export default arrowLeftIcon
