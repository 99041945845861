import React from 'react'
import { Select } from 'antd'
import { isEmpty } from 'lodash'
import styled from '@emotion/styled'
import FormInput from '../input/FormInput'
import SelectFormInput from '../input/SelectFormInput'

const StyledSelect = styled(Select)`
  color: #bfbebf;
  margin: 1% 0;
  width: 100%;
  & .ant-select-selector {
    border: none !important;
    border-bottom: 1px solid rgb(118, 117, 118, 0.1) !important;
  }
`

const required = value => (!isEmpty(value) ? undefined : 'Required field!')
const onlyNumbers = value => {
  const formattedValue = value.replace(/[&/\\._-]/g, '')
  return /^\d+$/.test(formattedValue)
    ? undefined
    : 'This field only accept numbers'
}
const minCpfLength = value =>
  value.replace(/[&/\\._-]/g, '').length === 11 ? undefined : 'Invalid CPF'

const DocumentOptions = [
  {
    value: 'cpf',
    label: 'CPF'
  },
  {
    value: 'passport',
    label: 'Passport'
  }
]
const SelectDocument = ({
  input: { onChange },
  setValue,
  value,
  styledSelect,
  customSelect,
  defaultValue,
  style,
  ...rest
}) => {
  const props = {
    value: value,
    onChange: value => {
      onChange(value)
    },
    defaultValue: defaultValue
  }
  return styledSelect ? (
    <StyledSelect {...props}>
      {DocumentOptions.map(({ value, label }) => (
        <Select.Option
          style={{ color: 'rgb(191, 190, 191)' }}
          key={value}
          value={value}
        >
          {' '}
          {label}{' '}
        </Select.Option>
      ))}
    </StyledSelect>
  ) : customSelect ? (
    <Select {...props} style={style}>
      {DocumentOptions.map(({ value, label }) => (
        <Select.Option
          style={{ color: 'rgb(191, 190, 191)' }}
          key={value}
          value={value}
        >
          {' '}
          {label}{' '}
        </Select.Option>
      ))}
    </Select>
  ) : (
    <SelectFormInput {...rest}>
      <Select style={{ width: '100%', margin: '1% 0' }} {...props}>
        {DocumentOptions.map(({ value, label }) => (
          <Select.Option
            style={{ color: 'rgb(191, 190, 191)' }}
            key={value}
            value={value}
          >
            {' '}
            {label}{' '}
          </Select.Option>
        ))}
      </Select>
    </SelectFormInput>
  )
}

const AlternateInput = ({
  Field,
  name = '',
  styledInput = false,
  styledSelect = false,
  customSelect = false,
  documentSelectStyle = {},
  borderless,
  documentTypeValue = 'Document',
  ...rest
}) => {
  const validateDocumentNumber =
    documentTypeValue === 'cpf' ? [required, onlyNumbers, minCpfLength] : []
  const maskProps =
    documentTypeValue === 'cpf' ? { mask: documentTypeValue } : {}
  const placeholderMsg =
    documentTypeValue === 'Document'
      ? 'Please, first select the document type'
      : `Type your ${documentTypeValue}`
  return (
    <>
      <Field
        name={`${name && name + '.'}document_number`}
        component={FormInput}
        addonBefore={
          <Field
            name={`${name && name + '.'}document_type`}
            component={SelectDocument}
            value={documentTypeValue}
            defaultValue={documentTypeValue}
            styledSelect={styledSelect}
            customSelect={customSelect}
            style={documentSelectStyle}
          />
        }
        placeholder={placeholderMsg}
        disabled={documentTypeValue === 'Document'}
        styledInput={styledInput}
        validate={validateDocumentNumber}
        borderless={borderless}
        {...maskProps}
      />
    </>
  )
}

export default AlternateInput
