import { createReducer } from '@reduxjs/toolkit'
import {
  SIGN_IN,
  SIGN_IN_SUCCESSFUL,
  SIGN_IN_FAILURE,
  LOGOUT,
  VERIFY_BROWSER_REQUEST,
  VERIFY_BROWSER_SUCCESS,
  VERIFY_BROWSER_FAILURE,
  RESET_SIGN_IN_ERROR,
  updateCredentialsSuccess,
  updateUserInfo,
  updateDeviceId
} from './actions'

const initialState = {
  user: null,
  credentials: {},
  isLogged: false,
  isDeviceAuthenticated: false,
  deviceId: '',
  isFetching: false,
  isError: false,
  errorMsg: []
}

const auth = createReducer(initialState, {
  [SIGN_IN]: (state, action) => ({
    ...state,
    isFetching: true,
    isLogged: false,
    isError: false
  }),
  [SIGN_IN_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isLogged: true,
    isError: false,
    user: {
      ...action.payload.user
    },
    credentials: { ...action.payload.headers }
  }),
  [SIGN_IN_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isLogged: false,
    user: {},
    isError: true,
    errorMsg: action.payload
  }),
  [RESET_SIGN_IN_ERROR]: state => ({
    ...state,
    isError: false,
    errorMsg: []
  }),
  [LOGOUT]: (state, action) => ({
    ...initialState
  }),
  [VERIFY_BROWSER_REQUEST]: (state, action) => ({
    ...state,
    isFetching: true
  }),
  [VERIFY_BROWSER_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    deviceId: action.payload.id,
    isDeviceAuthenticated: true
  }),
  [VERIFY_BROWSER_FAILURE]: state => ({
    ...state,
    isFetching: false,
    isDeviceAuthenticated: false
  }),
  [updateCredentialsSuccess]: (state, action) => ({
    ...state,
    credentials: action.payload.headers
  }),
  [updateUserInfo]: (state, action) => ({
    ...state,
    user: {
      ...action.payload
    }
  }),
  [updateDeviceId]: (state, action) => ({
    ...state,
    deviceId: action.payload
  })
})

export default auth
