/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import btoa from 'btoa'
import { isValid, change, clearAsyncError, getFormValues } from 'redux-form'

import SignupForm from '../../components/form/SignupForm'
import { registerUserRequest } from '../../store/users/index'
import http from '../../utils/http'

const SignUpErrorMsgs = {
  email: {
    blank: "Email can't be blank",
    taken: 'Email is being used already'
  },
  document_number: {
    blank: "Document number can't be blank",
    taken: 'Document number is being used already'
  },
  document_type: {
    blank: "Document type can't be blank"
  },
  password: {
    short: 'Password must have at least 6 characters'
  }
}

const mapStateToProps = state => ({
  isFormValid: isValid('signUpForm')(state),
  loading: state.users.isFetching,
  formValues: getFormValues('signUpForm')(state)
})

const mapDispatchToProps = {
  registerUserRequest,
  change,
  clearAsyncError
}

const registerUser = payload => {
  const registerEndpoint = '/auth/register'
  const params = {
    data: {
      type: 'registrations',
      attributes: { ...payload }
    }
  }
  return http
    .post(registerEndpoint, params)
    .then(res => res)
    .catch(err => err.response)
}

const RegisterUserContainer = props => {
  const [status, setStatus] = React.useState('waiting')
  const [errors, setErrors] = React.useState([])
  const { registerUserRequest, ...rest } = props
  const onSubmit = async data => {
    const { phone_code, ...dataRest } = data
    const formattedData = {
      ...dataRest,
      password: btoa(dataRest['password']),
      phone: `${phone_code} ${dataRest['phone']}`
    }
    setStatus('loading')
    const response = await registerUser(formattedData)
    if (response.status === 201) {
      setStatus('confirmed')
    } else if (response.status === 422) {
      setStatus('waiting')
      const { data: { errors } } = response
      const errorMessages = errors.reduce((ac, item) => {
        let newError = ac
        Object.keys(SignUpErrorMsgs).forEach(key => {
          if (item.detail.includes(key)) {
            Object.keys(SignUpErrorMsgs[key]).forEach(msg => {
              if (item.title.includes(msg)) {
                newError =
                  !errors.includes(SignUpErrorMsgs[key][msg]) &&
                  newError.concat(SignUpErrorMsgs[key][msg])
              }
            })
          }
        })
        return newError
      }, [])
      setErrors(errorMessages)
    }
  }
  return (
    <SignupForm errors={errors} status={status} onSubmit={onSubmit} {...rest} />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(
  RegisterUserContainer
)
