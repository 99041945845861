import { put, call, takeLatest, all, select } from 'redux-saga/effects'
import { updateCredentialsRequest } from '../auth/actions'

import * as actions from './index'
import * as services from './services'

export function * fetchInvestments ({ payload }) {
  try {
    let filters = {}
    if (payload) {
      filters = payload.filters
    }
    const pagination = yield select(state => state.investments.pagination)
    const response = yield call(services.fetchInvestments, {
      filters,
      pagination
    })
    if (response.status === 200) {
      const formattedData = response.data.data.map(item => {
        return {
          ...item.attributes,
          id: item.id
        }
      })
      yield put(actions.fetchInvestmentsSuccess(formattedData))
      yield put(updateCredentialsRequest(response.headers))
    }
  } catch (e) {
    console.error(e)
    yield put(actions.fetchInvestmentsError(e))
  }
}

export function * createInvestment ({ payload }) {
  try {
    const { attributes, history } = payload
    const params = {
      data: {
        type: 'investment_entities',
        attributes: { ...attributes }
      }
    }
    const response = yield call(services.createInvestment, params)
    if (response.status === 201) {
      yield put(actions.createInvestmentSuccess())
      yield put(updateCredentialsRequest(response.headers))
      history.push('/investments')
    }
  } catch (e) {
    yield put(actions.createInvestmentError(e))
  }
}

export function * updateInvestment ({ payload }) {
  try {
    const { attributes, id, history } = payload
    const params = {
      data: {
        id,
        type: 'investment_entities',
        attributes: { ...attributes }
      }
    }
    const response = yield call(services.updateInvestment, { params, id })
    if (response.status === 200) {
      yield put(actions.createInvestmentSuccess())
      yield put(updateCredentialsRequest(response.headers))
      history.push('/investments')
    }
  } catch (e) {
    yield put(actions.createInvestmentError(e))
  }
}

export function * deleteInvestment ({ payload }) {
  try {
    const response = yield call(services.deleteInvestment, { id: payload })
    yield put(updateCredentialsRequest(response.headers))
    yield put(actions.fetchInvestmentsRequest())
  } catch (e) {
    console.error(e)
  }
}

export function * watchFetchInvestments () {
  yield takeLatest(actions.fetchInvestmentsRequest, fetchInvestments)
}

export function * watchCreateInvestment () {
  yield takeLatest(actions.createInvestmentRequest, createInvestment)
}

export function * watchUpdateInvestment () {
  yield takeLatest(actions.updateInvestmentRequest, updateInvestment)
}

export function * watchDeleteInvestment () {
  yield takeLatest(actions.deleteInvestmentRequest, deleteInvestment)
}

export function * InvestmentsSagas () {
  yield all([
    watchFetchInvestments(),
    watchCreateInvestment(),
    watchUpdateInvestment(),
    watchDeleteInvestment()
  ])
}
