// import btoa from 'btoa'

import http from '../../utils/http'

// Endpoints
const signInEndpoint = '/auth/sign_in'

export const signIn = params => {
  return http.post(signInEndpoint, params).then(res => res)
  // .catch(err => {
  //   const getErrorMessage = err.response.data.errors.map(item => item.detail)
  //   return getErrorMessage
  // })
}
