import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

const slideUp = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0)
  }
`

export const Container = styled('div')`
  animation: ${slideUp} 0.5s ease-out;
  background: white;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
  padding: ${({ isPhone, ...rest }) => (isPhone ? '2em 1em' : '5em 3em')};
  text-align: initial;
`
export const InnerContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
`
export const ContainerTitle = styled('span')`
  font-size: 2em;
  color: #484848;
  margin-bottom: 16px;
`

export const ViewTitle = styled('span')`
  color: #484848;
  font-size: 2em;
  font-weight: bold;
`
