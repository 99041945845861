import React from 'react'
import { Select } from 'antd'
import { Field } from 'redux-form'

import BrazilFlag from '../../assets/brazil.svg'
import UsFlag from '../../assets/united-states.svg'

import {
  required,
  minBRPhoneNumberLength,
  minUSPhoneNumberLength
} from '../form/Validation/validation'
import FormInput from './FormInput'

const SelectCountryCode = ({ input }) => {
  const { onChange, ...inputRest } = input
  if (inputRest.value === '') {
    input.value = '+55'
  }
  return (
    <Select
      {...inputRest}
      onBlur={() => input.onBlur(input.value)}
      onChange={value => {
        onChange(value)
      }}
      style={{ border: 'none', width: '8em', color: '#bfbebf' }}
    >
      <Select.Option value='+55'>
        <img
          style={{ width: '50%', padding: '0.5em' }}
          src={BrazilFlag}
          alt='brazil'
        />
        <span style={{ color: '#bfbebf' }}> +55 </span>
      </Select.Option>
      <Select.Option value='+1'>
        <img style={{ width: '50%', padding: '0.5em' }} src={UsFlag} alt='us' />
        <span style={{ color: '#bfbebf' }}> +1 </span>
      </Select.Option>
    </Select>
  )
}

const PhoneInput = props => {
  const { namePreffix = '', ...restProps } = props
  const phoneCodeField = React.useRef(null)
  const phoneFieldValue =
    phoneCodeField && phoneCodeField.current && phoneCodeField.current.value
  return (
    <Field
      component={FormInput}
      {...restProps}
      addonBefore={
        <Field
          ref={phoneCodeField}
          name={`${namePreffix}phone_code`}
          component={SelectCountryCode}
        />
      }
      required
      validate={[
        required,
        phoneFieldValue === '+55'
          ? minBRPhoneNumberLength
          : minUSPhoneNumberLength
      ]}
      mask={
        phoneFieldValue === '+55'
          ? 'brPhone'
          : phoneFieldValue === '+1' ? 'usPhone' : ''
      }
    />
  )
}

export default PhoneInput
