import { createAction, createReducer } from '@reduxjs/toolkit'

export const fetchUsersRequest = createAction('users/FETCH_USERS_REQUEST')
export const fetchUsersSuccess = createAction('users/FETCH_USERS_SUCCESS')
export const fetchUsersError = createAction('users/FETCH_USERS_ERROR')

export const registerUserRequest = createAction('users/REGISTER_USER_REQUEST')
export const registerUserSuccess = createAction('users/REGISTER_USER_SUCCESS')
export const registerUserError = createAction('users/REGISTER_USER_ERROR')

export const updateUserRequest = createAction('users/UPDATE_USER_REQUEST')
export const updateUserSuccess = createAction('users/UPDATE_USER_SUCCESS')
export const updateUserError = createAction('users/UPDATE_USER_ERROR')

export const createUserRequest = createAction('users/CREATE_USER_REQUEST')
export const createUserSuccess = createAction('users/CREATE_USER_SUCCESS')
export const createUserError = createAction('users/CREATE_USER_ERROR')

export const updateUsersPagination = createAction(
  'users/UPDATE_USERS_PAGINATION'
)

export const resetUsersState = createAction('users/RESET_USERS_STATE')

const initialState = {
  items: [],
  isFetching: false,
  pagination: {
    current: 1,
    defaultPageCurrent: 1,
    defaultPageSize: 20,
    pageSize: 20,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true
  },
  error: null
}

export const UsersReducer = createReducer(initialState, {
  [fetchUsersRequest]: (state, action) => ({
    ...state,
    isFetching: true,
    error: null
  }),
  [fetchUsersSuccess]: (state, action) => ({
    ...state,
    isFetching: false,
    items: action.payload.users
  }),
  [fetchUsersError]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload
  }),
  [updateUserRequest]: state => ({
    ...state,
    isFetching: true
  }),
  [updateUserSuccess]: state => ({
    ...state,
    isFetching: false
  }),
  [updateUserError]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload
  }),
  [createUserRequest]: state => ({
    ...state,
    isFetching: true
  }),
  [createUserSuccess]: state => ({
    ...state,
    isFetching: false
  }),
  [createUserError]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload
  }),
  [updateUsersPagination]: (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      ...action.payload
    }
  }),
  [resetUsersState]: state => ({
    ...initialState
  })
})
