import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import MailIcon from '../../static/mail-icon.js'
import LockIcon from '../../static/lock-icon.js'
import {
  FormWrapper,
  SubmitButton,
  OptionsWrapper,
  SpanTitle
} from './styles/LoginForm.style'
import CustomRadio from '../input/CustomRadioInput'
import InputComponent from '../input/StyledInput'
import { AlternativeSpinner as Loading } from '../loading/Loading'
import styled from '@emotion/styled'

const Container = styled('div')`
  .ant-input-affix-wrapper > input {
    font-size: 16px !important;
    padding-left: 8pximport LockIcon from './../../static/lock-icon';
    background: transparent;
  }
  div > span > input::placeholder {
    font-size: 16px;
    padding-left: 8px;
  }
`

export const LoginForm = ({
  handleSubmit,
  onSubmit,
  isError,
  isFetching,
  errorMsg
}) => {
  const isPhone = useMediaQuery({ query: '(max-width: 767px)' })
  return (
    <FormWrapper isPhone={isPhone}>
      {isFetching ? (
        <>
          <SpanTitle> Logging in... </SpanTitle>
          <Loading />
        </>
      ) : (
        <>
          <SpanTitle> Log in </SpanTitle>
          <Form onFinish={handleSubmit(onSubmit)}>
            <Container>
              <Field
                name='email'
                component={InputComponent}
                type='text'
                prefix={<MailIcon />}
                placeholder='Email address'
                isPhone={isPhone}
              />
            </Container>
            <Container>
              <Field
                name='password'
                component={InputComponent}
                type='password'
                prefix={<LockIcon />}
                suffix={
                  <Link
                    style={{
                      color: 'rgb(118, 117, 118)',
                      textDecoration: 'none'
                    }}
                    to='/recoverpass'
                  >
                    Forgot?
                  </Link>
                }
                placeholder='Password'
                isPhone={isPhone}
              />
            </Container>
            {isError &&
              errorMsg.map((item, index) => {
                return (
                  <span key={index} style={{ color: 'red' }}>
                    {item}
                  </span>
                )
              })
            // <span style={{ color: 'red' }}>
            /* Bad account credentials, insert your email/password correctly.{' '} */
            // </span>
            }
            <SubmitButton type='submit'>Login to your account</SubmitButton>
          </Form>
          <OptionsWrapper>
            <CustomRadio> Remember me </CustomRadio>
            <span>
              {' '}
              New here? <Link to='/signup'> Sign Up! </Link>{' '}
            </span>
          </OptionsWrapper>
        </>
      )}
    </FormWrapper>
  )
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  isFetching: PropTypes.bool,
  errorMsg: PropTypes.array
}

export default reduxForm({
  form: 'login'
})(LoginForm)
