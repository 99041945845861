import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

const slideUp = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0)
  }
`

export const SpanTitle = styled('span')`
  color: #484848;
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 16px;
`

export const FormWrapper = styled('div')`
  animation: ${slideUp} 0.5s ease-out;
  background: white;
  border-radius: 3em;
  box-shadow: 1px 0px 30px 1px rgba(0, 0, 0, 0.29);
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
  padding: ${({ isPhone, ...rest }) => (isPhone ? '2em 1em' : '4em 4em')};
  text-align: initial;
  align-items: center;
`
export const InputWrapper = styled('div')`
  align-items: center;
  background: white;
  border-bottom: 1px solid rgb(118, 117, 118, 0.1);
  color: #bfbebf;
  display: flex;
  height: 2em;
  margin: 1em 0;
  outline: none;
  padding: ${({ isPhone, ...rest }) => (isPhone ? '0.5em 0' : '1.5em 0')};
  width: 100%;
  & input:focus {
    outline: none;
  }
  & .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
    outline: none;
  }
  & .ant-input-affix-wrapper {
    padding: 4px;
  }
  & .ant-input-affix-wrapper > input {
    font-size: 1.5em;
  }
`
export const SubmitButton = styled('button')`
  align-items: center;
  background: #776aae;
  border: none;
  border-radius: 42px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 1.5em;
  justify-content: center;
  margin-top: 1em;
  min-width: 10em;
  padding: 1.5em 0.2em;
  transition: 0.5s ease;
  width: 100%;
  max-width: 400px;
  & :hover {
    background: #776aae9e;
    color: #484848;
  }
  & :disabled {
    background: #767576;
    color: white;
    cursor: not-allowed;
  }
`

export const OptionsWrapper = styled('div')`
  color: #bfbebf;
  display: flex;
  justify-content: space-between;
  padding: 2em 0;
  & a {
    color: #bfbebf;
    font-weight: bold;
    text-decoration: none;
    & :hover {
      text-decoration: underline;
    }
  }
`
