import React from 'react'

const CheckmarkGreenIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='14'
      viewBox='0 0 15 14'
      fill='none'
    >
      <ellipse cx='7.32075' cy='7' rx='7.32075' ry='7' fill='none' />
      <path
        d='M11.7264 3.68322C11.9598 3.91763 11.9483 4.28677 11.7006 4.50771L5.18448 10.32C4.90816 10.5665 4.4744 10.5589 4.2079 10.3029L2.00542 8.18737C1.76653 7.95791 1.76942 7.58862 2.01187 7.36253C2.25432 7.13645 2.64452 7.13918 2.88341 7.36864L4.71924 9.13202L10.8552 3.65881C11.1029 3.43787 11.4929 3.4488 11.7264 3.68322Z'
        fill='white'
      />
    </svg>
  )
}

export default CheckmarkGreenIcon
