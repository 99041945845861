import styled from '@emotion/styled'

export const TableButtonsContainer = styled('div')`
  align-items: center;
  display: flex;
  position: relative;
  @media (max-width: 767px) {
    flex-direction: column !important;
    align-items: flex-start;
    gap: 32px;
    margin-bottom: 10px;
  }
`
