import React from 'react'

const DocumentIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
    >
      <path
        d='M13.8149 27.0213H23.8796C24.285 27.0213 24.6132 26.6931 24.6132 26.2877V5.73361C24.6132 5.3282 24.285 5 23.8796 5H10.1211C8.40288 5 7 6.40288 7 8.12108V23.9067C7 25.6056 8.36427 26.9891 10.0503 27.0277H13.8213L13.8149 27.0213Z'
        stroke='#484848'
        strokeWidth='0.75'
        strokeMiterlimit='10'
      />
      <path
        d='M7.15454 24.7111C7.15454 23.5978 7.98468 22.6904 9.00788 22.6904H24.6197'
        stroke='#484848'
        strokeWidth='0.75'
        strokeMiterlimit='10'
      />
      <path
        d='M20.7583 9.24707H11.0154C10.628 9.24707 10.314 9.56111 10.314 9.94851V18.6875C10.314 19.0749 10.628 19.389 11.0154 19.389H20.7583C21.1457 19.389 21.4598 19.0749 21.4598 18.6875V9.94851C21.4598 9.56111 21.1457 9.24707 20.7583 9.24707Z'
        stroke='#484848'
        strokeWidth='0.75'
        strokeMiterlimit='10'
      />
      <path
        d='M18.062 17.89H13.6989C13.345 17.89 13.0296 17.7741 12.8301 17.5682C12.6307 17.3623 12.5792 17.0984 12.7014 16.8603C13.1905 15.9143 14.4647 15.2837 15.8804 15.2837C17.2962 15.2837 18.609 15.9336 19.0787 16.8989C19.1881 17.1242 19.1367 17.3687 18.9436 17.5682C18.7441 17.7677 18.4159 17.89 18.0684 17.89H18.062Z'
        stroke='#484848'
        strokeWidth='0.75'
        strokeMiterlimit='10'
      />
      <path
        d='M15.8869 14.4404C16.907 14.4404 17.7339 13.6135 17.7339 12.5935C17.7339 11.5735 16.907 10.7466 15.8869 10.7466C14.8669 10.7466 14.04 11.5735 14.04 12.5935C14.04 13.6135 14.8669 14.4404 15.8869 14.4404Z'
        stroke='#484848'
        strokeWidth='0.75'
        strokeMiterlimit='10'
      />
    </svg>
  )
}

export default DocumentIcon
