import React from 'react'
import { compose } from 'redux'
import { reduxForm, Field } from 'redux-form'
import { Button } from 'antd'
import { Link } from 'react-router-dom'

import {
  FormViewWrapper,
  FormWrapper,
  FormButtonsArea,
  FormTitle
} from './styles/Form.style'
import FormInput from '../input/FormInput'
import { required } from './Validation/validation'
import styled from '@emotion/styled'
import ReturnIcon from '../../static/return-icon'
import { LineDivider } from '../view/MessageView'

const Container = styled('div')`
  .ant-select-selector {
    background-color: transparent;
    border: none !important;
    span {
      color: #484848;
      font-family: 'Montserrat';
    }
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #776aae9e !important;
  }
  .ant-select-focused:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
    box-shadow: none;
  }
  .ant-select {
    .ant-select-arrow {
      > span > svg > path {
        fill: #484848;
      }
    }
  }
  .ant-collapse-content-box div > div {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: none;
  }
  .ant-pagination {
    margin-top: 0px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background: transparent;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    background: transparent;
    border: none;
  }
  .ant-checkbox {
    &:hover {
      border: 1px solid #484848;
    }
  }
  a:hover {
    font-weight: 600;
    svg > circle {
      stroke: #eaeced;
      fill: #776aae;
    }
    svg > path {
      stroke: #fff;
    }
  }
`
const InvestmentsForm = props => {
  const { onSubmit, handleSubmit, submit, isFormValid, isFetching } = props
  return (
    <FormViewWrapper>
      <Container style={{ marginBottom: '16px' }}>
        <Link
          style={{
            display: 'flex',
            color: '#484848',
            alignItems: 'center',
            gap: '4px',
            width: 'fit-content'
          }}
          to='/investments'
        >
          {' '}
          <ReturnIcon />
          Go back to investments list{' '}
        </Link>
      </Container>
      <FormTitle style={{ color: '#484848' }}>New Investment entity</FormTitle>
      <LineDivider />
      <FormWrapper onFinish={handleSubmit(onSubmit)}>
        <Field
          name='name'
          component={FormInput}
          placeholder='Name'
          validate={[required]}
        />
      </FormWrapper>
      <LineDivider />
      <FormButtonsArea>
        <Button
          disabled={!isFormValid || isFetching}
          loading={isFetching}
          onClick={() => submit('investmentsForm')}
          type='submit'
        >
          Save investment entity
        </Button>
      </FormButtonsArea>
    </FormViewWrapper>
  )
}

const enhance = compose(reduxForm({ form: 'investmentsForm' }))

export default enhance(InvestmentsForm)
