import React from 'react'

const CancelIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='8' cy='8' r='8' fill='#F69E9E' />
      <g clipPath='url(#clip0_10704_32719)'>
        <path
          d='M4.70107 3.75736C4.44073 3.49701 4.01862 3.49701 3.75827 3.75736C3.49792 4.01771 3.49792 4.43982 3.75827 4.70017L7.0581 8L3.75827 11.2998C3.49792 11.5602 3.49792 11.9823 3.75827 12.2426C4.01862 12.503 4.44073 12.503 4.70107 12.2426L8.00091 8.94281L11.3007 12.2426C11.5611 12.503 11.9832 12.503 12.2435 12.2426C12.5039 11.9823 12.5039 11.5602 12.2435 11.2998L8.94372 8L12.2435 4.70017C12.5039 4.43982 12.5039 4.01771 12.2435 3.75736C11.9832 3.49701 11.5611 3.49701 11.3007 3.75736L8.00091 7.05719L4.70107 3.75736Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_10704_32719'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CancelIcon
