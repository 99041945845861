import http from '../../utils/http'

const investmentsEndpoint = '/investment_entities'

export const fetchInvestments = ({
  filters,
  pagination: { current, pageSize }
}) => {
  let endpoint = `${investmentsEndpoint}?page[number]=${current}&page[size]=${pageSize}`
  if (filters.name) {
    endpoint = `${investmentsEndpoint}?q[name_cont]=${
      filters.name
    }&page[number]=${current}&page[size]=${pageSize}`
  }
  return http
    .get(endpoint)
    .then(res => res)
    .catch(err => err.response)
}

export const createInvestment = params => {
  return http
    .post(investmentsEndpoint, params)
    .then(res => res)
    .catch(err => err.response)
}

export const updateInvestment = ({ params, id }) => {
  return http
    .put(`${investmentsEndpoint}/${id}`, params)
    .then(res => res)
    .catch(err => err.response)
}

export const deleteInvestment = ({ id }) => {
  return http({
    url: `${investmentsEndpoint}/${id}`,
    headers: {
      'Content-Type': 'application/vnd.api+json'
    },
    method: 'DELETE',
    data: {}
  })
}
