/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { isValid, getFormValues } from 'redux-form'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'

import { updateUserRequest } from '../../store/users'
import EditProfileForm from '../../components/form/EditProfileForm'

const mapDispatchToProps = {
  updateUserRequest
}

const mapStateToProps = state => ({
  user: state.auth.user,
  formValues: getFormValues('editProfileForm')(state),
  isFormValid: isValid('editProfileForm')(state)
})

const SettingsContainer = props => {
  const { user, updateUserRequest } = props
  const [initialValues, setInitialValues] = React.useState({})
  const [renderReady, setRenderReady] = React.useState(false)
  const history = useHistory()

  React.useState(() => {
    const { name, document_type, document_number, phone, email, id } = user
    let phoneAttrs = { phone: phone, phone_code: '+55' }
    if (phone && phone?.includes('+55')) {
      phoneAttrs.phone = phone?.replace('+55', '').trim()
      phoneAttrs.phone_code = phone.substr(0, 3)
    } else if (phone && phone?.includes('+1')) {
      phoneAttrs.phone = phone?.replace('+1', '').trim()
      phoneAttrs.phone_code = phone?.substr(0, 2)
    }
    setInitialValues({
      name,
      document_type,
      document_number,
      email,
      id,
      ...phoneAttrs
    })
  }, [])
  React.useEffect(
    () => {
      if (!isEmpty(initialValues)) {
        setRenderReady(true)
      }
    },
    [initialValues]
  )
  const onSubmit = data => {
    const { phone_code, ...attrs } = data
    const formattedData = {
      ...attrs,
      phone: `${phone_code} ${attrs['phone']}`
    }
    updateUserRequest({ attributes: formattedData, history, editProfile: true })
  }
  if (renderReady) {
    return (
      <EditProfileForm
        {...props}
        onSubmit={onSubmit}
        initialValues={initialValues}
      />
    )
  } else return null
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer)
