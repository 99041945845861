import React from 'react'
import { connect } from 'react-redux'
import { submit, isValid } from 'redux-form'
import { useHistory, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'

import InvestmentsForm from '../../components/form/InvestmentsForm'
import { updateInvestmentRequest } from '../../store/investments'
import { updateCredentialsRequest } from '../../store/auth/actions'
import http from '../../utils/http'

const mapDispatchToProps = {
  updateInvestmentRequest,
  submit
}

const mapStateToProps = state => ({
  isFormValid: isValid('investmentsForm')(state),
  isFetching: state.investments.isFetching,
  userProfiles: state.auth.user.profiles
})

const fetchInvestmentData = id => {
  const investmentsEndpoint = '/investment_entities'
  return http
    .get(`${investmentsEndpoint}/${id}`)
    .then(res => res)
    .catch(err => err.response)
}

const InvestmentsFormContainer = props => {
  const { id } = useParams()
  const history = useHistory()
  const [initialValues, setInitialValues] = React.useState({})
  const [renderReady, setRenderReady] = React.useState(false)
  const { updateInvestmentRequest, userProfiles, ...rest } = props
  React.useEffect(async () => {
    if (
      userProfiles?.includes('superuser') ||
      userProfiles?.includes('admin')
    ) {
      const response = await fetchInvestmentData(id)
      if (response.status === 200) {
        const { data: { data: { attributes, id } } } = response
        setInitialValues({
          ...attributes,
          id
        })
        updateCredentialsRequest(response.headers)
      } else {
        history.push('/')
      }
    } else {
      history.push('/')
    }
  }, [])
  React.useEffect(
    () => {
      if (
        !(
          userProfiles?.includes('superuser') || userProfiles?.includes('admin')
        )
      ) {
        history.push('/')
      } else if (!isEmpty(initialValues)) {
        setRenderReady(true)
      }
    },
    [initialValues]
  )

  const onSubmit = data => {
    const { id, ...attrs } = data
    updateInvestmentRequest({ attributes: attrs, id, history })
  }
  if (renderReady) {
    return (
      <InvestmentsForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        {...rest}
      />
    )
  } else return null
}

export default connect(mapStateToProps, mapDispatchToProps)(
  InvestmentsFormContainer
)
