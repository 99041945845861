import styled from '@emotion/styled'

export const H2 = styled('h2')`
  color: #484848;
  font-family: 'Montserrat';
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin: 0;
  @media (max-width: 767px) {
    margin-bottom: 10px;
    margin-top: 16px;
  }
`
