import styled from '@emotion/styled'
import { Breadcrumb } from 'antd'

export const Container = styled('div')`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  width: 100vw;
`

export const Navbar = styled('nav')`
  align-items: center;
  display: flex;
  padding: 1em;
  position: relative;
  width: 100%;
  .ant-dropdown-trigger {
    top: 5px;
  }
  .anticon-menu {
    margin-right: 30px;
    svg {
      fill: #776aae;
    }
  }
  @media (min-width: 653px) {
    .anticon-menu {
      display: none;
    }
  }
`

export const NavbarControls = styled('div')`
  display: flex;
  gap: 40px;
  @media (max-width: 767px) {
    gap: 0px;
  }
  width: 100%;
  justify-content: flex-end;
  margin-right: 20px;
`

export const IconContainer = styled('div')`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-evenly;

  @media (max-width: 767px) {
    margin-left: 35px;
  }
`

export const LogoutButton = styled('div')`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 35%;
`

export const ChildrenContainer = styled('div')`
  background: #eaeced;
  flex-grow: 1;
  padding: 1em 2em;
  margin-top: 42px;
  margin-right: 32px;
  @media (max-width: 767px) {
    margin-right: 0px;
    margin-top: 0px;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const Sidebar = styled('div')`
  background: #eaeced;
  border: 1px solid #484848;
  border-radius: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
  height: ${({ isPhone, ...rest }) => (isPhone ? '100%' : 'auto')};
  margin: ${({ isPhone, ...rest }) =>
    isPhone ? '22px 22px 42px 9px' : '22px 22px 42px 9px'};
  position: relative;
  bottom: ${({ isPhone, ...rest }) => (isPhone ? '75px' : '60px')};
`
export const SidebarMenu = styled('div')`
  background: #eaeced;
  border-radius: 0.5em;
  height: 100%;
  margin: 1em auto;
  padding: 0.5em 1em;
  width: 12em;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`

export const MenuItemWrapper = styled('div')`
  font-size: 16px;
  font-style: normal;
  font-weight: ${({ selected }) => (selected ? 700 : 400)};
  line-height: 120%;
  box-sizing: border-box;
  color: ${({ selected }) => (selected ? '#776AAE' : '#484848')};
  cursor: pointer;
  display: flex;
  width: 100%;
`
export const Backdrop = styled('div')`
  position: fixed;
  top: ${document.getElementById('header') &&
    document.getElementById('header').clientHeight};
  z-index: 99;
  width: 100%;
  height: 100%;
  background: rgb(8, 6, 16, 0.5);
`
export const StyledBreadCrumb = styled(Breadcrumb)`
  align-items: center;
  background: white;
  display: flex;
  height: 4vh;
  padding: 0 25px;
`
