import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled/'
import { Card, Tooltip } from 'antd'

import { ViewTitle } from './styles/Views.styles'
import AttachmentComponent from '../attachments/ShowAttachments'
import ReturnIcon from '../../static/return-icon'
import EyeIcon from '../../static/eye-icon'
import { reportKindOptions } from '../../utils/constants'

const AttachmentsWrapper = styled('div')`
  align-self: flex-start;
  display: ${({ isPhone }) => (isPhone ? 'grid' : 'flex')};
  grid-row-gap: 1em;
  grid-column-gap: 1em;
  grid-template-columns: repeat(5, 0.2fr);
  justify-content: space-evenly;
  flex-wrap: wrap;
`

export const Container = styled('div')`
  width: fit-content;
  &:hover {
    a {
      font-weight: 600;
      svg > circle {
        stroke: #eaeced;
        fill: #776aae;
      }
      svg > path {
        stroke: #fff;
      }
    }
  }
`
export const LineDivider = styled('div')`
  border-bottom: 1px solid #484848;
  margin-top: 7px;
`
export const MessageBox = styled('div')`
  align-self: flex-start;
  border: 1px solid #484848;
  border-radius: 16px;
  margin-bottom: 2em;
  min-height: 60%;
  overflow: auto;
  padding: 1em;
  width: 100%;
  @media (max-width: 767px) {
    max-width: 90vw;
  }
`
export const InfoContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 0.5fr);
  column-gap: 1em;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`
const Views = props => {
  const { readings } = props
  if (!readings || readings.length === 0) {
    return null
  }
  return (
    <Tooltip
      color='#fff'
      placement='bottom'
      title={
        <div style={{ display: 'grid', border: 'none' }}>
          {readings.map(reading => (
            <span style={{ color: '#776AAE' }} key={reading.name}>
              {`${reading.name} em ${new Date(reading.read_at).toLocaleString(
                'pt-BR'
              )}`}
            </span>
          ))}
        </div>
      }
    >
      <div>
        <EyeIcon />
      </div>
    </Tooltip>
  )
}

const MessageView = ({
  initialValues,
  isPhone,
  isReport,
  userProfiles,
  is_admin
}) => {
  const audience_destination_attributes = initialValues.destinations_attributes.filter(
    item => item.destinatable_type === 'Audience'
  )
  const investor_account_destination_attributes = initialValues.destinations_attributes.filter(
    item => item.destinatable_type === 'InvestorAccount'
  )
  const user_destination_attributes = initialValues.destinations_attributes.filter(
    item => item.destinatable_type === 'User'
  )
  const kind = reportKindOptions.find(item => item.value === initialValues.kind)
  return (
    <div
      style={{
        height: '80vh',
        padding: '1em',
        width: '100%'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'initial',
          height: '100%'
        }}
      >
        <Container>
          <Link
            style={{
              display: 'flex',
              color: '#484848',
              alignItems: 'center',
              gap: '4px',
              width: 'fit-content'
            }}
            to={kind.path}
          >
            {' '}
            <ReturnIcon />
            Go back to {kind.label}s list
          </Link>
        </Container>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1vmax',
            marginTop: '16px',
            marginBottom: '16px'
          }}
        >
          <ViewTitle>{initialValues.title}</ViewTitle>
          <Views readings={initialValues.readings} />
        </div>
        <MessageBox
          dangerouslySetInnerHTML={{ __html: initialValues.message }}
        />
        <InfoContainer>
          <div>
            <span
              style={{
                alignSelf: 'flex-start',
                color: '#484848',
                fontSize: '1em',
                fontWeight: 600
              }}
            >
              {' '}
              Attachments{' '}
            </span>
            <LineDivider />
            <AttachmentsWrapper isPhone={isPhone}>
              {initialValues.file_urls ? (
                <AttachmentComponent
                  attachments={initialValues.file_urls}
                  download
                />
              ) : (
                <span style={{ color: '#484848', paddingTop: '16px' }}>
                  {' '}
                  There are no attachments for this{' '}
                  {isReport ? 'report' : 'position statement'}.
                </span>
              )}
            </AttachmentsWrapper>
          </div>
          {(userProfiles.includes('superuser') ||
            userProfiles.includes('admin')) && (
            <div>
              <span
                style={{
                  alignSelf: 'flex-start',
                  color: '#484848',
                  fontSize: '1em',
                  fontWeight: 600
                }}
              >
                Sent to
              </span>
              <LineDivider />
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 0.33fr)',
                  columnGap: '0.5em',
                  marginTop: '16px'
                }}
              >
                {audience_destination_attributes.length ? (
                  <Card
                    style={{
                      borderRadius: '8px'
                    }}
                    size='small'
                    title={'Audiences'}
                    bodyStyle={{
                      display: 'flex',
                      flexDirection: 'column',
                      overflow: 'auto'
                    }}
                    headStyle={{ textAlign: 'center' }}
                  >
                    {audience_destination_attributes.map(audience => {
                      const destinatable = audience.destinatable
                      const name = destinatable.name
                      return <span key={audience.id}> {name} </span>
                    })}
                  </Card>
                ) : null}
                {user_destination_attributes.length ? (
                  <Card
                    size='small'
                    title='Users'
                    bodyStyle={{
                      display: 'flex',
                      flexDirection: 'column',
                      overflow: 'auto'
                    }}
                    headStyle={{ textAlign: 'center' }}
                  >
                    {user_destination_attributes.map(user => {
                      const destinatable = user.destinatable
                      const name = destinatable.name
                      return <span key={user.id}> {name} </span>
                    })}
                  </Card>
                ) : null}
                {investor_account_destination_attributes.length ? (
                  <Card
                    size='small'
                    title='Investors'
                    bodyStyle={{
                      display: 'flex',
                      flexDirection: 'column',
                      overflow: 'auto'
                    }}
                    headStyle={{ textAlign: 'center' }}
                  >
                    {investor_account_destination_attributes.map(investor => {
                      const destinatable = investor.destinatable
                      const name = destinatable.name
                      return <span key={investor.id}> {name} </span>
                    })}
                  </Card>
                ) : null}
              </div>
            </div>
          )}
        </InfoContainer>
      </div>
    </div>
  )
}

export default MessageView
