import React from 'react'
import { hot } from 'react-hot-loader'
import { BrowserRouter as Router } from 'react-router-dom'
import 'antd/dist/antd.css'

import Routes from './routes/Routes'

const supportsHistory = 'pushState' in window.history

const App = () => {
  React.useEffect(() => {
    document.addEventListener('contextmenu', event => {
      event.preventDefault()
    })
  }, [])
  return (
    <Router forceRefresh={!supportsHistory} keyLength={12}>
      <Routes />
    </Router>
  )
}

export default hot(module)(App)
