import styled from '@emotion/styled'

export const AddButton = styled('button')`
  align-items: center;
  background: #776aae !important;
  border: none;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  display: flex;
  gap: 8px;
  padding: 14.5px 24.2px;
  &:hover {
    background: #776aae9e !important;
  }
  @media (max-width: 767px) {
    padding: 12.5px 14.2px;
    font-size: 12px;
    width: 100%;
  }
`
