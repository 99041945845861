import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Layout, Modal } from 'antd'
import { useMediaQuery } from 'react-responsive'

import { Container, ChildrenContainer } from './styles/Template.style'
import SidebarComponent from './SidebarTemplate'
import NavbarComponent from './NavbarTemplate'
import { AlternativeSpinner } from '../loading/Loading'
import {
  LOGOUT as logout,
  updateCredentialsRequest
} from '../../store/auth/actions'
import styled from '@emotion/styled'
const mapStateToProps = state => ({
  user: state.auth.user,
  auth: state.auth,
  isLoadingReport: state.reports.isFetching
})

const mapDispatchToProps = {
  logout,
  updateCredentialsRequest
}
export const Hr = styled('div')`
  border-bottom: 1px solid #484848;
  margin-right: 32px;
  @media (max-width: 767px) {
    margin-right: 0px;
  }
`

export const LoadingMessage = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ModalLoader = ({ visible }) => {
  return (
    <Modal visible={visible} footer={null} maskClosable={false}>
      <LoadingMessage>
        <AlternativeSpinner />
        <span>Carregando</span>
      </LoadingMessage>
    </Modal>
  )
}
export const DashboardTemplate = ({
  children,
  logout,
  user,
  updateCredentialsRequest,
  isLoadingReport,
  auth
}) => {
  const [menu, setMenu] = React.useState(false)
  const isPhone = useMediaQuery({ query: '(max-width: 767px)' })
  return (
    <Container>
      <NavbarComponent
        logout={logout}
        updateCredentialsRequest={updateCredentialsRequest}
        menu={menu}
        setMenu={setMenu}
        isPhone={isPhone}
        auth={auth}
      />
      <div
        style={{
          display: 'flex',
          flexGrow: '1'
        }}
      >
        <SidebarComponent
          user={user}
          isPhone={isPhone}
          menu={menu}
          setMenu={setMenu}
        />
        <Layout.Content>
          <Hr />
          <ChildrenContainer>
            <ModalLoader visible={isLoadingReport} />
            {children}
          </ChildrenContainer>
        </Layout.Content>
      </div>
    </Container>
  )
}

DashboardTemplate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.array
  ]).isRequired,
  logout: PropTypes.func,
  updateCredentialsRequest: PropTypes.func,
  user: PropTypes.object,
  auth: PropTypes.object,
  isLoadingReport: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTemplate)
