import { createAction, createReducer } from '@reduxjs/toolkit'

export const fetchInvestmentsRequest = createAction(
  'investments/FETCH_INVESTMENTS_REQUEST'
)
export const fetchInvestmentsSuccess = createAction(
  'investments/FETCH_INVESTMENTS_SUCCESS'
)
export const fetchInvestmentsError = createAction(
  'investments/FETCH_INVESTMENTS_ERROR'
)

export const createInvestmentRequest = createAction(
  'investments/CREATE_INVESTMENT_REQUEST'
)
export const createInvestmentSuccess = createAction(
  'investments/CREATE_INVESTMENT_SUCCESS'
)
export const createInvestmentError = createAction(
  'investments/CREATE_INVESTMENT_ERROR'
)

export const updateInvestmentRequest = createAction(
  'investments/UPDATE_INVESTMENT_REQUEST'
)
export const updateInvestmentSuccess = createAction(
  'investments/UPDATE_INVESTMENT_SUCCESS'
)
export const updateInvestmentError = createAction(
  'investments/UPDATE_INVESTMENT_ERROR'
)

export const deleteInvestmentRequest = createAction(
  'investments/DELETE_INVESTMENT_REQUEST'
)

export const updateInvestmentsPagination = createAction(
  'investments/UPDATE_INVESTMENTS_PAGINATION'
)

const initialState = {
  items: [],
  pagination: {
    current: 1,
    defaultPageCurrent: 1,
    defaultPageSize: 20,
    pageSize: 20,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true
  },
  isFetching: false,
  error: null
}

export const InvestmentsReducer = createReducer(initialState, {
  [fetchInvestmentsRequest]: (state, action) => ({
    ...state,
    isFetching: true,
    error: null
  }),
  [fetchInvestmentsSuccess]: (state, action) => ({
    ...state,
    isFetching: false,
    items: action.payload
  }),
  [fetchInvestmentsError]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload
  }),
  [createInvestmentRequest]: (state, action) => ({
    ...state,
    isFetching: true,
    error: null
  }),
  [createInvestmentSuccess]: (state, action) => ({
    ...state,
    isFetching: false
  }),
  [createInvestmentError]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload
  }),
  [updateInvestmentRequest]: (state, action) => ({
    ...state,
    isFetching: true,
    error: null
  }),
  [updateInvestmentSuccess]: (state, action) => ({
    ...state,
    isFetching: false
  }),
  [updateInvestmentError]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload
  }),
  [updateInvestmentsPagination]: (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      ...action.payload
    }
  })
})
