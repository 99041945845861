import styled from '@emotion/styled'
import { Form } from 'antd'

export const FormViewWrapper = styled('div')`
  font-family: 'Montserrat';
  max-width: ${props => (props.isPhone ? '85vw' : '')};
  padding: 1em;
  text-align: initial;
  .ant-btn,
  .ant-btn-submit {
    border-radius: 8px;
    padding: 14.5px 24.2px;
    background-color: #776aae;
    height: fit-content;
    font-weight: 600;
    color: #fff;
    &:hover {
      border: none;
      background-color: #776aae7e;
    }
  }
`

export const FormWrapper = styled(Form)`
  color: #004e7c;
  padding: 1em;
  .ant-input:focus,
  .ant-input-focused {
    border-color: #776aae;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px #776aae79;
  }
  .ant-input:hover {
    border-color: #776aae;
  }
  .ant-input {
    border-radius: 8px;
    border-color: #fff;
  }
  @media (max-width: 767px) {
    padding: 0px;
  }
`
export const FieldsWrapper = styled('div')`
  align-items: center;
  display: grid;
  grid-template-columns: ${props => (props.isPhone ? '' : 'repeat(2, 0.8fr)')};
  column-gap: 1em;
  padding: 1em;
`

export const FormButtonsArea = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin: 1em 0;
`
export const SelectUsersWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  background: #fff;
  border-radius: 16px;
  justify-content: center;
  margin-top: 1em;
  overflow: auto;
  padding: 2em;
  position: relative;
  max-width: ${props => (props.isPhone ? '85vw' : '')};
`
export const LetterSpan = styled('span')`
  font-size: 1.5em;
  font-weight: bold;
`

export const BlockArea = styled('div')`
  background: #9fa3a763;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
`

export const FormTitle = styled('span')`
  color: #004e7c;
  font-size: 2em;
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 16px;
`

export const Divider = styled('hr')`
  background-color: #004e7c;
  color: #004e7c;
  height: 0.2em;
`
export const UserAttr = styled('div')`
  align-items: center;
  display: flex;
  font-size: ${props => (props.isPhone ? '0.7em' : '1em')};
  justify-content: left;
  width: 100%;
  svg {
    width: 22px !important;
    height: 22px !important;
  }
`

export const UserCard = styled('div')`
  align-items: center;
  border: 1px solid #484848;
  border-radius: 16px;
  cursor: default;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: ${props => (props.isPhone ? '5em' : '10em')};
  padding: 8px;
  min-width: 235px;
  max-width: fit-content;
  div > div > svg:nth-of-type(1) {
    justify-self: center;
    width: 32px;
    height: 32px;
    path {
      stroke: #484848;
    }
  }
`
