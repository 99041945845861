import React from 'react'

const AddIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
    >
      <path
        d='M7.24512 10.9822H14.1186'
        stroke='white'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M10.6819 7.54541V14.4189'
        stroke='white'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M5.44098 16.2227C8.33545 19.1172 13.0283 19.1172 15.9228 16.2227C18.8173 13.3283 18.8173 8.63538 15.9228 5.74091C13.0283 2.84644 8.33545 2.84644 5.44098 5.74091C2.5465 8.63538 2.5465 13.3283 5.44098 16.2227Z'
        stroke='white'
        strokeMiterlimit='10'
      />
    </svg>
  )
}

export default AddIcon
