import React from 'react'
import { Input } from 'antd'
const { TextArea } = Input

const TextAreaInput = fields => (
  <div>
    <TextArea
      {...fields.input}
      placeholder={fields.placeholder}
      disabled={fields.disabled}
    />
    {fields.meta.touched &&
      ((fields.meta.error && (
        <span className='form__massage-error'>{fields.meta.error}</span>
      )) ||
        (fields.meta.warning && (
          <span className='form__massage-error'>{fields.meta.warning}</span>
        )))}
  </div>
)

export default TextAreaInput
