import { createAction, createReducer } from '@reduxjs/toolkit'
import { createPromiseAction } from 'redux-saga-promise-actions'

export const fetchReports = createPromiseAction('reports/FETCH_REPORTS')()

export const createReportRequest = createAction('reports/CREATE_REPORT_REQUEST')
export const createReportSuccess = createAction('reports/CREATE_REPORT_SUCCESS')
export const createReportFailure = createAction('reports/CREATE_REPORT_FAILURE')

export const updateReportRequest = createAction('reports/UPDATE_REPORT_REQUEST')
export const updateReportSuccess = createAction('reports/UPDATE_REPORT_SUCCESS')
export const updateReportFailure = createAction('reports/UPDATE_REPORT_FAILURE')

export const deleteReport = createPromiseAction('reports/DELETE_REPORT')()
export const deleteReportFileRequest = createAction(
  'reports/DELETE_REPORT_FILE_REQUEST'
)

export const updateReportPagination = createAction(
  'reports/UPDATE_REPORT_PAGINATION'
)

export const toggleFavoriteReport = createPromiseAction(
  'reports/TOGGLE_FAVORITE_REPORT'
)()

const initialState = {
  items: [],
  pagination: {
    current: 1,
    defaultPageCurrent: 1,
    defaultPageSize: 20,
    pageSize: 20,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true
  },
  isFetching: false,
  error: null
}

export const reportsReducer = createReducer(initialState, {
  [fetchReports.request]: (state, action) => ({
    ...state,
    isFetching: true,
    error: null
  }),
  [fetchReports.success]: (state, action) => ({
    ...state,
    isFetching: false,
    items: action.payload
  }),
  [fetchReports.failure]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload
  }),
  [createReportRequest]: (state, action) => ({
    ...state,
    isFetching: true,
    error: null
  }),
  [createReportSuccess]: (state, action) => ({
    ...state,
    isFetching: false
  }),
  [createReportFailure]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload
  }),
  [updateReportRequest]: (state, action) => ({
    ...state,
    isFetching: true,
    error: null
  }),
  [updateReportSuccess]: (state, action) => ({
    ...state,
    isFetching: false
  }),
  [updateReportFailure]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload
  }),
  [updateReportPagination]: (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      ...action.payload
    }
  })
})
