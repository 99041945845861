import React from 'react'

const LockIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
    >
      <path
        d='M17.5579 9.51904H7.41212C5.52766 9.51904 4 10.8181 4 12.4206V21.0482C4 22.6507 5.52766 23.9498 7.41212 23.9498H17.5579C19.4423 23.9498 20.97 22.6507 20.97 21.0482V12.4206C20.97 10.8181 19.4423 9.51904 17.5579 9.51904Z'
        stroke='#484848'
        strokeWidth='0.75'
        strokeMiterlimit='10'
      />
      <path
        d='M7.68359 9.22395C7.94781 6.84878 9.99357 5 12.4847 5C14.9759 5 17.0292 6.84878 17.2858 9.22395'
        stroke='#484848'
        strokeWidth='0.75'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M12.485 17.1584C13.3563 17.1584 14.0627 16.5577 14.0627 15.8167C14.0627 15.0758 13.3563 14.4751 12.485 14.4751C11.6136 14.4751 10.9072 15.0758 10.9072 15.8167C10.9072 16.5577 11.6136 17.1584 12.485 17.1584Z'
        stroke='#484848'
        strokeWidth='0.75'
        strokeMiterlimit='10'
      />
      <path
        d='M12.4849 17.1582V18.9941'
        stroke='#484848'
        strokeWidth='0.75'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default LockIcon
