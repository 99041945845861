import React from 'react'
import { connect } from 'react-redux'
import { Container } from './styles/Template.style'

import NavbarComponent from './NavbarTemplate'

const mapStateToProps = state => ({
  user: state.auth.user,
  auth: state.auth
})

const LoginTemplate = ({ children, auth }) => {
  return (
    <Container>
      <NavbarComponent auth={auth} />
      <div
        style={{
          margin: 'auto'
        }}
      >
        {children}
      </div>
    </Container>
  )
}

export default connect(mapStateToProps)(LoginTemplate)
