import React from 'react'

const PhoneIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.9662 18.8291C22.1379 18.3345 21.0567 17.7823 20.0846 18.1792C19.3369 18.484 18.8596 19.6574 18.3707 20.2555C18.1234 20.5603 17.8243 20.6121 17.4389 20.4568C14.6264 19.3353 12.4696 17.4603 10.9167 14.8721C10.6521 14.4694 10.6981 14.1531 11.0202 13.7793C11.4918 13.2271 12.0785 12.6002 12.205 11.8582C12.3315 11.1163 11.9807 10.2421 11.6759 9.58062C11.2848 8.73514 10.8419 7.52732 9.99641 7.04994C9.2142 6.60707 8.19042 6.85438 7.49448 7.42379C6.29816 8.40155 5.71725 9.92572 5.7345 11.4441C5.7345 11.8755 5.79202 12.3069 5.89555 12.721C6.13711 13.7217 6.59724 14.6535 7.12063 15.545C7.51174 16.2122 7.93735 16.8621 8.39748 17.489C9.90438 19.5308 11.7736 21.3081 13.942 22.6367C15.0233 23.2981 16.1908 23.879 17.3987 24.2816C18.7503 24.7302 19.9581 25.1961 21.4248 24.9143C22.9547 24.6209 24.4673 23.6777 25.0712 22.1995C25.2495 21.7624 25.3416 21.2735 25.238 20.8134C25.031 19.8587 23.7369 19.295 22.9662 18.8291Z'
        stroke='#484848'
        strokeWidth='0.75'
        strokeMiterlimit='10'
      />
    </svg>
  )
}

export default PhoneIcon
