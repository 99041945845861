import styled from '@emotion/styled'

export const TableContainer = styled('div')`
  position: relative;
  .ant-input-affix-wrapper {
    border-radius: 32px;
    background-color: #eaeced;
    border-color: #484848;
    input {
      background-color: #eaeced;
      padding-left: 24px;
    }
    input::placeholder {
      color: #484848;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
    }
    .anticon-search {
      svg > path {
        fill: #484848;
      }
    }
  }
  .ant-input-affix-wrapper:hover {
    border-color: #776aae;
  }
  .ant-table-wrapper {
    > td {
      border: none;
    }
    .ant-table-thead {
      .ant-table-cell:last-of-type {
        @media (min-width: 767px) {
          text-align: center;
        }
      }
      .ant-table-cell {
        background-color: transparent !important;
        border: none;
        span {
          color: #bfbfbf !important;
        }
      }
    }
    .ant-table-row {
      .ant-table-cell {
        padding: 20px 0px;
        border: none;
        > span {
          color: #484848 !important;
          font-weight: 500 !important;
        }
      }
    }
    .table-row-light {
      background-color: #eaeced;
    }
    .table-row-dark {
      .ant-table-cell:first-of-type {
        border-top-left-radius: 32px;
        border-bottom-left-radius: 32px;
      }
      .ant-table-cell:last-of-type {
        border-top-right-radius: 32px;
        border-bottom-right-radius: 32px;
      }
    }
    .table-row-light {
      .ant-table-cell:first-of-type {
        border-top-left-radius: 32px;
        border-bottom-left-radius: 32px;
      }
      .ant-table-cell:last-of-type {
        border-top-right-radius: 32px;
        border-bottom-right-radius: 32px;
      }
    }
    .ant-table-tbody > .ant-table-row:hover td {
      background-color: #776aae !important;
      color: #eaeced;
    }
    .ant-table-tbody > .ant-table-row:hover td {
      span {
        color: #eaeced !important;
      }
      div > button > svg > path {
        stroke: #eaeced;
      }
    }
    .ant-table {
      background: transparent;
    }
    .ant-select-selector {
      background-color: transparent;
      border: none !important;
      span {
        color: #484848;
        font-family: 'Montserrat';
      }
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #776aae9e !important;
    }
    .ant-select-focused:not(.ant-select-disabled):hover .ant-select-selector,
    .ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
      box-shadow: none;
    }
    .ant-select {
      .ant-select-arrow {
        > span > svg > path {
          fill: #484848;
        }
      }
    }
  }
`
