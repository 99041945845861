import React from 'react'

const ReportIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 15 18'
      fill='none'
    >
      <path
        d='M4.44037 1.46777H2.53144C1.68651 1.46777 1 2.23168 1 3.17187V14.8459C1 15.7883 1.68651 16.55 2.53144 16.55H12.7215C13.5684 16.55 14.253 15.7861 14.253 14.8459V3.17187C14.253 2.2295 13.5664 1.46777 12.7215 1.46777H10.8008'
        stroke='#B9B3D6'
        strokeMiterlimit='10'
      />
      <path
        d='M10.1746 1H5.07956C4.70581 1 4.40283 1.33714 4.40283 1.75302V2.38634C4.40283 2.80223 4.70581 3.13937 5.07956 3.13937H10.1746C10.5483 3.13937 10.8513 2.80223 10.8513 2.38634V1.75302C10.8513 1.33714 10.5483 1 10.1746 1Z'
        stroke='#B9B3D6'
        strokeMiterlimit='10'
      />
      <path
        d='M5.10769 6L3.942 7.41464C3.92244 7.44075 3.89506 7.45599 3.86768 7.45599C3.84029 7.45599 3.81487 7.44293 3.79335 7.41464L3.17139 6.59197'
        stroke='#B9B3D6'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M12.0838 7.02295H6.26123'
        stroke='#B9B3D6'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M5.10769 11.4561L3.942 12.8707C3.92244 12.8968 3.89506 12.912 3.86768 12.912C3.84029 12.912 3.81487 12.899 3.79335 12.8707L3.17139 12.048'
        stroke='#B9B3D6'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M12.0838 12.4771H6.26123'
        stroke='#B9B3D6'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default ReportIcon
