import React from 'react'
import emotionNormalize from 'emotion-normalize'
import { Global, css } from '@emotion/core'

export const GlobalStyle = () => (
  <Global
    styles={css`
      ${emotionNormalize} @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
      body {
        font-family: 'Montserrat', sans-serif;
        overflow-x: hidden;
        background-color: #eaeced;
        #app {
          overflow-x: hidden;
        }
        .ant-dropdown-menu {
          border-radius: 8px;
          display: flex;
          gap: 8px;
          flex-direction: column;
          padding: 8px 0px;
          width: fit-content;
        }
      }

      @media print {
        .pdf-container {
          display: none;
        }
      }

      .ant-modal-content:has(.pdf-container) {
        max-width: 90vw;
        width: fit-content;
        overflow: scroll;
        max-height: 75vh;
      }
      .ant-modal-wrap:has(.pdf-container) {
        display: flex;
        .ant-modal {
          width: auto !important;
          margin: auto;
        }
      }
    `}
  />
)
