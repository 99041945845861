import React from 'react'

const EditIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='13'
      height='18'
      viewBox='0 0 13 18'
      fill='none'
    >
      <path
        d='M1 17.6582H12'
        stroke='#484848'
        strokeWidth='0.75'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M3.97449 16.3484L11.8868 3.02373C12.157 2.56875 11.9271 1.93605 11.3702 1.61377L10.5788 1.15169C10.0243 0.827044 9.35364 0.933679 9.0835 1.39102L1.17118 14.7157L1.00293 15.0095L1.25885 17.4052C1.27307 17.5427 1.41525 17.628 1.54321 17.5759L3.80151 16.6517L3.97449 16.3507V16.3484Z'
        stroke='#484848'
        strokeWidth='0.75'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M7.14746 4.73975L9.92234 6.32742'
        stroke='#484848'
        strokeWidth='0.75'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default EditIcon
