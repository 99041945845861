import styled from '@emotion/styled'

export const InputWrapper = styled('div')`
  align-items: center;
  background: white;
  border-bottom: 1px solid rgb(118, 117, 118, 0.1);
  color: #bfbebf;
  display: flex;
  height: 2em;
  margin: 1em 0;
  outline: none;
  padding: ${({ isPhone, ...rest }) => (isPhone ? '0.5em 0' : '1.5em 0')};
  width: ${({ isPhone, ...rest }) => (isPhone ? '100%' : '25em')};
  & input:focus {
    outline: none;
  }
  & .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
    outline: none;
  }
  & .ant-input-affix-wrapper {
    padding: 4px;
  }
  & .ant-input-affix-wrapper > input {
    font-size: 1.5em;
  }
  & .ant-input-group-wrapper > .ant-input-wrapper > .ant-input-group-addon {
    border: none;
  }
  & .ant-input-group-wrapper > .ant-input-wrapper > input {
    border: none;
  }
`
