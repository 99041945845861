/* eslint-disable react/display-name */
import React from 'react'
import { Input, Menu, Dropdown } from 'antd'
import { SyncOutlined, SearchOutlined, MoreOutlined } from '@ant-design/icons'
import { useMediaQuery } from 'react-responsive'

import ModalComponent from '../modal/ListActionsModal'
import TableComponent from '../table/Table'
import { AlternativeSpinner } from '../loading/Loading'
import EyeIcon from '../../static/eye-icon'
import EditIcon from '../../static/edit-icon'
import RemoveIcon from '../../static/remove-icon'
import AddIcon from '../../static/add-icon'
import { AddButton } from '../button/AddButton'
import { ResetButton } from '../button/ResetButton'
import { TableContainer } from '../container/TableContainer'
import { TitleContainer } from '../container/TitleContainer'
import { H2 } from '../h2/CustomH2'
import { TableButtonsContainer } from '../container/TableButtonsContainer'

const DropdownMenu = ({ record, history, setModal, setModalData }) => (
  <Menu>
    <Menu.Item>
      <button
        style={{ border: 'none', background: 'transparent' }}
        onClick={() => history.push(`/audiences/${record.id}`)}
      >
        <EyeIcon />
      </button>
    </Menu.Item>
    <Menu.Item>
      <button
        style={{ border: 'none', background: 'transparent' }}
        onClick={() => history.push(`/audiences/edit/${record.id}`)}
      >
        <EditIcon />
      </button>
    </Menu.Item>
    <Menu.Item>
      <button
        style={{ border: 'none', background: 'transparent' }}
        onClick={() => {
          setModalData({
            ...record
          })
          setModal(true)
        }}
      >
        <RemoveIcon />
      </button>
    </Menu.Item>
  </Menu>
)

const AudiencesView = props => {
  const {
    data,
    history,
    deleteAudienceRequest,
    fetchAudiencesRequest,
    loadingAudiences,
    updateAudiencesPagination,
    pagination
  } = props
  const [showModal, setModal] = React.useState(false)
  const [modalData, setModalData] = React.useState({})
  const [nameFilter, setNameFilter] = React.useState('')
  const isPhone = useMediaQuery({ query: '(max-width: 767px)' })
  const handleConfirmDelete = () => {
    deleteAudienceRequest(modalData.id)
    setModal(false)
    setModalData({})
  }
  const handleCancelDelete = () => {
    setModal(false)
    setModalData({})
  }
  const handleSizeChange = (current, size) => {
    updateAudiencesPagination({ current, pageSize: size })
    fetchAudiencesRequest()
  }
  const columns = [
    {
      title: () => {
        return (
          <span
            style={{
              fontWeight: 'bold',
              color: 'rgb(94, 95, 96)',
              paddingLeft: '20px'
            }}
          >
            {' '}
            Name{' '}
          </span>
        )
      },
      key: 'name',
      dataIndex: 'name',
      render: (text, record) => {
        return (
          <span
            style={{
              color: '#4A55A5',
              fontWeight: 'bold',
              paddingLeft: '20px'
            }}
          >
            {' '}
            {record.name}{' '}
          </span>
        )
      }
    },
    {
      title: () => {
        return (
          <span style={{ fontWeight: 'bold', color: 'rgb(94, 95, 96)' }}>
            {' '}
            Created at{' '}
          </span>
        )
      },
      key: 'created',
      render: (_, record) => {
        let date = new Date(record.created_at)
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let dt = date.getDate()
        let hour = date.getHours()
        let minute = date.getMinutes()

        if (dt < 10) {
          dt = '0' + dt
        }
        if (month < 10) {
          month = '0' + month
        }
        return (
          <span>
            {month + '/' + dt + '/' + year + ' - ' + hour + ':' + minute}
          </span>
        )
      }
    },
    {
      title: () => {
        return (
          <span style={{ fontWeight: 'bold', color: 'rgb(94, 95, 96)' }}>
            {' '}
            Users{' '}
          </span>
        )
      },
      key: 'users',
      render: (_, record) => {
        return <span> {record.user_ids.length} </span>
      }
    },
    {
      title: () => {
        return (
          <span style={{ fontWeight: 'bold', color: 'rgb(94, 95, 96)' }}>
            {' '}
            Actions{' '}
          </span>
        )
      },
      key: 'actions',
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <ModalComponent
            visible={showModal}
            handleOk={handleConfirmDelete}
            handleCancel={handleCancelDelete}
            modalData={modalData}
            onOkText='Delete'
            onOkButtonProps={{ danger: true }}
          />
          <button
            style={{
              border: 'none',
              background: 'transparent',
              cursor: 'pointer'
            }}
            onClick={() => history.push(`/audiences/${record.id}`)}
          >
            <EyeIcon />
          </button>
          <button
            style={{
              border: 'none',
              background: 'transparent',
              cursor: 'pointer'
            }}
            onClick={() => history.push(`/audiences/edit/${record.id}`)}
          >
            <EditIcon />
          </button>
          <button
            style={{
              border: 'none',
              background: 'transparent',
              cursor: 'pointer'
            }}
            onClick={() => {
              setModalData({
                ...record
              })
              setModal(true)
            }}
          >
            <RemoveIcon />
          </button>
        </div>
      )
    }
  ]
  const mobileColumn = [
    {
      title: () => {
        return (
          <span style={{ fontWeight: 'bolder', paddingLeft: '20px' }}>
            {' '}
            Audiences{' '}
          </span>
        )
      },
      render: (_, record) => {
        let date = new Date(record.created_at)
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let dt = date.getDate()
        let hour = date.getHours()
        let minute = date.getMinutes()

        if (dt < 10) {
          dt = '0' + dt
        }
        if (month < 10) {
          month = '0' + month
        }
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingRight: '20px'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <span style={{ fontWeight: 'bolder', paddingLeft: '20px' }}>
                {' '}
                {record.name}{' '}
              </span>
              <span style={{ paddingLeft: '20px' }}>
                {' '}
                Created at:{' '}
                {month +
                  '/' +
                  dt +
                  '/' +
                  year +
                  ' - ' +
                  hour +
                  ':' +
                  minute}{' '}
              </span>
              <span style={{ paddingLeft: '20px' }}>
                {' '}
                Users: {record.user_ids.length}{' '}
              </span>
            </div>
            <ModalComponent
              visible={showModal}
              handleOk={handleConfirmDelete}
              handleCancel={handleCancelDelete}
              modalData={modalData}
              onOkText='Delete'
              onOkButtonProps={{ danger: true }}
            />
            <Dropdown
              trigger={['click']}
              overlay={
                <DropdownMenu
                  record={record}
                  history={history}
                  setModal={setModal}
                  setModalData={setModalData}
                />
              }
            >
              <MoreOutlined style={{ fontSize: '2em' }} />
            </Dropdown>
          </div>
        )
      }
    }
  ]

  const getColumns = isPhone ? mobileColumn : columns
  return (
    <TableContainer style={{ width: '100%' }}>
      <TitleContainer style={{ display: 'flex' }}>
        <H2 style={{ marginTop: '16px' }}>Audiences</H2>
        <TableButtonsContainer>
          <div
            style={{
              display: 'flex',
              gap: '8px'
            }}
          >
            <ResetButton style={{ background: '#E0E1E1' }}>
              <SyncOutlined onClick={() => fetchAudiencesRequest()} />
            </ResetButton>
            <Input
              placeholder='Search by name'
              value={nameFilter}
              onChange={e => setNameFilter(e.target.value)}
              style={{ width: '70%' }}
              suffix={
                <SearchOutlined
                  onClick={() => {
                    fetchAudiencesRequest({ filters: { name: nameFilter } })
                  }}
                />
              }
            />
          </div>
          <div>
            <AddButton
              style={{ background: '#E0E1E1' }}
              onClick={() => history.push('/audiences/new')}
            >
              <AddIcon />
              Add audience
            </AddButton>
          </div>
        </TableButtonsContainer>
      </TitleContainer>
      <TableComponent
        dataSource={data}
        rowKey={record => record.id}
        columns={getColumns}
        pagination={{
          ...pagination,
          onShowSizeChange: handleSizeChange,
          onChange: handleSizeChange
        }}
        loading={{
          spinning: loadingAudiences,
          indicator: <AlternativeSpinner />
        }}
        style={{
          padding: '0.75em 0'
        }}
        size='small'
      />
    </TableContainer>
  )
}

export default AudiencesView
