import React from 'react'

const ReturnIcon = () => {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        id='Ellipse 364'
        cx='13'
        cy='13'
        r='12.5'
        transform='rotate(-180 13 13)'
        fill='#EAECED'
        stroke='#484848'
      />
      <path
        id='Shape'
        d='M15.8534 18.8537C15.6585 19.0493 15.3419 19.0499 15.1463 18.855L9.66178 13.39C9.44607 13.1751 9.44607 12.8257 9.66178 12.6108L15.1463 7.14582C15.3419 6.9509 15.6585 6.95147 15.8534 7.14708C16.0483 7.34269 16.0477 7.65927 15.8521 7.85418L10.6875 13.0004L15.8521 18.1466C16.0477 18.3415 16.0483 18.6581 15.8534 18.8537Z'
        fill='#484848'
      />
    </svg>
  )
}

export default ReturnIcon
