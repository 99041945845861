import React from 'react'
import { Upload } from 'antd'
import { PaperClipOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'
import styled from '@emotion/styled/'
import { toast } from 'react-toastify'

import AttachmentComponent from '../attachments/ShowAttachments'

const AttachmentsWrapper = styled('div')`
  align-self: flex-start;
  display: ${({ isPhone }) => (isPhone ? 'grid' : 'flex')};
  grid-row-gap: 1em;
  grid-column-gap: 1em;
  grid-template-columns: repeat(5, 0.2fr);
  flex-wrap: wrap;
`
const notify = () => toast.error('Only .PDF are accepted!')
const UploadInput = props => {
  const { isDrag, children, input, ...rest } = props

  return isDrag ? (
    <Upload.Dragger {...input} {...rest}>
      {children}
    </Upload.Dragger>
  ) : (
    <Upload {...input} {...rest}>
      {' '}
      {children}{' '}
    </Upload>
  )
}

const UploadInputComponent = props => {
  const {
    fields,
    edit,
    itemId,
    meta,
    forceUpdate,
    fieldArrayValue,
    ...rest
  } = props
  const dispatch = useDispatch()
  const deleteFileHandler = async (file, index) => {
    const fileIndex = index
    if (edit) {
      dispatch(change(meta.form, `files[${fileIndex}][_destroy]`, true))
    } else {
      fields.remove(fileIndex)
    }
    await forceUpdate()
  }
  return (
    <>
      <UploadInput
        showUploadList={false}
        style={{
          margin: '1em 0',
          background: '#F0F0F0'
        }}
        beforeUpload={file => {
          const fileType = file.type
          if (fileType !== 'application/pdf') {
            notify()
            return false
          }

          fields.push({
            name: file.name,
            size: file.size,
            file
          })
          return false
        }}
        fileList={fieldArrayValue}
        accept='.pdf'
        {...rest}
      >
        <PaperClipOutlined />
        <span> Attachment </span>
      </UploadInput>
      {fieldArrayValue && (
        <AttachmentsWrapper>
          <AttachmentComponent
            deleteHandle={deleteFileHandler}
            attachments={fieldArrayValue}
          />
        </AttachmentsWrapper>
      )}
    </>
  )
}

export default UploadInputComponent
