import { setQueryParams } from '../../utils/functions'
import http from '../../utils/http'

const reportsEndpoint = '/reports'
let multipartInstance = http
multipartInstance.defaults.headers.common['Content-Type'] =
  'multipart/form-data; boundary=----------XnJLe9ZIbbGUYtzPQJ16u1'
export const createReport = params => {
  return multipartInstance
    .post(reportsEndpoint, params)
    .then(res => res)
    .catch(err => err.response)
}

export const fetchReports = params => {
  const querystring = setQueryParams(params)
  let endpoint = `${reportsEndpoint}?${querystring}`

  return http
    .get(endpoint)
    .then(res => res)
    .catch(err => err.response)
}

export const updateReport = ({ params, id }) => {
  return multipartInstance
    .put(`${reportsEndpoint}/${id}`, params)
    .then(res => res)
    .catch(err => err.response)
}

export const deleteReport = ({ id }) => {
  return http({
    url: `${reportsEndpoint}/${id}`,
    headers: {
      'Content-Type': 'application/vnd.api+json'
    },
    method: 'DELETE',
    data: {}
  })
}

export const deleteReportFile = ({ reportId, fileId }) => {
  return http({
    url: `${reportsEndpoint}/${reportId}/files/${fileId}`,
    headers: {
      'Content-Type': 'application/vnd.api+json'
    },
    method: 'DELETE',
    data: {}
  })
}

export const toggleFavoriteReport = ({ id, favorite }) => {
  if (favorite) {
    return http
      .post(`${reportsEndpoint}/${id}/favorite`, { data: {} })
      .then(res => res)
      .catch(err => err.response)
  } else {
    return http
      .delete(`${reportsEndpoint}/${id}/favorite`, { data: {} })
      .then(res => res)
      .catch(err => err.response)
  }
}
