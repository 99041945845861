import React from 'react'
import PropTypes from 'prop-types'
import { Select, Spin } from 'antd'
import FormItemWithValidation from '../form/Validation/FormItemWithValidation'

const FormSelect = ({
  input: select,
  meta: { touched, error },
  onSearch,
  options,
  label,
  loading,
  searchable,
  disabled,
  placeholder,
  formItem,
  required,
  defaultValue,
  children,
  ...props
}) => {
  return (
    <FormItemWithValidation
      touched={touched}
      error={error}
      label={label}
      required={required}
      {...formItem}
    >
      {children || (
        <Select
          {...select}
          onBlur={() => select.onBlur(select.value)}
          showSearch={searchable}
          notFoundContent={loading ? <Spin size='small' /> : 'Sem resultados'}
          onSearch={onSearch}
          optionFilterProp='children'
          disabled={disabled}
          value={select.value || undefined}
          placeholder={placeholder}
          defaultValue={defaultValue}
          {...props}
        >
          {options.map(option => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      )}
    </FormItemWithValidation>
  )
}

FormSelect.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onSearch: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string
  }).isRequired,
  searchable: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  formItem: PropTypes.object,
  defaultValue: PropTypes.object,
  children: PropTypes.object
}

FormSelect.defaultProps = {
  options: [],
  required: false
}

export default FormSelect
