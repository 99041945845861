import styled from '@emotion/styled'

export const FormContainer = styled('div')`
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: 1px solid #776aae;
  }
  .ant-select .ant-select-selector {
    border-radius: 8px;
    border: none;
  }
  .ant-input-group-addon {
    border: none;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`
