import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  fetchAudiencesRequest,
  deleteAudienceRequest,
  updateAudiencesPagination
} from '../../store/audiences/'

import AudiencesView from '../../components/view/AudiencesView'

const mapStateToProps = ({ audiences, auth }) => ({
  audienceItems: audiences.items,
  loadingAudiences: audiences.isFetching,
  userProfiles: auth.user.profiles,
  pagination: audiences.pagination
})

const mapDispatchToProps = {
  fetchAudiencesRequest,
  deleteAudienceRequest,
  updateAudiencesPagination
}

const AudiencesContainer = props => {
  const { fetchAudiencesRequest, audienceItems, userProfiles, ...rest } = props
  const history = useHistory()
  React.useEffect(() => {
    if (
      userProfiles?.includes('superuser') ||
      userProfiles?.includes('admin')
    ) {
      fetchAudiencesRequest()
    } else {
      history.push('/')
    }
  }, [])
  return (
    <AudiencesView
      {...rest}
      fetchAudiencesRequest={fetchAudiencesRequest}
      history={history}
      data={audienceItems}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AudiencesContainer)
