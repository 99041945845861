/* eslint-disable react/display-name */
import React from 'react'
import { Input, Menu, Dropdown } from 'antd'
import {
  SyncOutlined,
  SearchOutlined,
  PlusCircleOutlined,
  MoreOutlined
} from '@ant-design/icons'
import { useMediaQuery } from 'react-responsive'

import TableComponent from '../table/Table'
import ModalComponent from '../modal/ListActionsModal'
import { AlternativeSpinner } from '../loading/Loading'
import EditIcon from '../../static/edit-icon'
import RemoveIcon from '../../static/remove-icon'
import { TableContainer } from '../container/TableContainer'
import { TitleContainer } from '../container/TitleContainer'
import { H2 } from '../h2/CustomH2'
import { TableButtonsContainer } from '../container/TableButtonsContainer'
import { ResetButton } from '../button/ResetButton'
import { AddButton } from '../button/AddButton'
import AddIcon from '../../static/add-icon'

const DropdownMenu = ({ history, record, setModal, setModalData }) => (
  <Menu style={{ borderRadius: '8px' }}>
    <Menu.Item>
      <button
        style={{ border: 'none', background: 'transparent' }}
        onClick={() => history.push(`/investments/edit/${record.id}`)}
      >
        <EditIcon />
      </button>
    </Menu.Item>
    <Menu.Item>
      <button
        style={{ border: 'none', background: 'transparent' }}
        onClick={() => {
          setModalData({
            ...record
          })
          setModal(true)
        }}
      >
        <RemoveIcon />
      </button>
    </Menu.Item>
  </Menu>
)

const InvestmentsView = props => {
  const isPhone = useMediaQuery({ query: '(max-width: 767px)' })
  const {
    data,
    history,
    deleteInvestmentRequest,
    fetchInvestmentsRequest,
    loadingInvestments,
    updateInvestmentsPagination,
    pagination
  } = props
  const [showModal, setModal] = React.useState(false)
  const [modalData, setModalData] = React.useState({})
  const [nameFilter, setNameFilter] = React.useState('')
  const handleConfirmDelete = () => {
    deleteInvestmentRequest(modalData.id)
    setModal(false)
    setModalData({})
  }
  const handleCancelDelete = () => {
    setModal(false)
    setModalData({})
  }
  const handleSizeChange = (current, size) => {
    updateInvestmentsPagination({ current, pageSize: size })
    fetchInvestmentsRequest()
  }

  const columns = [
    {
      title: () => {
        return (
          <span
            style={{
              fontWeight: 'bold',
              color: 'rgb(94, 95, 96)',
              paddingLeft: '20px'
            }}
          >
            {' '}
            Name{' '}
          </span>
        )
      },
      key: 'name',
      dataIndex: 'name',
      render: (text, record) => {
        return (
          <span
            style={{
              color: '#4A55A5',
              fontWeight: 'bold',
              paddingLeft: '20px'
            }}
          >
            {' '}
            {record.name}{' '}
          </span>
        )
      }
    },
    {
      title: () => {
        return (
          <span style={{ fontWeight: 'bold', color: 'rgb(94, 95, 96)' }}>
            {' '}
            Actions{' '}
          </span>
        )
      },
      key: 'actions',
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <ModalComponent
            visible={showModal}
            handleOk={handleConfirmDelete}
            handleCancel={handleCancelDelete}
            modalData={modalData}
            onOkText='Delete'
            onOkButtonProps={{ danger: true }}
          />
          <button
            style={{
              border: 'none',
              background: 'transparent',
              cursor: 'pointer'
            }}
            onClick={() => history.push(`/investments/edit/${record.id}`)}
          >
            <EditIcon />
          </button>
          <button
            style={{
              border: 'none',
              background: 'transparent',
              cursor: 'pointer'
            }}
            onClick={() => {
              setModalData({
                ...record
              })
              setModal(true)
            }}
          >
            <RemoveIcon />
          </button>
        </div>
      )
    }
  ]
  const mobileColumn = [
    {
      title: () => {
        return (
          <span style={{ fontWeight: 'bolder', paddingLeft: '20px' }}>
            {' '}
            Investments{' '}
          </span>
        )
      },
      render: (_, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingRight: '20px'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <span style={{ fontWeight: 'bolder', paddingLeft: '20px' }}>
                {' '}
                {record.name}{' '}
              </span>
            </div>
            <ModalComponent
              visible={showModal}
              handleOk={handleConfirmDelete}
              handleCancel={handleCancelDelete}
              modalData={modalData}
              onOkText='Delete'
              onOkButtonProps={{ danger: true }}
            />

            <Dropdown
              trigger={['click']}
              overlay={
                <DropdownMenu
                  history={history}
                  record={record}
                  setModal={setModal}
                  setModalData={setModalData}
                />
              }
            >
              <MoreOutlined style={{ fontSize: '2em' }} />
            </Dropdown>
          </div>
        )
      }
    }
  ]

  const getColumns = isPhone ? mobileColumn : columns
  return (
    <TableContainer style={{ width: '100%' }}>
      <TitleContainer style={{ display: 'flex' }}>
        <H2>Investments</H2>
        <TableButtonsContainer>
          <div
            style={{
              display: 'flex',
              gap: '8px'
            }}
          >
            <ResetButton>
              <SyncOutlined onClick={() => fetchInvestmentsRequest()} />
            </ResetButton>
            <Input
              placeholder='Search by name'
              value={nameFilter}
              onChange={e => setNameFilter(e.target.value)}
              style={{ width: '70%' }}
              suffix={
                <SearchOutlined
                  onClick={() => {
                    fetchInvestmentsRequest({ filters: { name: nameFilter } })
                  }}
                />
              }
            />
          </div>
          <div>
            <AddButton
              style={{ background: '#E0E1E1' }}
              icon={<PlusCircleOutlined />}
              onClick={() => history.push('/investments/new')}
            >
              <AddIcon />
              Add Investment
            </AddButton>
          </div>
        </TableButtonsContainer>
      </TitleContainer>
      <TableComponent
        rowIndex={record => record.id}
        dataSource={data}
        columns={getColumns}
        pagination={{
          ...pagination,
          onShowSizeChange: handleSizeChange,
          onChange: handleSizeChange
        }}
        loading={{
          spinning: loadingInvestments,
          indicator: <AlternativeSpinner />
        }}
        style={{
          padding: '0.75em 0'
        }}
        size='small'
      />
    </TableContainer>
  )
}

export default InvestmentsView
