import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { fetchUsersRequest, updateUsersPagination } from '../../store/users'

import UsersView from '../../components/view/UsersView'

const mapStateToProps = ({ users, auth }) => ({
  userItems: users.items,
  loadingUsers: users.isFetching,
  userProfiles: auth.user.profiles,
  pagination: users.pagination
})

const mapDispatchToProps = {
  fetchUsersRequest,
  updateUsersPagination
}

const UsersContainer = props => {
  const { fetchUsersRequest, userItems, userProfiles, ...rest } = props
  const history = useHistory()
  React.useEffect(() => {
    if (
      userProfiles?.includes('superuser') ||
      userProfiles?.includes('admin')
    ) {
      fetchUsersRequest()
    } else {
      history.push('/')
    }
  }, [])
  return (
    <UsersView
      {...rest}
      fetchUsersRequest={fetchUsersRequest}
      history={history}
      data={userItems}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer)
