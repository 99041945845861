import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { Link } from 'react-router-dom'
import { Form } from 'antd'

import AlternateInput from '../../components/input/AlternateInput'
import FormInput from '../input/FormInput'
import PhoneInput from '../input/PhoneInput'
import { FormWrapper, SubmitButton, SpanTitle } from './styles/LoginForm.style'
import { AlternativeSpinner as Loading } from '../loading/Loading'

import { required, validEmail, minPassLength } from './Validation/validation'

const FormComponent = ({ onSubmit, isFormValid, formValues }) => {
  const documentTypeValue = formValues && formValues['document_type']
  return (
    <Form onFinish={onSubmit}>
      <Field
        name='name'
        styledInput
        component={FormInput}
        placeholder='Name'
        validate={[required]}
      />
      <Field
        name='email'
        styledInput
        component={FormInput}
        placeholder='Email'
        validate={[required, validEmail]}
      />
      <PhoneInput name='phone' styledInput placeholder='Phone number' />
      <Field
        name='password'
        styledInput
        component={FormInput}
        placeholder='Password'
        validate={[required, minPassLength]}
        type='password'
      />
      <AlternateInput
        documentTypeValue={documentTypeValue}
        documentSelectStyle={{ width: '8em' }}
        styledInput
        borderless
        Field={Field}
        styledSelect
        validate
      />
      <SubmitButton disabled={!isFormValid} type='submit'>
        {' '}
        Submit{' '}
      </SubmitButton>
    </Form>
  )
}

const SignupForm = props => {
  const {
    onSubmit,
    handleSubmit,
    status,
    errors,
    isFormValid,
    formValues
  } = props
  const submitMethod = handleSubmit(onSubmit)
  return (
    <FormWrapper>
      <SpanTitle>Registration request</SpanTitle>
      {status === 'waiting' ? (
        <>
          {errors.map(error => (
            <span style={{ color: 'red' }} key={error}>
              {error}
            </span>
          ))}
          <FormComponent
            onSubmit={submitMethod}
            isFormValid={isFormValid}
            formValues={formValues}
          />
          <Link
            to='/'
            style={{
              alignSelf: 'center',
              color: '#bfbebf',
              fontWeight: 'bold',
              marginTop: '1em',
              textDecoration: 'underline'
            }}
          >
            {' '}
            Go to Login Page{' '}
          </Link>
        </>
      ) : status === 'loading' ? (
        <Loading />
      ) : status === 'confirmed' ? (
        <>
          <SpanTitle>Solicitation complete!</SpanTitle>
          <Link to='/'> Back to login </Link>
        </>
      ) : null}
    </FormWrapper>
  )
}

export default reduxForm({
  form: 'signUpForm',
  initialValues: { phone_code: '+55' }
})(SignupForm)
