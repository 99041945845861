/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { getFormValues, isValid } from 'redux-form'

import http from '../../utils/http'
import AudienceForm from '../../components/form/AudiencesForm'
import { updateCredentialsRequest } from '../../store/auth/actions'
import { fetchUsersRequest, updateUsersPagination } from '../../store/users'
import { updateAudienceRequest } from '../../store/audiences'

const mapDispatchToProps = {
  updateCredentialsRequest,
  fetchUsersRequest,
  updateAudienceRequest,
  updateUsersPagination
}

const mapStateToProps = state => ({
  users: state.users,
  formValues: getFormValues('audiencesForm')(state),
  userProfiles: state.auth.user.profiles,
  isFormValid: isValid('audiencesForm')(state),
  isFetching: state.audiences.isFetching
})

const fetchAudienceData = id => {
  const audiencesEndpoint = '/audiences'
  return http
    .get(`${audiencesEndpoint}/${id}`)
    .then(res => res)
    .catch(err => err.response)
}

const AudienceEditContainer = props => {
  const { fetchUsersRequest, userProfiles, updateAudienceRequest } = props
  const { id } = useParams()
  const history = useHistory()
  const [initialValues, setInitialValues] = React.useState({})
  const [renderReady, setRenderReady] = React.useState(false)

  React.useEffect(async () => {
    if (userProfiles?.includes('superuser') || userProfiles.includes('admin')) {
      fetchUsersRequest({
        filters: {
          'q[profiles_role_in]': 'investor,assessor'
        }
      })
      await fetchAudienceData(id).then(response => {
        if (response.status === 200) {
          const { data: { data: { attributes, id } } } = response
          const { user_ids, ...attrs } = attributes
          setInitialValues({
            ...attrs,
            user_ids: user_ids.map(item => item + ''),
            id
          })
          updateCredentialsRequest(response.headers)
        } else {
          history.push('/')
        }
      })
    } else {
      history.push('/')
    }
  }, [])

  React.useEffect(
    () => {
      if (!isEmpty(initialValues)) {
        setRenderReady(true)
      }
    },
    [initialValues]
  )
  const onSubmit = data => {
    const { id, ...attrs } = data
    const formattedData = {
      ...attrs,
      user_ids: data['user_ids'].map(item => item * 1)
    }
    updateAudienceRequest({ attributes: formattedData, id, history })
  }

  if (renderReady) {
    return (
      <AudienceForm
        {...props}
        edit
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    )
  } else return null
}

export default connect(mapStateToProps, mapDispatchToProps)(
  AudienceEditContainer
)
