/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import {} from '../../store/reports'
import { updateCredentialsRequest } from '../../store/auth/actions'
import http from '../../utils/http'

import MessageView from '../../components/view/MessageView'
import { filter } from 'lodash'
import { AlternativeSpinner } from '../../components/loading/Loading'

const mapDispatchToProps = {
  updateCredentialsRequest
}

const mapStateToProps = state => ({
  users: state.users.items,
  audiences: state.audiences.items,
  userProfiles: state.auth.user.profiles,
  is_admin: state.auth.user.profiles.includes('admin'),
  is_superuser: state.auth.user.profiles.includes('superuser')
})

const ReportMessageContainer = props => {
  const {
    updateCredentialsRequest,
    userProfiles,
    is_admin,
    is_superuser
  } = props
  const [initialValues, setInitialValues] = React.useState({})
  const [renderReady, setRenderReady] = React.useState(false)

  const { id } = useParams()
  const initData = async () => {
    try {
      const fetchReportData = async () => {
        const usersEndpoint = '/reports'
        const response = await http.get(
          `${usersEndpoint}/${id}?include=readings,destinations`
        )
        if (response.status === 200) {
          const { data: { data: { attributes }, included } } = response
          const destinations_attributes = included
            .filter(item => item.type === 'destinations')
            .map(item => ({ id: item.id, ...item.attributes }))
          const readings = filter(
            included,
            item => item.type === 'readings'
          ).map(item => ({
            id: item.id,
            ...item.attributes
          }))
          return {
            initialValues: {
              draft: attributes.draft,
              message: attributes.message,
              title: attributes.title,
              file_urls: attributes.file_urls,
              kind: attributes.kind,
              readings,
              destinations_attributes,
              id
            },
            headers: response.headers
          }
        } else {
          return {
            initialValues: {
              draft: '',
              message: '',
              title: '',
              file_urls: [],
              kind: '',
              destinations_attributes: [],
              id
            },
            headers: response.headers
          }
        }
      }
      const getData = await fetchReportData(id)
      getData && setInitialValues(getData.initialValues)
      setRenderReady(true)
      getData && getData.headers && updateCredentialsRequest(getData.headers)
    } catch (error) {
      console.error(error)
    }
  }
  React.useEffect(() => {
    initData()
  }, [])

  if (!renderReady) {
    return <AlternativeSpinner />
  }
  return (
    <MessageView
      isReport
      initialValues={initialValues}
      userProfiles={userProfiles}
      is_admin={is_admin || is_superuser}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(
  ReportMessageContainer
)
