import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { submit, isValid } from 'redux-form'
import { omit } from 'lodash'

import ReportsForm from '../../components/form/ReportsForm'
import { fetchUsersRequest } from '../../store/users'
import { fetchAudiencesRequest } from '../../store/audiences'
import { createReportRequest, updateReportRequest } from '../../store/reports'
import { updateCredentialsRequest } from '../../store/auth/actions'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import http from '../../utils/http'
import { AlternativeSpinner } from '../../components/loading/Loading'

const mapDispatchToProps = {
  fetchUsersRequest,
  fetchAudiencesRequest,
  submit,
  createReportRequest,
  updateCredentialsRequest,
  updateReportRequest
}

const mapStateToProps = state => ({
  users: state.users.items,
  audiences: state.audiences.items,
  userProfiles: state.auth.user.profiles,
  isFetching: state.reports.isFetching,
  isFormValid: isValid('reportsForm')(state)
})
const NewReport = props => {
  const {
    fetchAudiencesRequest,
    fetchUsersRequest,
    createReportRequest,
    updateReportRequest,
    userProfiles,
    ...rest
  } = props
  const [draft, setDraft] = React.useState(false)
  const [initialValues, setInitialValues] = React.useState({})
  const [loading, setLoading] = React.useState(true)
  const history = useHistory()
  const location = useLocation()

  const handlePermissions = () => {
    if (
      userProfiles?.includes('superuser') ||
      userProfiles?.includes('admin')
    ) {
      fetchUsersRequest()
      fetchAudiencesRequest()
    } else {
      history.push('/')
    }
  }

  const initForm = async () => {
    const id = location?.state?.report_id
    const kind = location?.state?.kind
    setLoading(true)
    if (!id && kind) {
      setInitialValues({ kind })
      setLoading(false)
      return
    }
    if (
      userProfiles?.includes('superuser') ||
      userProfiles?.includes('admin')
    ) {
      const fetchReportData = async () => {
        const usersEndpoint = '/reports'
        const response = await http.get(
          `${usersEndpoint}/${id}?include=destinations`
        )
        const { data: { data: { attributes }, included } } = response
        if (response.status === 200) {
          let files = []
          const destinations_attributes = included
            .filter(item => item.type === 'destinations')
            .map(item => ({
              id: item.id,
              ...omit(item.attributes, ['destinatable'])
            }))
          if (attributes['file_urls'] && attributes['file_urls'].length) {
            let fileUrlsArray = attributes['file_urls']
            return Promise.all(
              fileUrlsArray.map(item => {
                return fetch(item.url)
                  .then(r => {
                    return r.blob()
                  })
                  .then(blobFile => {
                    return new File([blobFile], item.filename, {
                      type: blobFile.type
                    })
                  })
              })
            ).then(values => {
              let fileResponse = []
              values.forEach((value, index) => {
                const id = attributes['file_urls'][index]['id']
                fileResponse.push({
                  file: value,
                  size: value.size,
                  name: value.name,
                  uid: id
                })
              })
              return {
                initialValues: {
                  draft: attributes.draft,
                  kind: attributes.kind,
                  message: attributes.message,
                  title: attributes.title,
                  destinations_attributes,
                  files: fileResponse,
                  id
                },
                headers: response.headers
              }
            })
          } else {
            return {
              initialValues: {
                draft: attributes.draft,
                kind: attributes.kind,
                message: attributes.message,
                title: attributes.title,
                files,
                destinations_attributes,
                id
              },
              headers: response.headers
            }
          }
        }
      }
      const getData = await fetchReportData(id)
      fetchUsersRequest()
      fetchAudiencesRequest()
      getData && setInitialValues(getData.initialValues)
      setLoading(false)
      getData && getData.headers && updateCredentialsRequest(getData.headers)
    } else {
      history.push('/')
    }
  }
  React.useEffect(() => {
    handlePermissions()
    initForm()
  }, [])
  const onSubmit = async data => {
    const { id, file_urls, ...attrs } = data
    setLoading(true)
    if (id) {
      await updateReportRequest({
        attributes: { ...attrs, draft },
        history,
        id
      })
    } else {
      await createReportRequest({ attributes: { ...data, draft }, history })
    }
    setLoading(false)
  }

  if (loading) {
    return <AlternativeSpinner />
  }

  return (
    <ReportsForm
      {...rest}
      edit={location?.state?.report_id}
      history={history}
      initialValues={initialValues}
      onSubmit={onSubmit}
      setDraft={setDraft}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(NewReport)
