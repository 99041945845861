import styled from '@emotion/styled'

export const ResetButton = styled('button')`
  align-items: center;
  background: #776aae !important;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  padding: 10px;
  &:hover {
    background: #776aae9e !important;
  }
  .anticon-sync {
    svg > path {
      fill: #fff;
    }
  }
`
