import React from 'react'
import { Dropdown, Menu } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import MailIcon from '../../static/mail-icon'
import { Navbar, NavbarControls, IconContainer } from './styles/Template.style'
import Profile from '../../static/user.jpg'
import { PhoneBreakpoint } from '../responsive/devicesResponsive'
import http from '../../utils/http'
import { timeDifference } from '../../utils/functions'
import { connect } from 'react-redux'
import ArrowDownIcon from '../../static/arrow-down-icon.js'
import LogoutIcon from '../../static/logout-icon.js'
import SetupIcon from '../../static/setup-icon.js'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'

const mapStateToProps = state => ({
  user: state.auth.user
})

export const ActionButtonWithIcon = styled('button')`
  align-items: center;
  background: transparent;
  border: none;
  color: #484848;
  cursor: pointer;
  display: flex;
  min-width: 80px;
  gap: 4px;
  padding: 0;
  &:hover {
    color: #776aae;
    font-weight: 600;
  }
`

export const NotificationItem = styled('span')`
  color: #484848;
  min-width: 140px;
`
export const NotificationDateItem = styled('span')`
  color: #484848;
  font-size: 0.75em;
  min-width: 140px;
`
export const MenuContainer = styled('div')`
  display: flex;
  flex-direction: column;
  &:hover {
    span {
      color: #776aae;
    }
    font-weight: 600;
  }
`

const NavbarComponent = props => {
  const { menu, setMenu, updateCredentialsRequest, auth, user, logout } = props
  const [notifications, setNotifications] = React.useState([])
  const history = useHistory()
  const amountOfNotification = notifications.filter(
    item => item.read_at === null
  ).length
  const menuDrop = (
    <Menu>
      {notifications ? (
        notifications.map(item => {
          function truncateNotification (text, maxLength = 16) {
            if (text?.length <= maxLength) {
              return text
            } else {
              return text?.slice(0, maxLength) + '...'
            }
          }
          const truncatedNotification = truncateNotification(item?.title)
          return (
            <Menu.Item
              key={item.id}
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <MenuContainer>
                <NotificationItem>{truncatedNotification}</NotificationItem>
                <NotificationDateItem>
                  {item.sent_at
                    ? timeDifference(new Date(), new Date(item.sent_at))
                    : '--'}
                </NotificationDateItem>
              </MenuContainer>
            </Menu.Item>
          )
        })
      ) : (
        <Menu.Item key='empty'>No notifications</Menu.Item>
      )}
    </Menu>
  )

  const userDropMenu = (
    <Menu>
      <Menu.Item
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '4px'
        }}
      >
        <ActionButtonWithIcon onClick={() => history.push('/settings')}>
          <SetupIcon />
          Setups
        </ActionButtonWithIcon>
      </Menu.Item>
      <Menu.Item
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          color: '#484848'
        }}
      >
        <ActionButtonWithIcon onClick={() => logout()}>
          <LogoutIcon />
          Logout
        </ActionButtonWithIcon>
      </Menu.Item>
    </Menu>
  )

  React.useEffect(async () => {
    if (auth && auth.isLogged && auth.isDeviceAuthenticated) {
      const fetchNotificationData = () => {
        const notificationEndpoint = '/notifications'
        return http
          .get(`${notificationEndpoint}`)
          .then(res => res)
          .catch(err => err.response)
      }
      await fetchNotificationData().then(res => {
        if (res.status === 200) {
          const { data: { data }, headers } = res
          const formattedData = data
            .map(item => {
              return {
                id: item.id,
                ...item.attributes
              }
            })
            .sort((a, b) => {
              return new Date(b.sent_at) - new Date(a.sent_at)
            })
          setNotifications(formattedData)
          updateCredentialsRequest(headers)
        }
      })
    }
  }, [])

  function truncateUserName (text, maxLength = 15) {
    if (text?.length <= maxLength) {
      return text
    } else {
      return text?.slice(0, maxLength) + '...'
    }
  }
  const truncatedUserName = truncateUserName(user?.name)

  return (
    <>
      {!!auth.user && (
        <Navbar id='navbar'>
          <PhoneBreakpoint>
            <MenuOutlined
              style={{ fontSize: '1.5em' }}
              onClick={() => setMenu(!menu)}
            />
          </PhoneBreakpoint>
          {auth &&
            auth.isLogged &&
            auth.isDeviceAuthenticated && (
            <NavbarControls>
              <IconContainer>
                <Dropdown
                  placement='bottomCenter'
                  trigger={['click']}
                  overlay={menuDrop}
                >
                  <div style={{ position: 'relative' }}>
                    <MailIcon />
                    <div
                      style={{
                        background: '#776AAE',
                        color: 'white',
                        fontSize: '8px',
                        width: 'fit-content',
                        padding: `${
                          amountOfNotification > 99
                            ? '6px 5px'
                            : amountOfNotification >= 10
                              ? '3px 4px'
                              : '2px 6px'
                        }`,
                        borderRadius: '50%',
                        position: 'absolute',
                        left: '18px',
                        bottom: '-2px'
                      }}
                    >
                      {amountOfNotification}
                    </div>
                  </div>
                </Dropdown>
              </IconContainer>
              <IconContainer>
                <Dropdown trigger={['click']} overlay={userDropMenu}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px'
                    }}
                  >
                    <img
                      src={Profile}
                      style={{
                        display: 'block',
                        margin: 'auto',
                        width: '32px',
                        borderRadius: '50%'
                      }}
                      alt='profile'
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        marginRight: '8px'
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontWeight: 600,
                          fontSize: '14px',
                          color: '#484848',
                          lineHeight: '120%'
                        }}
                      >
                        {truncatedUserName}
                      </p>
                      <p
                        style={{
                          margin: 0,
                          fontSize: '12px',
                          fontWeight: 500,
                          lineHeight: '120%'
                        }}
                      >
                        {user.profiles[0]}
                      </p>
                    </div>
                    <ArrowDownIcon />
                  </div>
                </Dropdown>
              </IconContainer>
            </NavbarControls>
          )}
        </Navbar>
      )}
    </>
  )
}

export default connect(mapStateToProps)(NavbarComponent)
