import React from 'react'
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { getFormValues } from 'redux-form'

import http from '../../utils/http'
import { updateCredentialsRequest } from '../../store/auth/actions'

import SingleAudienceView from '../../components/view/SingleAudienceView'

const mapDispatchToProps = {
  updateCredentialsRequest
}

const mapStateToProps = state => ({
  users: state.users,
  formValues: getFormValues('audiencesForm')(state),
  userProfiles: state.auth.user.profiles
})

const fetchAudienceData = id => {
  const audiencesEndpoint = '/audiences'
  return http
    .get(`${audiencesEndpoint}/${id}?includes=users`)
    .then(res => res)
    .catch(err => err.response)
}

const fetchAllItems = userIds => {
  let arrayOfUrls = []
  const usersEndpoint = '/users'
  userIds.forEach(item => {
    arrayOfUrls.push(`${usersEndpoint}/${item}`)
  })
  return Promise.all(
    arrayOfUrls.map(url => {
      return http.get(url).then(response => response)
    })
  ).then(values => values)
}

const SingleAudienceContainer = props => {
  const { userProfiles, updateCredentialsRequest } = props
  const { id } = useParams()
  const history = useHistory()
  const [initialValues, setInitialValues] = React.useState({})
  const [renderReady, setRenderReady] = React.useState(false)

  React.useEffect(async () => {
    if (
      userProfiles?.includes('superuser') ||
      userProfiles?.includes('admin')
    ) {
      const response = await fetchAudienceData(id)
      if (response.status === 200) {
        const { data: { data: { attributes, id } } } = response
        await fetchAllItems(attributes['user_ids']).then(response => {
          const audiencecData = response.map(
            ({ data: { data: { id, attributes } }, headers }) => {
              if (headers['access_token'] && headers['access_token'] !== '') {
                updateCredentialsRequest(headers)
              }
              return { id, ...attributes }
            }
          )
          setInitialValues({
            ...attributes,
            userData: audiencecData,
            id
          })
        })
        updateCredentialsRequest(response.headers)
      } else {
        history.push('/')
      }
    } else {
      history.push('/')
    }
  }, [])

  React.useEffect(
    () => {
      if (!isEmpty(initialValues)) {
        setRenderReady(true)
      }
    },
    [initialValues]
  )

  if (renderReady) {
    return <SingleAudienceView initialValues={initialValues} />
  } else return null
}

export default connect(mapStateToProps, mapDispatchToProps)(
  SingleAudienceContainer
)
