/* eslint-disable camelcase */
import { put, call, takeLatest, all, select } from 'redux-saga/effects'
import querystring from 'querystring'

import * as actions from './index'
import * as services from './services'
import { updateCredentialsRequest, updateUserInfo } from '../auth/actions'

function * fetchUsers ({ payload }) {
  try {
    let filtersQuery = {
      'q[confirmed_at_not_null]': true
    }
    let fieldsQuery = {}
    if (payload && payload.filters) {
      filtersQuery = {
        ...filtersQuery,
        ...payload.filters
      }
    }
    if (payload && payload.fields) {
      fieldsQuery = {
        'fields[users]': payload.fields
      }
    }
    const { current, pageSize } = yield select(state => state.users.pagination)
    const paginationQuery = {
      'page[number]': current,
      'page[size]': pageSize
    }
    const queryParams = querystring.stringify({
      ...paginationQuery,
      ...filtersQuery,
      ...fieldsQuery
    })
    const response = yield call(services.fetchUsers, { queryParams })
    if (response.status === 200) {
      const { data: { data, meta: { total_count } }, headers } = response
      const userData = data.map(item => ({ ...item.attributes, id: item.id }))
      yield put(actions.updateUsersPagination({ total: total_count }))
      yield put(actions.fetchUsersSuccess({ users: userData }))
      yield put(updateCredentialsRequest(headers))
    }
  } catch (error) {
    console.error(error)
    yield put(actions.fetchUsersError(error))
  }
}

function * registerUsers ({ payload }) {
  try {
    const params = {
      data: {
        type: 'registrations',
        attributes: { ...payload }
      }
    }
    yield call(services.registerUser, params)
    yield put(actions.registerUserSuccess())
  } catch (error) {
    console.error(error)
    yield put(actions.registerUserError(error))
  }
}

function * createUser ({ payload }) {
  try {
    const { attributes, history } = payload
    const params = {
      data: {
        type: 'users',
        attributes: { ...attributes }
      }
    }
    const response = yield call(services.createUser, params)
    if (response.status === 201) {
      yield put(actions.createUserSuccess())
      yield put(updateCredentialsRequest(response.headers))
      history.push('/users')
    }
  } catch (e) {
    console.error(e)
  }
}

function * updateUser ({ payload }) {
  try {
    const { attributes, history, editProfile } = payload
    const { id, ...attr } = attributes
    const params = {
      data: {
        id,
        type: 'users',
        attributes: { ...attr }
      }
    }
    const response = yield call(services.updateUser, { params, id })
    if (response.status === 200) {
      yield put(actions.updateUserSuccess())
      yield put(updateCredentialsRequest(response.headers))
      if (editProfile) {
        const userResponse = yield call(services.fetchUser, { id })
        if (userResponse.status === 200) {
          const { data: { data: { attributes, id } } } = userResponse
          yield put(updateUserInfo({ ...attributes, id }))
          yield put(updateCredentialsRequest(userResponse.headers))
        }
      }
      history.push('/users')
    }
  } catch (e) {
    console.error(e)
  }
}

function * watchFetchUsers () {
  yield takeLatest(actions.fetchUsersRequest, fetchUsers)
}

function * watchRegisterUsers () {
  yield takeLatest(actions.registerUserRequest, registerUsers)
}

function * watchCreateUser () {
  yield takeLatest(actions.createUserRequest, createUser)
}

function * watchUpdateUser () {
  yield takeLatest(actions.updateUserRequest, updateUser)
}

export function * UsersSagas () {
  yield all([
    watchFetchUsers(),
    watchRegisterUsers(),
    watchCreateUser(),
    watchUpdateUser()
  ])
}
