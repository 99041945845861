/*  global location */
import React, { Component } from 'react'
import TinyMCE from 'react-tinymce'
import { isPlainObject } from 'lodash'
import FormItemWithValidation from '../form/Validation/FormItemWithValidation'

class FormEditor extends Component {
  handleEditorChange = e => {
    if (this.props.isContent) {
      this.props.onChange({ value: e.target.getContent() })
      return null
    }
    this.props.onChange(e.target.getContent())
  }

  getBaseUrl = () => {
    if (typeof location.origin === 'undefined') {
      location.origin = location.protocol + '//' + location.host
    }
    return location.origin
  }

  render () {
    return (
      <FormItemWithValidation>
        <TinyMCE
          {...this.props}
          ref={editor => {
            this.editor = editor
          }}
          config={{
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table contextmenu paste code autoresize nonbreaking'
            ].join(' '),
            // enable tab
            nonbreaking_force_tab: true,
            autoresize_bottom_margin: 1,
            autoresize_min_height: 30,
            // external_plugins: {
            //   eqneditor: `${this.getBaseUrl()}/tiny_mce_v4/plugins/eqneditor/plugin.min.js`
            // },
            branding: false,
            menubar: true,
            readonly: this.props.disabled,
            toolbar:
              'undo redo | styleselect | eqneditor | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent blockquote | link image | table | fullscreen preview',
            image_title: true,
            paste_as_text: true,
            // paste options
            paste_word_valid_elements: 'b,strong,i,em,h1,h2',
            paste_retain_style_properties:
              'color font-size font-style text-decoration font-weight',
            paste_enable_default_filters: false,
            paste_filter_drop: false
          }}
          onChange={this.handleEditorChange}
          content={
            isPlainObject(this.props.value)
              ? this.props.value.value
              : this.props.value
          }
        />
      </FormItemWithValidation>
    )
  }
}

export default FormEditor
