import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { submit, isValid } from 'redux-form'

import InvestmentsForm from '../../components/form/InvestmentsForm'
import { createInvestmentRequest } from '../../store/investments'

const mapDispatchToProps = {
  createInvestmentRequest,
  submit
}

const mapStateToProps = state => ({
  isFormValid: isValid('investmentsForm')(state),
  isFetching: state.investments.isFetching,
  userProfiles: state.auth.user.profiles
})

const InvestmentsFormContainer = props => {
  const { createInvestmentRequest, userProfiles, ...rest } = props
  const history = useHistory()
  React.useState(() => {
    if (
      !(userProfiles?.includes('superuser') || userProfiles?.includes('admin'))
    ) {
      history.push('/')
    }
  })
  const onSubmit = data => {
    createInvestmentRequest({ attributes: data, history })
  }
  return <InvestmentsForm onSubmit={onSubmit} {...rest} />
}

export default connect(mapStateToProps, mapDispatchToProps)(
  InvestmentsFormContainer
)
