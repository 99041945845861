import http from '../../utils/http'

const registerEndpoint = '/auth/register'
const usersEndpoint = '/users'

export const registerUser = params => {
  return http
    .post(registerEndpoint, params)
    .then(res => res)
    .catch(err => err.response)
}

export const fetchUsers = ({ queryParams }) => {
  return http
    .get(`${usersEndpoint}?${queryParams}`)
    .then(res => res)
    .catch(err => err.response)
}

export const fetchUser = ({ id }) => {
  return http
    .get(`${usersEndpoint}/${id}`)
    .then(res => res)
    .catch(err => err.response)
}

export const updateUser = ({ id, params }) => {
  return http
    .put(`${usersEndpoint}/${id}`, params)
    .then(res => res)
    .catch(err => err.response)
}

export const createUser = params => {
  return http
    .post(usersEndpoint, params)
    .then(res => res)
    .catch(err => err.response)
}
