/* eslint-disable camelcase */
import React from 'react'
import { Collapse, Checkbox, Pagination, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import querystring from 'querystring'

import http from '../../utils/http'
import { updateCredentialsRequest } from '../../store/auth/actions'
import { change } from 'redux-form'
import styled from '@emotion/styled'

const Container = styled('div')`
  .ant-select-selector {
    background-color: transparent;
    border: none !important;
    span {
      color: #484848;
      font-family: 'Montserrat';
    }
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #776aae9e !important;
  }
  .ant-select-focused:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
    box-shadow: none;
  }
  .ant-select {
    .ant-select-arrow {
      > span > svg > path {
        fill: #484848;
      }
    }
  }
  .ant-collapse-content-box div > div {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: none;
  }
  .ant-pagination {
    margin-top: 0px;
  }
`

const fetchUsersData = ({
  filters,
  fields,
  pagination: { current = 1, pageSize = 20 }
}) => {
  let paginationQuery = {
    'page[number]': current,
    'page[size]': pageSize
  }
  let filtersQuery = {
    'q[confirmed_at_not_null]': true
  }
  let fieldsQuery = {}
  if (filters) {
    filtersQuery = {
      ...filtersQuery,
      ...filters
    }
  }
  if (fields) {
    fieldsQuery = {
      'fields[users]': fields
    }
  }
  const queryParams = querystring.stringify({
    ...paginationQuery,
    ...filtersQuery,
    ...fieldsQuery
  })

  const usersEndpoint = '/users'
  return http
    .get(`${usersEndpoint}?${queryParams}`)
    .then(res => res)
    .catch(err => err.response)
}

const fetchInvestorsData = ({
  filters,
  fields,
  pagination: { current = 1, pageSize = 20 }
}) => {
  let paginationQuery = {
    'page[number]': current,
    'page[size]': pageSize
  }
  let filtersQuery = {
    'q[investor_confirmed_at_not_null]': true
  }
  let fieldsQuery = {}
  if (filters) {
    filtersQuery = {
      ...filtersQuery,
      ...filters
    }
  }
  const queryParams = querystring.stringify({
    ...paginationQuery,
    ...filtersQuery,
    ...fieldsQuery
  })

  const investorAccountEndpoint = '/investor_accounts'
  return http
    .get(`${investorAccountEndpoint}?${queryParams}`)
    .then(res => res)
    .catch(err => err.response)
}

const fetchAudienceData = ({
  filters,
  fields,
  pagination: { current = 1, pageSize = 20 }
}) => {
  let paginationQuery = {
    'page[number]': current,
    'page[size]': pageSize
  }
  let filtersQuery = {}
  let fieldsQuery = {}
  if (filters) {
    filtersQuery = {
      ...filtersQuery,
      ...filters
    }
  }
  if (fields) {
    fieldsQuery = {
      'fields[audiences]': fields
    }
  }
  const queryParams = querystring.stringify({
    ...paginationQuery,
    ...filtersQuery,
    ...fieldsQuery
  })

  const audiencesEndpoint = '/audiences'
  return http
    .get(`${audiencesEndpoint}?${queryParams}`)
    .then(res => res)
    .catch(err => err.response)
}
const defaultPagination = {
  current: 1,
  defaultPageCurrent: 1,
  defaultPageSize: 20,
  pageSize: 20,
  pageSizeOptions: [5, 10, 20],
  showSizeChanger: true
}
const DestinationInput = props => {
  const { meta, fields, forceUpdate } = props
  const dispatch = useDispatch()
  const [audiences, setAudiences] = React.useState([])
  const handleAudiencePaginationChange = (current, size) => {
    setAudiencesPagination({ ...audiencesPagination, current, pageSize: size })
    fetchAudiences({ pagination: { current, pageSize: size } })
  }
  const [audiencesPagination, setAudiencesPagination] = React.useState({
    ...defaultPagination,
    onShowSizeChange: handleAudiencePaginationChange,
    onChange: handleAudiencePaginationChange
  })
  const [audienceNameFilter, setAudienceNameFilter] = React.useState('')
  const [inv, setInv] = React.useState([])
  const handleInvPaginationChange = (current, size) => {
    setInvPagination({ ...invPagination, current, pageSize: size })
    fetchInvestors({ pagination: { current, pageSize: size } })
  }
  const [invPagination, setInvPagination] = React.useState({
    ...defaultPagination,
    onShowSizeChange: handleInvPaginationChange,
    onChange: handleInvPaginationChange
  })
  const [invNameFilter, setInvNameFilter] = React.useState('')
  const [nonInv, setNonInv] = React.useState([])
  const handleNonInvPaginationChange = (current, size) => {
    setInvPagination({ ...nonInvPagination, current, pageSize: size })
    fetchNonInvestors({ pagination: { current, pageSize: size } })
  }

  const [nonInvPagination, setNonInvPagination] = React.useState({
    ...defaultPagination,
    onShowSizeChange: handleNonInvPaginationChange,
    onChange: handleNonInvPaginationChange
  })
  const [nonInvNameFilter, setNonInvNameFilter] = React.useState('')
  const [potInv, setPotInv] = React.useState([])
  const handlePotInvPaginationChange = (current, size) => {
    setPotInvPagination({ ...potInvPagination, current, pageSize: size })
    fetchPotInvestors({ pagination: { current, pageSize: size } })
  }
  const [potInvPagination, setPotInvPagination] = React.useState({
    ...defaultPagination,
    onShowSizeChange: handlePotInvPaginationChange,
    onChange: handlePotInvPaginationChange
  })
  const [potInvNameFilter, setPotInvNameFilter] = React.useState('')
  const fetchAudiences = async payload => {
    const pagination = payload ? payload.pagination : {}
    const queryPagination = { ...audiencesPagination, ...pagination }
    await fetchAudienceData({
      filters: {
        'q[name_cont]': audienceNameFilter
      },
      fields: 'name',
      pagination: queryPagination
    }).then(response => {
      if (response.status === 200) {
        const { data: { data, meta: { total_count } }, headers } = response
        setAudiencesPagination({
          ...queryPagination,
          total: total_count
        })
        dispatch(updateCredentialsRequest(headers))
        setAudiences(data.map(item => ({ ...item.attributes, id: item.id })))
      }
    })
  }
  const fetchInvestors = async payload => {
    const pagination = payload ? payload.pagination : {}
    const queryPagination = { ...invPagination, ...pagination }
    await fetchInvestorsData({
      filters: {
        'q[investor_name_cont]': invNameFilter,
        include: 'investor'
      },
      pagination: queryPagination
    }).then(response => {
      if (response.status === 200) {
        const {
          data: { data, meta: { total_count }, included },
          headers
        } = response
        setInvPagination({
          ...queryPagination,
          total: total_count
        })
        dispatch(updateCredentialsRequest(headers))
        setInv(
          data.reduce((acc, item) => {
            const user = included.find(
              incl =>
                incl.type === 'users' &&
                ~~incl.id === ~~item.attributes.investor_id
            )
            return acc.concat({
              ...item.attributes,
              id: item.id,
              name: user.attributes.name
            })
          }, [])
        )
      }
    })
  }
  const fetchNonInvestors = async payload => {
    const pagination = payload ? payload.pagination : {}
    const queryPagination = { ...potInvPagination, ...pagination }
    await fetchUsersData({
      filters: {
        'q[profiles_role_in]': 'assessor',
        'q[name_cont]': nonInvNameFilter
      },
      fields: 'name',
      pagination: queryPagination
    }).then(response => {
      if (response.status === 200) {
        const { data: { data, meta: { total_count } }, headers } = response
        setNonInvPagination({
          ...queryPagination,
          total: total_count
        })
        dispatch(updateCredentialsRequest(headers))
        setNonInv(data.map(item => ({ ...item.attributes, id: item.id })))
      }
    })
  }
  const fetchPotInvestors = async payload => {
    const pagination = payload ? payload.pagination : {}
    const queryPagination = { ...nonInvPagination, ...pagination }
    await fetchUsersData({
      filters: {
        'q[profiles_role_in]': 'potential_investor',
        'q[name_cont]': potInvNameFilter
      },
      fields: 'name',
      pagination: queryPagination
    }).then(response => {
      if (response.status === 200) {
        const { data: { data, meta: { total_count } }, headers } = response
        setPotInvPagination({
          ...queryPagination,
          total: total_count
        })
        dispatch(updateCredentialsRequest(headers))
        setPotInv(data.map(item => ({ ...item.attributes, id: item.id })))
      }
    })
  }
  React.useEffect(async () => {
    await fetchAudiences()
    await fetchInvestors()
    await fetchNonInvestors()
    await fetchPotInvestors()
  }, [])
  const values = fields.getAll() || []
  return (
    <div
      style={{
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
          gap: '16px'
        }}
      >
        <Collapse>
          <Collapse.Panel
            header='Select Audiences'
            style={{ margin: '1em 1em' }}
          >
            <Input
              placeholder='Search by name'
              value={audienceNameFilter}
              onChange={e => setAudienceNameFilter(e.target.value)}
              style={{ width: '70%' }}
              suffix={
                <SearchOutlined
                  onClick={() => {
                    fetchAudiences({
                      filters: { 'q[name_cont]': audienceNameFilter }
                    })
                  }}
                />
              }
            />
            <div>
              {audiences &&
                audiences.map(recipient => {
                  const idValue = recipient.id * 1
                  const current_destination = values.find(
                    value =>
                      value.destinatable_type === 'Audience' &&
                      value.destinatable_id === idValue
                  )
                  const current_destination_index = values.findIndex(
                    value =>
                      value.destinatable_type === 'Audience' &&
                      value.destinatable_id === idValue
                  )
                  const is_checked =
                    current_destination && !current_destination._destroy
                  const destination_saved = current_destination?.id
                  return (
                    <div key={recipient.name}>
                      <Checkbox
                        onChange={e => {
                          const checked = e.target.checked
                          if (!current_destination || !destination_saved) {
                            if (checked) {
                              fields.push({
                                destinatable_id: idValue,
                                destinatable_type: 'Audience'
                              })
                            } else if (!destination_saved) {
                              fields.remove(current_destination_index)
                            }
                          } else {
                            let newValue = current_destination
                            if (checked) {
                              newValue = {
                                ...current_destination,
                                _destroy: false
                              }
                            } else {
                              newValue = {
                                ...current_destination,
                                _destroy: true
                              }
                            }
                            dispatch(
                              change(
                                meta.form,
                                `destinations_attributes[${current_destination_index}]`,
                                newValue
                              )
                            )
                          }
                          forceUpdate()
                        }}
                        checked={is_checked}
                      />
                      <span>{recipient.name}</span>
                    </div>
                  )
                })}
            </div>
            <Container>
              <Pagination {...audiencesPagination} />
            </Container>
          </Collapse.Panel>
        </Collapse>
        <Collapse>
          <Collapse.Panel
            header='Select Investors'
            style={{ margin: '1em 1em' }}
          >
            <Input
              placeholder='Search by name'
              value={invNameFilter}
              onChange={e => setInvNameFilter(e.target.value)}
              style={{ width: '70%' }}
              suffix={
                <SearchOutlined
                  onClick={() => {
                    fetchInvestors({
                      filters: { 'q[investor_name_cont]': invNameFilter }
                    })
                  }}
                />
              }
            />

            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 0.5fr)'
              }}
            >
              {inv.map(recipient => {
                const idValue = recipient.id * 1
                const current_destination = values.find(
                  value =>
                    value.destinatable_type === 'InvestorAccount' &&
                    value.destinatable_id === idValue
                )
                const current_destination_index = values.findIndex(
                  value =>
                    value.destinatable_type === 'InvestorAccount' &&
                    value.destinatable_id === idValue
                )
                const is_checked =
                  current_destination && !current_destination._destroy
                const destination_saved = current_destination?.id
                return (
                  <div key={recipient.name}>
                    <Checkbox
                      onChange={e => {
                        const checked = e.target.checked
                        if (!current_destination || !destination_saved) {
                          if (checked) {
                            fields.push({
                              destinatable_id: idValue,
                              destinatable_type: 'InvestorAccount'
                            })
                          } else if (!destination_saved) {
                            fields.remove(current_destination_index)
                          }
                        } else {
                          let newValue = current_destination
                          if (checked) {
                            newValue = {
                              destinatable_id: idValue,
                              destinatable_type: 'InvestorAccount',
                              _destroy: false
                            }
                          } else {
                            newValue = {
                              ...current_destination,
                              _destroy: true
                            }
                          }
                          dispatch(
                            change(
                              meta.form,
                              `destinations_attributes[${current_destination_index}]`,
                              newValue
                            )
                          )
                        }
                        forceUpdate()
                      }}
                      checked={is_checked}
                    />
                    <span>{recipient.name}</span>
                  </div>
                )
              })}
            </div>
            <Container>
              <Pagination {...invPagination} />
            </Container>
          </Collapse.Panel>
        </Collapse>
        <Collapse>
          <Collapse.Panel
            header='Select Assessors'
            style={{ margin: '1em 1em' }}
          >
            <Input
              placeholder='Search by name'
              value={nonInvNameFilter}
              onChange={e => setNonInvNameFilter(e.target.value)}
              style={{ width: '70%' }}
              suffix={
                <SearchOutlined
                  onClick={() => {
                    fetchNonInvestors({
                      filters: { 'q[name_cont]': nonInvNameFilter }
                    })
                  }}
                />
              }
            />
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 0.5fr)'
              }}
            >
              {nonInv.map(recipient => {
                const idValue = recipient.id * 1
                const current_destination = values.find(
                  value =>
                    value.destinatable_type === 'User' &&
                    value.destinatable_id === idValue
                )
                const current_destination_index = values.findIndex(
                  value =>
                    value.destinatable_type === 'User' &&
                    value.destinatable_id === idValue
                )
                const is_checked =
                  current_destination && !current_destination._destroy
                const destination_saved = current_destination?.id
                return (
                  <div key={recipient.name}>
                    <Checkbox
                      onChange={e => {
                        const checked = e.target.checked
                        if (!current_destination || !destination_saved) {
                          if (checked) {
                            fields.push({
                              destinatable_id: idValue,
                              destinatable_type: 'User'
                            })
                          } else if (!destination_saved) {
                            fields.remove(current_destination_index)
                          }
                        } else {
                          let newValue = current_destination
                          if (checked) {
                            newValue = {
                              destinatable_id: idValue,
                              destinatable_type: 'User',
                              _destroy: false
                            }
                          } else {
                            newValue = {
                              ...current_destination,
                              _destroy: true
                            }
                          }
                          dispatch(
                            change(
                              meta.form,
                              `destinations_attributes[${current_destination_index}]`,
                              newValue
                            )
                          )
                        }
                        forceUpdate()
                      }}
                      checked={is_checked}
                    />
                    <span>{recipient.name}</span>
                  </div>
                )
              })}
            </div>
            <Container>
              <Pagination {...nonInvPagination} />
            </Container>
          </Collapse.Panel>
        </Collapse>
        <Collapse>
          <Collapse.Panel
            header='Select Potential Investors'
            style={{ margin: '1em 1em' }}
          >
            <Input
              placeholder='Search by name'
              value={nonInvNameFilter}
              onChange={e => setPotInvNameFilter(e.target.value)}
              style={{ width: '70%' }}
              suffix={
                <SearchOutlined
                  onClick={() => {
                    fetchPotInvestors({
                      filters: { 'q[name_cont]': potInvNameFilter }
                    })
                  }}
                />
              }
            />
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 0.5fr)'
              }}
            >
              {potInv.map(recipient => {
                const idValue = recipient.id * 1
                const current_destination = values.find(
                  value =>
                    value.destinatable_type === 'User' &&
                    value.destinatable_id === idValue
                )
                const current_destination_index = values.findIndex(
                  value =>
                    value.destinatable_type === 'User' &&
                    value.destinatable_id === idValue
                )
                const is_checked =
                  current_destination && !current_destination._destroy
                const destination_saved = current_destination?.id
                return (
                  <div key={recipient.name}>
                    <Checkbox
                      onChange={e => {
                        const checked = e.target.checked
                        if (!current_destination || !destination_saved) {
                          if (checked) {
                            fields.push({
                              destinatable_id: idValue,
                              destinatable_type: 'User'
                            })
                          } else if (!destination_saved) {
                            fields.remove(current_destination_index)
                          }
                        } else {
                          let newValue = values.reduce((acc, destination) => {
                            if (
                              destination.id === ~~current_destination.id &&
                              destination.destinatable_type === 'User'
                            ) {
                              if (checked) {
                                return acc.concat({
                                  destinatable_id: idValue,
                                  destinatable_type: 'User',
                                  _destroy: false
                                })
                              } else {
                                return acc.concat({
                                  ...destination,
                                  _destroy: true
                                })
                              }
                            }
                            return acc.concat(destination)
                          }, [])
                          dispatch(
                            change(
                              meta.form,
                              'destinations_attributes',
                              newValue
                            )
                          )
                        }
                      }}
                      checked={is_checked}
                    />
                    <span>{recipient.name}</span>
                  </div>
                )
              })}
            </div>
            <Container>
              <Pagination {...potInvPagination} />
            </Container>
          </Collapse.Panel>
        </Collapse>
      </div>
      {meta &&
        meta.error &&
        (values && !Object.keys(values).some(item => values[item].length > 0)) && (<span style={{ color: '#ff4d4f', marginTop: '20px' }}>* Select at least one destination</span>)}
    </div>
  )
}

export default DestinationInput
