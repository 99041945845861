import React from 'react'

const MailIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
    >
      <path
        d='M3.65088 8.35693L10.7338 13.0711C11.5038 13.5824 12.4678 13.5824 13.2378 13.0711L20.3208 8.35693'
        stroke='#484848'
        strokeWidth='0.75'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M18.2349 4.50098H5.73839C3.67373 4.50098 2 6.17471 2 8.23936V16.7095C2 18.7742 3.67373 20.4479 5.73839 20.4479H18.2349C20.2996 20.4479 21.9733 18.7742 21.9733 16.7095V8.23936C21.9733 6.17471 20.2996 4.50098 18.2349 4.50098Z'
        stroke='#484848'
        strokeWidth='0.75'
        strokeMiterlimit='10'
      />
    </svg>
  )
}

export default MailIcon
