import React from 'react'
import { Radio } from 'antd'

import './styles/radio.css'

const CustomRadio = ({ children }) => {
  return <Radio> {children} </Radio>
}

export default CustomRadio
