import React from 'react'
import Input from 'antd-mask-input'

const maskTypes = {
  usPhone: '(111)111-1111',
  brPhone: '(11) 11111-1111',
  cpf: '111.111.111-11',
  passport: ''
}

const MaskedInputComponent = ({ mask, prefix, input, ...props }) => {
  return (
    <Input
      mask={mask && maskTypes[mask]}
      prefix={prefix}
      {...input}
      {...props}
    />
  )
}

export default MaskedInputComponent
