import React from 'react'

const EyeIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='12'
      viewBox='0 0 17 12'
      fill='none'
    >
      <path
        d='M1 8.70387C1 4.42912 4.46525 0.963867 8.74 0.963867C13.0148 0.963867 16.48 4.42912 16.48 8.70387'
        stroke='#484848'
        strokeWidth='0.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.74003 11.5479C10.765 11.5479 12.4066 9.90636 12.4066 7.88139C12.4066 5.85641 10.765 4.21484 8.74003 4.21484C6.71505 4.21484 5.07349 5.85641 5.07349 7.88139C5.07349 9.90636 6.71505 11.5479 8.74003 11.5479Z'
        stroke='#484848'
        strokeWidth='0.75'
        strokeMiterlimit='10'
      />
    </svg>
  )
}

export default EyeIcon
