import { once } from 'lodash'

import axiosInstance from '../utils/http'
import { LOGOUT as logout, SIGN_IN_SUCCESSFUL } from '../store/auth/actions'

const clearUserOnAuthErrors = once(next => {
  axiosInstance.interceptors.response.use(
    response => response,
    /**
     * This is a central point to handle all
     * error messages generated by HTTP
     * requests
     */
    error => {
      const { response } = error
      /**
       * If token is either expired, not provided or invalid
       * then redirect to login. On server side the error
       * messages can be changed on app/Providers/EventServiceProvider.php
       */
      const isUnauthorizedError = [401, 400].indexOf(response.status) > -1
      const isAuthEndpoint = error.config.url.includes('auth')
      const isRegisteredDeviceEndpoint = error.config.url.includes(
        'registered_devices'
      )

      if (
        isUnauthorizedError &&
        !isAuthEndpoint &&
        !isRegisteredDeviceEndpoint
      ) {
        next(logout())
      }

      return Promise.reject(error)
    }
  )
})

export const onAuthErrors = store => next => action => {
  clearUserOnAuthErrors(next)
  next(action)
}

/**
 * This set the token on two moments: On localStorage rehydrate
 * and on user sign in.
 * TODO: Set the token on signup too
 */
export const addTokenToRequest = store => next => action => {
  const state = store.getState()
  const { isDeviceAuthenticated, deviceId } = state.auth
  const isStorageRehydrate = action.type === 'persist/REHYDRATE'
  const isUserSignedIn = action.type === SIGN_IN_SUCCESSFUL.type
  const isLogged = action.payload?.auth?.isLogged
  if (isDeviceAuthenticated) {
    axiosInstance.defaults.headers.common['device-id'] = deviceId
  }
  if (isStorageRehydrate && action.payload && action.payload.headers) {
    axiosInstance.defaults.headers.common['access-token'] =
      action.payload.headers['access-token']
    axiosInstance.defaults.headers.common['client'] =
      action.payload.headers['client']
    axiosInstance.defaults.headers.common['expiry'] =
      action.payload.headers['expiry']
    axiosInstance.defaults.headers.common['client'] =
      action.payload.headers['client']
    axiosInstance.defaults.headers.common['uid'] = action.payload.headers['uid']
    axiosInstance.defaults.headers.common['device-id'] = deviceId
  }

  if (isStorageRehydrate && isLogged) {
    axiosInstance.defaults.headers.common['access-token'] =
      action.payload.auth?.credentials['access-token']
    axiosInstance.defaults.headers.common['client'] =
      action.payload.auth?.credentials['client']
    axiosInstance.defaults.headers.common['expiry'] =
      action.payload.auth?.credentials['expiry']
    axiosInstance.defaults.headers.common['client'] =
      action.payload.auth?.credentials['client']
    axiosInstance.defaults.headers.common['uid'] =
      action.payload.auth?.credentials['uid']
    axiosInstance.defaults.headers.common['device-id'] =
      action.payload.auth?.deviceId
  }
  if (
    (isUserSignedIn && action.payload && action.payload.headers) ||
    (action.payload && action.payload.headers)
  ) {
    axiosInstance.defaults.headers.common['access-token'] =
      action.payload.headers['access-token']
    axiosInstance.defaults.headers.common['token-type'] =
      action.payload.headers['token-type']
    axiosInstance.defaults.headers.common['expiry'] =
      action.payload.headers['expiry']
    axiosInstance.defaults.headers.common['client'] =
      action.payload.headers['client']
    axiosInstance.defaults.headers.common['uid'] = action.payload.headers['uid']
    axiosInstance.defaults.headers.common['device-id'] = deviceId
  }

  next(action)
}
