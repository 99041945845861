import React from 'react'
import { connect } from 'react-redux'
import { getFormValues, submit, isValid } from 'redux-form'
import { useHistory } from 'react-router-dom'

import { fetchUsersRequest, updateUsersPagination } from '../../store/users'
import { createAudienceRequest } from '../../store/audiences/'
import AudiencesForm from '../../components/form/AudiencesForm'

const mapDispatchToProps = {
  fetchUsersRequest,
  submit,
  createAudienceRequest,
  updateUsersPagination
}

const mapStateToProps = state => ({
  users: state.users,
  formValues: getFormValues('audiencesForm')(state),
  userProfiles: state.auth.user.profiles,
  isFormValid: isValid('audiencesForm')(state),
  isFetching: state.audiences.isFetching
})

const AudienceFormContainer = props => {
  const {
    fetchUsersRequest,
    createAudienceRequest,
    userProfiles,
    updateUsersPagination
  } = props
  const history = useHistory()
  React.useEffect(() => {
    if (
      userProfiles?.includes('superuser') ||
      userProfiles?.includes('admin')
    ) {
      updateUsersPagination({ pageSize: 10 })
      fetchUsersRequest({
        filters: {
          'q[profiles_role_in]': 'investor,assessor'
        }
      })
    } else {
      history.push('/')
    }
  }, [])
  const onSubmit = data => {
    const formattedData = {
      ...data,
      user_ids: data['user_ids'].map(item => item * 1)
    }
    createAudienceRequest({ attr: formattedData, history })
  }
  return <AudiencesForm onSubmit={onSubmit} {...props} />
}

export default connect(mapStateToProps, mapDispatchToProps)(
  AudienceFormContainer
)
